import { UserOutlined } from '@ant-design/icons';
import { Avatar } from 'antd';
import React from 'react';
import { getSessionUser } from '../../utils/helperAuthentication';

function UserAvatar() {
  const user = getSessionUser();

  return user && user.profile_image ? (
    <Avatar
      size='large'
      src={user.profile_image.includes('googleusercontent') ? user.profile_image : `${process.env.REACT_APP_API_BASE_URL}/temp/${user.profile_image}`}
    />
  ) : (
    <Avatar
      size='large'
      icon={<UserOutlined />}
    />
  );
}

export default UserAvatar;
