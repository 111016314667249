import { SearchOutlined } from '@ant-design/icons';
import {
  Button, Empty, Input, Pagination, Result, Select, Skeleton, Tooltip, Typography
} from 'antd';
import React, { useEffect, useState } from 'react';
import useFetchData from '../../../hooks/useFetchData';
import truncateString from '../../../lib/truncateString';
import testnetAddress from '../../../utils/helperTestnetAddress';
import AclRoute from '../../routes/AclRoute';

const { Title } = Typography;
const { Option } = Select;

function TokenDistribution() {
  window.document.title = 'ERC-20 Token • Token Distribution';
  const [searchKeyword, setSearchKeyword] = useState('');
  const [sortType, setSortType] = useState('aesc');
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);

  const [loading, error, response] = useFetchData(`${process.env.REACT_APP_API_BASE_URL}/api/v1/distributed-token-list?page=${page}&search=${searchKeyword}&limit=${limit}&sort=${sortType}`);

  // reset paginate page number
  useEffect(() => {
    setPage(1);
  }, [searchKeyword, limit]);

  return (
    <div className='bg-backgroundColorWhite min-h-[68vh] shadow-md rounded-sm p-4'>
      <Skeleton loading={loading} active paragraph={{ rows: 10 }}>
        {error ? (
          <Result
            status='error'
            title='Fetching Error'
            subTitle={error}
          />
        ) : (
          <AclRoute hasOwnPermission='Ico.TokenDistribution.View'>
            <div>
              <Title className='text-start' level={4}>Distributed Token List</Title>

              <div>
                {/* SEARCH BOX & FILTERS */}
                <div className='search-and-filter-box'>
                  <Input
                    className='space-x-4'
                    placeholder='Type in to Search distributed tokens'
                    onChange={(e) => setSearchKeyword(e.target.value)}
                    prefix={<SearchOutlined />}
                    size='large'
                  />

                  <Select
                    className='w-full sm:w-[240px]'
                    placeholder='-- show rows --'
                    onChange={(value) => setLimit(value)}
                    defaultValue='10'
                    size='large'
                  >
                    <Option value='10'>10 Rows</Option>
                    <Option value='20'>20 Rows</Option>
                    <Option value='30'>30 Rows</Option>
                    <Option value='40'>40 Rows</Option>
                    <Option value='50'>50 Rows</Option>
                  </Select>

                  <Select
                    className='w-full sm:w-[240px]'
                    placeholder='-- select type to sort --'
                    onChange={(value) => setSortType(value)}
                    defaultValue='aesc'
                    size='large'
                  >
                    <Option value='aesc'>Sort By Ascending</Option>
                    <Option value='desc'>Sort By Descending</Option>
                  </Select>
                </div>

                {/* DISTRIBUTED TOKEN LIST */}
                {response && response.result.rows.length === 0 ? (
                  <Empty
                    className='mt-10'
                    description={(
                      <span>Sorry! Your distributed token data was not found.</span>
                    )}
                  />
                ) : (
                  <div className='w-full shadow bg-white rounded my-3'>
                    <div className='border-gray-200 w-full rounded bg-white overflow-x-auto'>
                      <table className='w-full leading-normal'>
                        {/* TABLE HEADER */}
                        <thead className='table-thead'>
                          <tr className='border-b border-gray-200'>
                            <th scope='col' className='table-thead-th'>
                              FROM ADDRESS
                            </th>
                            <th scope='col' className='table-thead-th'>
                              TO ADDRESS
                            </th>
                            <th scope='col' className='table-thead-th'>
                              TRANX ID
                            </th>
                            <th scope='col' className='table-thead-th'>
                              TOKEN QUANTITY
                            </th>
                            <th scope='col' className='table-thead-th'>
                              TOTAL PRICE
                            </th>
                            <th scope='col' className='table-thead-th'>
                              CURRENCY RATE
                            </th>
                            <th scope='col' className='table-thead-th'>
                              TOKEN RECEIVED ADDRESS
                            </th>
                            <th scope='col' className='table-thead-th'>
                              AIR-DROP TRANX ID
                            </th>
                            <th scope='col' className='table-thead-th'>
                              DATE
                            </th>
                          </tr>
                        </thead>

                        {/* DATA MAPPING ON TABLE BODY */}
                        {response && response.result.rows.map((data) => (
                          <tbody key={data.id}>
                            <tr className='table-body-tr'>
                              <td className='table-body-td'>
                                <Tooltip title={data.send_from_address}>
                                  {truncateString(data.send_from_address, 18)}
                                </Tooltip>
                              </td>
                              <td className='table-body-td'>
                                <Tooltip title={data.send_to_address}>
                                  {truncateString(data.send_to_address, 18)}
                                </Tooltip>
                              </td>
                              <td className='table-body-td'>
                                <Tooltip title={data?.trx_id}>
                                  <Button
                                    className='!px-0'
                                    href={testnetAddress(data?.network, data?.trx_id)}
                                    target='_blank'
                                    size='middle'
                                    type='link'
                                  >
                                    {truncateString(data?.trx_id, 12)}
                                  </Button>
                                </Tooltip>
                              </td>
                              <td className='table-body-td'>
                                {data.token_quantity}
                              </td>
                              <td className='table-body-td'>
                                {data.total_price}
                              </td>
                              <td className='table-body-td'>
                                {data.currency_rate ? data.currency_rate : 'N/A'}
                              </td>
                              <td className='table-body-td'>
                                <Tooltip title={data.token_receive_address}>
                                  {truncateString(data.token_receive_address, 24)}
                                </Tooltip>
                              </td>
                              <td className='table-body-td'>
                                <Tooltip title={data.air_drop_trx_id}>
                                  <Button
                                    className='!px-0'
                                    href={testnetAddress(data?.network, data?.air_drop_trx_id)}
                                    target='_blank'
                                    size='middle'
                                    type='link'
                                  >
                                    {truncateString(data?.air_drop_trx_id, 18)}
                                  </Button>
                                </Tooltip>
                              </td>
                              <td className='table-body-td'>
                                {data.created_at.split(' ')[0]}
                              </td>
                            </tr>
                          </tbody>
                        ))}
                      </table>
                    </div>
                  </div>
                )}

                {/* DISTRIBUTED TOKEN LIST PAGINATION */}
                {response && response.result.total_page > 1 && (
                  <Pagination
                    className='flex items-center justify-center m-4'
                    total={response.result.total_page * 10}
                    onChange={(e) => setPage(e)}
                    defaultCurrent={1}
                  />
                )}
              </div>
            </div>
          </AclRoute>
        )}
      </Skeleton>
    </div>
  );
}

export default TokenDistribution;
