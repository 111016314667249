import { Result, Skeleton } from 'antd';
import React from 'react';
import useFetchData from '../../../hooks/useFetchData';
import IcoCard from '../card/IcoCard';
import KycCard from '../card/KycCard';
import PaymentCard from '../card/PaymentCard';
import UserCard from '../card/UserCard';

function SuperAdminDashboard() {
  window.document.title = 'ERC-20 Token • Super Admin Dashboard';

  // fetching admin dashboard API data
  const [loading, error, response] = useFetchData(`${process.env.REACT_APP_API_BASE_URL}/api/v1/super-admin-dashboard`);

  return (
    <Skeleton loading={loading} active paragraph={{ rows: 12 }}>
      {error ? (
        <Result
          status='error'
          title='Fetching Error'
          subTitle={error}
        />
      ) : (
        <div className='min-h-[68vh] rounded-sm mt-4'>
          <div className='flex flex-wrap -mx-2 mb-8'>
            <div className='w-full sm:w-1/2 px-2 mb-4'>
              {response && (<UserCard user={response?.result[0]?.user} />)}
            </div>

            <div className='w-full sm:w-1/2 px-2 mb-4'>
              {response && <IcoCard ico={response?.result[1]?.ico} />}
            </div>

            <div className='w-full sm:w-1/2 px-2 mb-4'>
              {response && <KycCard kyc={response?.result[2]?.kyc} />}
            </div>

            <div className='w-full sm:w-1/2 px-2 mb-4'>
              {response && (<PaymentCard payment={response?.result[3]?.payment} />)}
            </div>
          </div>
        </div>
      )}
    </Skeleton>
  );
}

export default SuperAdminDashboard;
