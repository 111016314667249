import { LoadingOutlined } from '@ant-design/icons';
import {
  Button, Checkbox, Col, Form, Input, InputNumber, Row, Switch, Tooltip
} from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import eventMsg from '../../../data/event-message.json';
import useConnectWallet from '../../../hooks/useConnectWallet';
import convertToInternationalCurrencySystem from '../../../lib/internationalCurrencyConvert';
import isEmptyObject from '../../../lib/isEmptyObject';
import { setTokenFormData } from '../../../redux/actions/tokenAction';
import { getSessionToken } from '../../../utils/helperAuthentication';
import ga4EventAction from '../../../utils/helperGAEventAction';
import notificationWithIcon from '../../../utils/popupNotification';

function CreateTokenForm() {
  const { connectWalletHandler } = useConnectWallet();
  const { tokenFromData } = useSelector((state) => state.token);
  const { walletNetwork, walletAddress, walletBalance } = useSelector((state) => state.wallet);
  const [advancedSettings, setAdvancedSettings] = useState(false);
  const [initialSupply, setInitialSupply] = useState(0);
  const [loading, setLoading] = useState(false);
  const token = getSessionToken();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { useForm } = Form;
  const [form] = useForm();

  useEffect(() => {
    if (!isEmptyObject(tokenFromData)) {
      form.setFieldsValue(tokenFromData);

      if (tokenFromData.advancedSetting && tokenFromData.advancedSetting === true) {
        setAdvancedSettings(true);
      } else {
        setAdvancedSettings(false);
      }
    }
  }, [tokenFromData, form]);

  const onFinish = (values) => {
    // google analytics event handler
    ga4EventAction('link', 'Create Token', 'User Create Token');

    if (walletAddress && walletBalance) {
      setLoading(true);
      const myHeaders = new Headers();
      myHeaders.append('Content-Type', 'application/json');
      // if user is logged in, send authorization headers
      token && myHeaders.append('Authorization', `Bearer ${token}`);

      const raw = JSON.stringify({
        token_name: values.tokenName,
        token_symbol: values.symbol,
        total_supply: values.initialSupply,
        token_decimals: values.decimals,
        supply_type: values?.othersConfig?.includes('CanMint') ? 'multiple' : 'fixed',
        freeze: values?.othersConfig?.includes('CanFreeze') ? 1 : 0,
        burning_token: values?.othersConfig?.includes('CanBurn') ? 1 : 0,
        change_owner: values.advancedSetting ? 1 : 0,
        owner_address: values.advancedSetting ? values.createOwner : walletAddress,
        network: walletNetwork
      });

      const requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
      };

      fetch(`${process.env.REACT_APP_API_BASE_URL}/api/v1/token-deploy`, requestOptions)
        .then((response) => response.json())
        .then((result) => {
          if (result.result_code === 0 && result.result) {
            notificationWithIcon('success', eventMsg.app.tokenInitiateSuccess.title, eventMsg.app.tokenInitiateSuccess.message);

            // add token create form values to redux store
            dispatch(setTokenFormData(values));

            // navigate to token preview page
            navigate('/app/token-preview', {
              state: { hex: result.result.hex }
            });

            setLoading(false);
          } else {
            notificationWithIcon('error', 'Token Create Error', result?.error?.message);
            setLoading(false);
          }
        })
        .catch((error) => {
          notificationWithIcon('error', 'Token Create Error', error.message);
          setLoading(false);
        });
    } else {
      notificationWithIcon('error', eventMsg.app.walletConnectionError.title, eventMsg.app.walletConnectionError.message);
    }
  };

  return (
    <Form
      form={form}
      name='token-create-form'
      labelCol={{ span: 6 }}
      wrapperCol={{ span: 12 }}
      initialValues={{ remember: true }}
      onFinish={onFinish}
      autoComplete='off'
    >
      {/* INPUT FIELD TOKEN NAME */}
      <Form.Item
        label='Token Name'
        name='tokenName'
        rules={[{
          required: true,
          message: 'Please input your token name'
        }]}
      >
        <Input placeholder='e.g. Good Luck Token' size='large' />
      </Form.Item>

      {/* INPUT FILED SYMBOLS */}
      <Form.Item
        label='Token Symbol'
        name='symbol'
        rules={[{
          required: true,
          message: 'Please input here symbols'
        }]}
      >
        <Input placeholder='e.g. GLT' size='large' />
      </Form.Item>

      {/* INPUT FILED INITIAL SUPPLY */}
      <Form.Item label='Total Supply'>
        <Form.Item
          name='initialSupply'
          rules={[{
            required: true,
            message: 'Please input here initial supply'
          }]}
          noStyle
        >
          <InputNumber
            className='!w-[300px]'
            placeholder='e.g. 21000000'
            type='number'
            size='large'
            onChange={(value) => {
              setInitialSupply(value);
            }}
          />
        </Form.Item>
        <span className='ant-form-text'>
          {`≈ ${convertToInternationalCurrencySystem(initialSupply)}`}
        </span>
      </Form.Item>

      {/* INPUT FILED DECIMALS */}
      <Form.Item
        label='Decimals(1-18)'
        name='decimals'
        rules={[{
          required: true,
          message: 'Please input here decimals (Min 1 - Max 18)'
        }]}
      >
        <InputNumber
          className='!w-[300px]'
          placeholder='e.g. 13'
          type='number'
          size='large'
          max={18}
          min={1}
        />
      </Form.Item>

      {/* SWITCH ADVANCED SETTINGS */}
      <Form.Item name='advancedSetting' label='Advanced settings' valuePropName='checked'>
        <Switch
          className={advancedSettings ? 'bg-primaryColor' : 'bg-gray-300'}
          defaultChecked={advancedSettings}
          onChange={(checked) => {
            setAdvancedSettings(checked);
          }}
        />
      </Form.Item>

      {advancedSettings && (
        <Form.Item
          label='Owner Address'
          name='createOwner'
          rules={[{
            required: true,
            message: 'Please input here Owner Address'
          }]}
        >
          <Input placeholder='0x1111111111...' size='large' />
        </Form.Item>
      )}

      {/* CHECKBOX FIELD OTHER CONFIG */}
      <Form.Item name='othersConfig' label='Other Config'>
        <Checkbox.Group>
          <Row>
            {/* CAN BURN */}
            <Col span={12}>
              <Checkbox
                value='CanBurn'
                style={{ lineHeight: '32px' }}
              >
                <Tooltip
                  title='This check specifies whether your tokens can be burned to decrease the supply.'
                >
                  <span>Can Burn</span>
                </Tooltip>
              </Checkbox>
            </Col>

            {/* CAN MINT */}
            <Col span={12}>
              <Checkbox
                value='CanMint'
                style={{ lineHeight: '32px' }}
              >
                <Tooltip
                  title='Minting specifies the opposite, whether more tokens can be created to increase the initial supply.'
                >
                  <span>Can Mint</span>
                </Tooltip>
              </Checkbox>
            </Col>

            {/* CAN FREEZE */}
            <Col span={12}>
              <Checkbox
                value='CanFreeze'
                style={{ lineHeight: '32px' }}
              >
                <Tooltip
                  title='This check specifies whether your token and all associated operations can be halted and resumed whenever needed.
This pausing operation can be used in case of a software vulnerability or a malicious attack.
Be aware that enabling pausing gives authority to whoever is allowed to pause or unpause,
such as the creator of the token, and this central authority may not suit certain use cases.'
                >
                  <span>Can Freeze</span>
                </Tooltip>
              </Checkbox>
            </Col>
          </Row>
        </Checkbox.Group>
      </Form.Item>

      {/* MetaMask Wallet Connect Button */}
      <Form.Item
        label='Wallet Connection'
        name='WalletAddress'
        rules={[{
          required: true,
          validator: () => (walletAddress ? Promise.resolve() : Promise.reject(new Error('Please connect your MetaMask wallet first. Without can’t create token')))
        }]}
      >
        <Button
          className='w-[220px] font-bold'
          onClick={connectWalletHandler}
          type='default'
          size='middle'
        >
          Connect to MetaMask
        </Button>
      </Form.Item>

      {/* FORM SUBMIT BUTTON */}
      <Form.Item className='flex items-center justify-center'>
        <Button
          className='w-[300px] mt-2 font-bold'
          htmlType='submit'
          type='primary'
          size='large'
          disabled={loading}
        >
          {loading ? <LoadingOutlined /> : 'Create Token'}
        </Button>
      </Form.Item>
    </Form>
  );
}

export default CreateTokenForm;
