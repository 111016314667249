import { CommentOutlined, LoadingOutlined } from '@ant-design/icons';
import {
  Button, Comment, Descriptions, Form, Input, List, message, Result, Skeleton, Tag, Tooltip
} from 'antd';
import moment from 'moment';
import React, { useState } from 'react';
import eventMsg from '../../../data/event-message.json';
import useFetchData from '../../../hooks/useFetchData';
import { getSessionToken } from '../../../utils/helperAuthentication';
import { supportTicketResponseAsStatus } from '../../../utils/helperShared';
import UserAvatar from '../../common/UserAvatar';

const { TextArea } = Input;

function MySupportTicketDetails({ id }) {
  const [commentAddLoading, setCommentAddLoading] = useState(false);
  const [fetchAgain, setFetchAgain] = useState(false);
  const token = getSessionToken();
  const { useForm } = Form;
  const [form] = useForm();

  const [loading, error, response] = useFetchData(`${process.env.REACT_APP_API_BASE_URL}/api/v1/get-support-ticket-detail/${id}`, fetchAgain);

  // function to add a commat support ticket
  const handleAddComment = (values) => {
    setCommentAddLoading(true);
    const myHeaders = new Headers();
    myHeaders.append('Authorization', `Bearer ${token}`);
    myHeaders.append('Content-Type', 'application/json');

    const raw = JSON.stringify({
      comment: values.comments,
      ticket_id: id
    });

    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    fetch(`${process.env.REACT_APP_API_BASE_URL}/api/v1/save-support-ticket-comment`, requestOptions)
      .then((res) => res.json())
      .then((result) => {
        setCommentAddLoading(false);

        if (result.result_code === 0) {
          message.success(eventMsg.user.supperTicketCommentSuccess);
          setFetchAgain(!fetchAgain);
          form.resetFields();
        } else {
          message.error(result.error.message);
        }
      })
      .catch((err) => {
        message.error(err.message);
        setCommentAddLoading(false);
      });
  };

  return (
    <Skeleton loading={loading} active avatar paragraph={{ rows: 6 }}>
      {error ? (
        <Result
          status='error'
          title='Fetching Error'
          subTitle={error}
        />
      ) : response && (
        <>
          <Descriptions
            title='Support Ticket Details'
            labelStyle={{ fontWeight: 'bold' }}
            bordered
          >
            {/* SUPPORT TICKET DESCRIPTIONS */}
            <Descriptions.Item label='Name'>
              {`${response.result.first_name} ${response.result.last_name}`}
            </Descriptions.Item>
            <Descriptions.Item label='Status' span={2}>
              <Tag
                className='px-2 py-1 text-sm capitalize'
                color={supportTicketResponseAsStatus(response.result.status).color}
              >
                {supportTicketResponseAsStatus(response.result.status).status}
              </Tag>
            </Descriptions.Item>

            <Descriptions.Item label='Subject' span={3}>
              {response.result.subject}
            </Descriptions.Item>
            <Descriptions.Item className='capitalize' label='Descriptions' span={3}>
              {response.result.description}
            </Descriptions.Item>
          </Descriptions>

          {/* SUPPORT TICKET COMMENTS */}
          <List
            className='comment-list mx-0 md:mx-10 lg:mx-20'
            header={`${response.result.comments.length} Comments`}
            itemLayout='horizontal'
            dataSource={[
              ...response.result.comments.map((data) => ({
                author: `${data.first_name} ${data.last_name}`,
                avatar: data.profile_image ? (data.profile_image.includes('googleusercontent') ? data.profile_image : `${process.env.REACT_APP_API_BASE_URL}/temp/${data.profile_image}`) : 'https://joeschmoe.io/api/v1/random',
                content: (<p>{data.comment}</p>),
                datetime: (
                  <Tooltip
                    title={moment(data.created_at).subtract(1, 'seconds').format('YYYY-MM-DD HH:mm:ss')}
                  >
                    <span>
                      {moment(data.created_at).subtract(1, 'seconds').fromNow()}
                    </span>
                  </Tooltip>
                )
              }))
            ]}
            renderItem={(item) => (
              <li>
                <Comment
                  author={item.author}
                  avatar={item.avatar}
                  content={item.content}
                  datetime={item.datetime}
                />
              </li>
            )}
          />

          {/* SUPPORT TICKET ADD A COMMENT */}
          {response.result.status !== 5 && (
            <Comment
              className='ml-0 mx-0 md:mx-10 lg:mx-20'
              avatar={(<UserAvatar />)}
              content={(
                <Form
                  name='ticket-add-comment'
                  initialValues={{ remember: true }}
                  onFinish={handleAddComment}
                  autoComplete='off'
                  size='large'
                  form={form}
                >
                  {/* TEXT AREA COMMENT */}
                  <Form.Item
                    className='w-full sm:w-[80%] md:w-[65%]'
                    name='comments'
                    rules={[{
                      required: true,
                      message: 'Please input your ticket comments!'
                    }]}
                  >
                    <TextArea placeholder='Type here your support ticket comments' rows={5} />
                  </Form.Item>

                  {/* COMMENT SUBMIT BUTTON */}
                  <Form.Item>
                    <Button
                      className={`w-[200px] mt-2 ${commentAddLoading || 'inline-flex items-center'}`}
                      type='primary'
                      htmlType='submit'
                      icon={commentAddLoading || <CommentOutlined />}
                      size='large'
                      disabled={commentAddLoading}
                    >
                      {commentAddLoading ? <LoadingOutlined /> : 'Add Comment'}
                    </Button>
                  </Form.Item>
                </Form>
              )}
            />
          )}
        </>
      )}
    </Skeleton>
  );
}

export default MySupportTicketDetails;
