export const SET_WALLET_NETWORK = 'SET_TOKEN_CREATE_NETWORK';
export const DELETE_WALLET_NETWORK = 'DELETE_TOKEN_CREATE_NETWORK';

export const SET_WALLET_ADDRESS = 'SET_WALLET_ADDRESS';
export const DELETE_WALLET_ADDRESS = 'DELETE_WALLET_ADDRESS';

export const SET_WALLET_BALANCE = 'SET_WALLET_BALANCE';
export const DELETE_WALLET_BALANCE = 'DELETE_WALLET_BALANCE';

export const SET_WALLET_CHAIN_ID = 'SET_WALLET_CHAIN_ID';
export const DELETE_WALLET_CHAIN_ID = 'DELETE_WALLET_CHAIN_ID';
