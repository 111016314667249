import {
  Button, Descriptions, Result, Skeleton, Tag, Tooltip
} from 'antd';
import useFetchData from '../../../hooks/useFetchData';
import truncateString from '../../../lib/truncateString';
import { airDropResponseAsStatus, purchaseResponseAsStatus } from '../../../utils/helperShared';
import testnetAddress from '../../../utils/helperTestnetAddress';

function MyTokenDetails({ id }) {
  const [loading, error, response] = useFetchData(`${process.env.REACT_APP_API_BASE_URL}/api/v1/purchase-detail/${id}`);

  return (
    <Skeleton loading={loading} active avatar paragraph={{ rows: 6 }}>
      {error ? (
        <Result
          status='error'
          title='Fetching Error'
          subTitle={error}
        />
      ) : (
        <Descriptions
          className='pb-4'
          title='Purchase Token Details'
          labelStyle={{ fontWeight: 'bold' }}
          bordered
        >
          <Descriptions.Item label='Token Name (Symbol)'>
            {`${response?.result?.token_name} (${response?.result?.token_symbol})`}
          </Descriptions.Item>
          <Descriptions.Item label='Air-Drop Status' span={2}>
            <Tag
              className='px-2 py-1 text-sm capitalize'
              color={airDropResponseAsStatus(response?.result?.air_drop_status).color}
            >
              {airDropResponseAsStatus(response?.result?.air_drop_status).status}
            </Tag>
          </Descriptions.Item>

          <Descriptions.Item label='Paid Currency'>
            {`${response?.result?.accepted_currency?.toUpperCase()} (1 ${response?.result?.accepted_currency?.toUpperCase()} = $ ${response?.result?.currency_rate})`}
          </Descriptions.Item>
          <Descriptions.Item label='Purchase Status' span={2}>
            <Tag
              className='px-2 py-1 text-sm capitalize'
              color={purchaseResponseAsStatus(response?.result?.status).color}
            >
              {purchaseResponseAsStatus(response?.result?.status).status}
            </Tag>
          </Descriptions.Item>

          <Descriptions.Item label='Token Quality'>
            {`${response?.result?.token_quantity} (${response?.result?.token_symbol})`}
          </Descriptions.Item>
          <Descriptions.Item label='Token Bonus' span={2}>
            {`${response?.result?.bonus_token} %`}
          </Descriptions.Item>

          <Descriptions.Item label='Token Price'>
            {`${response?.result?.total_price} (${response?.result?.accepted_currency?.toUpperCase()})`}
          </Descriptions.Item>
          <Descriptions.Item label='Token Received Address' span={2}>
            {response?.result?.token_receive_address}
          </Descriptions.Item>

          <Descriptions.Item label='To Wallet Address'>
            {response?.result?.send_to_address}
          </Descriptions.Item>
          <Descriptions.Item label='From Wallet Address' span={2}>
            {response?.result?.send_from_address}
          </Descriptions.Item>

          <Descriptions.Item label='Tranx Id'>
            {response?.result?.trx_id ? (
              <Tooltip title={response?.result?.trx_id}>
                <Button
                  className='!px-0'
                  href={testnetAddress(response?.result?.network, response?.result?.trx_id)}
                  target='_blank'
                  size='middle'
                  type='link'
                >
                  {truncateString(response?.result?.trx_id, 22)}
                </Button>
              </Tooltip>
            ) : 'N/A'}

          </Descriptions.Item>
          <Descriptions.Item label='Purchase Date' span={2}>
            {response?.result?.created_at.split(' ')[0]}
          </Descriptions.Item>

          <Descriptions.Item label='Token Contract Address' span={3}>
            {response?.result?.contract_address ? response?.result?.contract_address : 'N/A'}
          </Descriptions.Item>
        </Descriptions>
      )}
    </Skeleton>
  );
}

export default MyTokenDetails;
