import { ExclamationCircleOutlined } from '@ant-design/icons';
import {
  Alert, Button, Descriptions, message, Modal, Result, Select, Skeleton, Tag
} from 'antd';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import eventMsg from '../../../data/event-message.json';
import useFetchData from '../../../hooks/useFetchData';
import numberWithCommas from '../../../lib/numberFormat';
import { updateApiState } from '../../../redux/actions/appAction';
import { getSessionToken } from '../../../utils/helperAuthentication';
import ga4EventAction from '../../../utils/helperGAEventAction';
import { responseAsColorAndStatus, tokenConfigResponseAsStatus } from '../../../utils/helperShared';
import notificationWithIcon from '../../../utils/popupNotification';
import AclRoute from '../../routes/AclRoute';

const { confirm } = Modal;

function DeclaredIcoDetails({ id }) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [stageStatus, setStageStatus] = useState(null);
  const [fetchAgain, setFetchAgain] = useState(false);
  const [stageId, setStageId] = useState(null);
  const [tokenId, setTokenId] = useState(null);
  const [errMsg, setErrMsg] = useState('');
  const token = getSessionToken();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // redux store state
  const { walletAddress } = useSelector((state) => state.wallet);
  const { reloadApiState } = useSelector((state) => state.app);

  // fetch ico declaration detail API data
  const [loading, error, response] = useFetchData(`${process.env.REACT_APP_API_BASE_URL}/api/v1/get-ico-declaration-detail/${id}`, fetchAgain);

  // function to handle change status
  const handleChangeStatus = () => {
    // google analytics event handler
    ga4EventAction('link', 'Change ICO Stage Status', 'Admin Change ICO Stage Status');

    if (stageId && tokenId && stageStatus) {
      setConfirmLoading(true);
      const myHeaders = new Headers();
      myHeaders.append('Authorization', `Bearer ${token}`);
      myHeaders.append('Content-Type', 'application/json');

      const raw = JSON.stringify({
        stage_id: stageId,
        token_id: tokenId,
        status: stageStatus
      });

      const requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
      };

      fetch(`${process.env.REACT_APP_API_BASE_URL}/api/v1/update-ico-stage-status`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
          if (result.result_code === 0) {
            setErrMsg('');
            setStageId(null);
            setTokenId(null);
            setStageStatus(null);
            setIsModalOpen(false);
            setConfirmLoading(false);
            setFetchAgain(!fetchAgain);
            message.success(eventMsg.admin.icoStatusUpdateSuccessMessage);

            // re-fetch called api data for update state
            dispatch(updateApiState(!reloadApiState));
          } else {
            setErrMsg(result.error.message);
            setConfirmLoading(false);
          }
        })
        .catch((err) => {
          setErrMsg(err.message);
          setConfirmLoading(false);
        });
    } else {
      setErrMsg('Please select an stage status first. Then try again');
      setConfirmLoading(false);
    }
  };

  // function to initiate & confirm air-drop
  const handleInitiateAirDrop = (stagesId) => {
    // google analytics event handler
    ga4EventAction('link', 'Initiate Air-Drop', 'Admin Initiate Air-Drop');

    if (walletAddress) {
      confirm({
        title: 'Initiate Air-drop',
        icon: <ExclamationCircleOutlined />,
        content: 'Are you sure at this stage initiate Air-drop?',

        onOk() {
          return new Promise((resolve, reject) => {
            const myHeaders = new Headers();
            myHeaders.append('Authorization', `Bearer ${token}`);
            myHeaders.append('Content-Type', 'application/json');

            const raw = JSON.stringify({
              stage_id: stagesId,
              from_address: walletAddress
            });

            const requestOptions = {
              method: 'POST',
              headers: myHeaders,
              body: raw,
              redirect: 'follow'
            };

            fetch(`${process.env.REACT_APP_API_BASE_URL}/api/v1/inititate-token-distribution`, requestOptions)
              .then((res) => res.json())
              .then((result) => {
                if (result.result_code === 0) {
                  resolve(); // resolved promise
                  // navigate to air-drop page
                  navigate('/admin/air-drop', {
                    state: {
                      stageInfo: {
                        ...result.result.data,
                        stage_id: stagesId
                      }
                    }
                  });
                } else {
                  reject(result.error.message);
                }
              })
              .catch((err) => {
                reject(err.message);
              });
          }).catch((err) => {
            message.error(err);
          });
        }
      });
    } else {
      notificationWithIcon('error', eventMsg.app.walletConnectionError.title, eventMsg.app.walletConnectionError.message);
    }
  };

  return (
    <>
      <Skeleton loading={loading} active avatar paragraph={{ rows: 10 }}>
        {error ? (
          <Result
            status='error'
            title='Fetching Error'
            subTitle={error}
          />
        ) : (
          response && response?.result && (
          <>
            {/* DECLARED ICO DESCRIPTION'S */}
            <Descriptions
              title='Declared ICO Details'
              labelStyle={{ fontWeight: 'bold' }}
              bordered
            >
              <Descriptions.Item label='Token Name'>
                {response?.result?.token_name}
              </Descriptions.Item>
              <Descriptions.Item label='Token Symbol' span={2}>
                {response?.result?.token_symbol}
              </Descriptions.Item>

              <Descriptions.Item label='Total Supply'>
                {numberWithCommas(response?.result?.total_supply)}
              </Descriptions.Item>
              <Descriptions.Item label='Token Decimals' span={2}>
                {response?.result?.token_decimals}
              </Descriptions.Item>

              <Descriptions.Item label='Target Token Sale Quantity'>
                {numberWithCommas(response?.result?.target_token_sale)}
              </Descriptions.Item>
              <Descriptions.Item className='capitalize' label='Supply Type' span={2}>
                {response?.result?.supply_type}
              </Descriptions.Item>

              <Descriptions.Item label='Can Burn'>
                {tokenConfigResponseAsStatus(response?.result?.can_burn)}
              </Descriptions.Item>
              <Descriptions.Item label='Can Freeze' span={2}>
                {tokenConfigResponseAsStatus(response?.result?.is_freeze)}
              </Descriptions.Item>

              <Descriptions.Item className='capitalize' label='Token Issuer'>
                {response?.result?.token_issuer}
              </Descriptions.Item>
              <Descriptions.Item label='Mailing Address' span={2}>
                {response?.result?.contact_address ? response?.result?.contact_address : 'N/A'}
              </Descriptions.Item>

              <Descriptions.Item label='Description' span={3}>
                {response?.result?.description}
              </Descriptions.Item>

              <Descriptions.Item label='Contract Address' span={3}>
                <AclRoute hasOwnPermission='Ico.Contract.View'>
                  {response?.result?.contract ? response?.result?.contract.contract_address : 'N/A'}
                </AclRoute>
              </Descriptions.Item>
            </Descriptions>

            {/* DECLARED ICO STAGE'S HEADER */}
            <h4 className='text-center text-xl font-semibold uppercase mt-4'>
              ICO Stage Information
            </h4>

            {/* DECLARED ICO STAGE'S DATA */}
            <AclRoute hasOwnPermission='Ico.Stage.View'>
              <div className='w-full shadow bg-white rounded my-3' span={2}>
                <div className='border-gray-200 w-full rounded bg-white overflow-x-auto'>
                  <table className='w-full leading-normal'>
                    {/* TABLE HEADER */}
                    <thead className='table-thead'>
                      <tr className='border-b border-gray-200'>
                        <th scope='col' className='table-thead-th'>
                          TOKEN PRICE
                        </th>
                        <th scope='col' className='table-thead-th'>
                          TOKEN SALE AMOUNT
                        </th>
                        <th scope='col' className='table-thead-th'>
                          BONUS
                        </th>
                        <th scope='col' className='table-thead-th'>
                          MIN PURCHASE
                        </th>
                        <th scope='col' className='table-thead-th'>
                          MAX PURCHASE
                        </th>
                        <th scope='col' className='table-thead-th'>
                          START DATE
                        </th>
                        <th scope='col' className='table-thead-th'>
                          END DATE
                        </th>
                        <th scope='col' className='table-thead-th'>
                          STATUS
                        </th>
                        <th scope='col' className='table-thead-th'>
                          ACTIONS
                        </th>
                      </tr>
                    </thead>

                    {/* DATA MAPPING ON TABLE BODY */}
                    {response && response?.result?.stages.map((data) => (
                      <tbody key={data.id}>
                        <tr className='table-body-tr'>
                          <td className='table-body-td'>
                            {`$ ${data.token_price} / 1 ${response?.result?.token_symbol}`}
                          </td>
                          <td className='table-body-td'>
                            {data.token_sale_amount}
                          </td>
                          <td className='table-body-td'>
                            {`${data.bonus} %`}
                          </td>
                          <td className='table-body-td'>
                            {data.min_purchase}
                          </td>
                          <td className='table-body-td'>
                            {data.max_purchase}
                          </td>
                          <td className='table-body-td'>
                            {(data.start_date).split(' ')[0]}
                          </td>
                          <td className='table-body-td'>
                            {(data.end_date).split(' ')[0]}
                          </td>
                          <td className='table-body-td'>
                            <Tag
                              className='px-2 py-1 text-sm capitalize'
                              color={responseAsColorAndStatus(data.status).color}
                            >
                              {responseAsColorAndStatus(data.status).status}
                            </Tag>
                          </td>
                          <td className='table-body-td space-x-2'>
                            <AclRoute hasOwnPermission='Ico.Stage.Status'>
                              {/* button to change stage status */}
                              <Button
                                type='primary'
                                disabled={data.status === 5}
                                onClick={() => {
                                  setStageId(data.id);
                                  setIsModalOpen(true);
                                  setTokenId(response.result.id);
                                }}
                              >
                                CHANGE STATUS
                              </Button>

                              {/* button to handle initiate air-drop */}
                              {response?.result?.contract && (
                                <AclRoute hasOwnPermission='Ico.TokenDistribution.Initiate'>
                                  <Button
                                    type='default'
                                    className='font-bold'
                                    onClick={() => handleInitiateAirDrop(data.id)}
                                    disabled={data.is_air_drop === '2'}
                                  >
                                    {data.is_air_drop === '2' ? 'AIR-DROPPED' : 'INITIATE AIR-DROP'}
                                  </Button>
                                </AclRoute>
                              )}
                            </AclRoute>
                          </td>
                        </tr>
                      </tbody>
                    ))}
                  </table>
                </div>
              </div>
            </AclRoute>

            {/* DECLARED ICO CURRENCY'S HEADER */}
            <h4 className='text-center text-xl font-semibold uppercase mt-4'>
              Accepted Currencies For Payment
            </h4>

            {/* DECLARED ICO CURRENCY'S DATA */}
            <div className='w-full shadow bg-white rounded my-3' span={2}>
              <div className='border-gray-200 w-full rounded bg-white overflow-x-auto'>
                <table className='w-full leading-normal'>
                  {/* TABLE HEADER */}
                  <thead className='table-thead'>
                    <tr className='border-b border-gray-200'>
                      <th scope='col' className='table-thead-th'>
                        WALLET ADDRESS
                      </th>
                      <th scope='col' className='table-thead-th'>
                        ACCEPTED CURRENCY
                      </th>
                      <th scope='col' className='table-thead-th'>
                        CURRENCY CODE (SYMBOL)
                      </th>
                    </tr>
                  </thead>

                  {/* DATA MAPPING ON TABLE BODY */}
                  {response && response?.result?.wallets.map((data) => (
                    <tbody key={data.id}>
                      <tr className='table-body-tr'>
                        <td className='table-body-td'>
                          {data.wallet_address}
                        </td>
                        <td className='table-body-td !capitalize'>
                          {data.currency_name}
                        </td>
                        <td className='table-body-td !uppercase'>
                          {`${data.currency_code} (${data.currency_symbol})`}
                        </td>
                      </tr>
                    </tbody>
                  ))}
                </table>
              </div>
            </div>
          </>
          )
        )}
      </Skeleton>

      {/* CHANGE STATUS MODAL */}
      <Modal
        title='Change Declared ICO Stage Status'
        open={isModalOpen}
        visible={isModalOpen}
        okText='Change Status'
        onOk={handleChangeStatus}
        onCancel={() => {
          setErrMsg('');
          setStageStatus(null);
          setIsModalOpen(false);
          setConfirmLoading(false);
        }}
        confirmLoading={confirmLoading}
      >
        {/* ERROR ALERT MESSAGE */}
        {errMsg && <Alert message={errMsg} type='error' className='!text-center mb-2' />}

        <Select
          className='w-full mb-16'
          placeholder='-- select stage status --'
          size='large'
          onChange={(value) => setStageStatus(value)}
          value={stageStatus}
        >
          <Select.Option value='0'>Draft</Select.Option>
          <Select.Option value='1'>Public</Select.Option>
          <Select.Option value='5'>Closed</Select.Option>
        </Select>
      </Modal>
    </>
  );
}

export default DeclaredIcoDetails;
