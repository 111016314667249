import { Layout, PageHeader } from 'antd';
import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate, useNavigate } from 'react-router-dom';
import CreatedTokenPreview from '../../components/app/token/CreatedTokenPreview';
import Footers from '../../components/common/Footers';
import Headers from '../../components/common/Headers';
import isEmptyObject from '../../lib/isEmptyObject';

const { Content } = Layout;

function Preview() {
  window.document.title = 'ERC-20 Token • Token Preview';
  const { tokenFromData } = useSelector((state) => state.token);
  const navigate = useNavigate();

  return isEmptyObject(tokenFromData) ? (
    <Navigate to='/app/create-token' replace />
  ) : (
    <Layout>
      {/* PREVIEW HEADERS */}
      <Headers />

      {/* PREVIEW CONTENTS */}
      <Content
        className='relative overflow-y-auto px-2 md:px-12 lg:px-24'
        style={{
          minHeight: 'calc(100vh - 170px)'
        }}
      >
        <PageHeader
          className='site-page-header'
          onBack={() => {
            navigate('/app/create-token');
          }}
          title='Back to Create Token'
        />

        {/*  TOKEN PREVIEW COMPONENT  */}
        <CreatedTokenPreview />
      </Content>

      {/* PREVIEW FOOTERS */}
      <Footers />
    </Layout>
  );
}

export default Preview;
