import { WarningOutlined } from '@ant-design/icons';
import { Divider, Progress, Typography } from 'antd';
import React from 'react';
import PrivateRoute from '../../routes/PrivateRoute';
import KycApplicationForm from './KycApplicationForm';

const { Title, Text } = Typography;

function KycApplication() {
  return (
    <PrivateRoute>
      <div className='min-h-[68vh] flex flex-col items-center justify-start'>
        <Title level={2}>Begin your ID-Verification</Title>
        <Text className='mb-2'>Verify your identity to participate in token sale.</Text>

        <div className='bg-backgroundColorWhite w-full xl:w-[70%] px-6 py-2 shadow-md rounded-sm'>
          {/* HEADER SECTION */}
          <div className='flex flex-row items-center justify-start space-x-4'>
            <Progress
              type='circle'
              percent={100}
              width={55}
              strokeColor={{
                '0%': '#108ee9',
                '100%': '#87d068'
              }}
            />

            <div>
              <Title level={4}>Personal Details</Title>
              <Text>Your basic information is required for indemnification purpose.</Text>
            </div>
          </div>

          <Divider />

          {/* ALERT SECTION */}
          <div className='flex flex-row items-center justify-start my-6 space-x-4'>
            <WarningOutlined className='text-warningColor' />
            <Text className='text-start'>Please type carefully and fill out the form with your personal details. You are not allowed to edit details once you have submitted the application.</Text>
          </div>

          {/* KYC APPLICATION FORM SECTION */}
          <KycApplicationForm />
        </div>
      </div>
    </PrivateRoute>
  );
}

export default KycApplication;
