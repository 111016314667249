import {
  Card, Statistic, Typography
} from 'antd';
import React from 'react';

const { Title } = Typography;

function PaymentCard({ payment }) {
  return (
    <Card className='dashboard-card cursor-default h-56 px-2 sm:px-4' hoverable>
      <Title className='inline-flex items-center' level={4}>
        Payment Status
      </Title>

      <div className='flex flex-row justify-start items-center space-x-8 mt-4'>
        <Statistic
          title=' Payment Success'
          value={payment.success}
          valueStyle={{ color: '#3f8600' }}
          prefix='✓'
        />

        <Statistic
          title='Payment Failed'
          value={payment.failed}
          valueStyle={{ color: '#cf1322' }}
          prefix='✘'
        />
      </div>
    </Card>
  );
}

export default PaymentCard;
