// kys application status based return color
export const responseAsColor = (status) => {
  if (status === 'pending') {
    return 'warning';
  }
  if (status === 'rejected') {
    return 'error';
  }
  if (status === 'approved' || status === 'approve') {
    return 'success';
  }
  return 'default';
};

// ico stage status based return color & status
export const responseAsColorAndStatus = (status) => {
  if (status === 0) {
    return {
      color: 'warning',
      status: 'Draft'
    };
  }
  if (status === 1) {
    return {
      color: 'success',
      status: 'Public'
    };
  }
  if (status === 5) {
    return {
      color: 'error',
      status: 'Closed'
    };
  }
  return {
    color: 'default',
    status: 'Unknown'
  };
};

// support ticket status based return color & status
export const supportTicketResponseAsStatus = (status) => {
  if (status === 0) {
    return {
      color: 'cyan',
      status: 'New'
    };
  }
  if (status === 1) {
    return {
      color: 'success',
      status: 'Open'
    };
  }
  if (status === 5) {
    return {
      color: 'error',
      status: 'Closed'
    };
  }
  return {
    color: 'default',
    status: 'Unknown'
  };
};

// token purchase status based return color & message
export const purchaseResponseAsStatus = (status) => {
  if (status === 0) {
    return {
      color: 'warning',
      status: 'Pending'
    };
  }
  if (status === 1) {
    return {
      color: 'success',
      status: 'Success'
    };
  }
  if (status === 2) {
    return {
      color: 'error',
      status: 'Failed'
    };
  }
  if (status === 3) {
    return {
      color: 'blue',
      status: 'In Process'
    };
  }
  if (status === 4) {
    return {
      color: '#f50',
      status: 'Initiated Invalid'
    };
  }
  return {
    color: 'default',
    status: 'Unknown'
  };
};

// air-drop status based on return color & message
export const airDropResponseAsStatus = (status) => {
  if (status === 0) {
    return {
      color: 'warning',
      status: 'Pending'
    };
  }
  if (status === 1) {
    return {
      color: 'success',
      status: 'Success'
    };
  }
  if (status === 6) {
    return {
      color: 'error',
      status: 'Failed'
    };
  }
  return {
    color: 'default',
    status: 'Unknown'
  };
};

export const tokenConfigResponseAsStatus = (status) => {
  if (status === 0) {
    return 'No';
  }
  if (status === 1) {
    return 'Yes';
  }
  return 'N/A';
};
