import { Layout, PageHeader } from 'antd';
import React from 'react';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import AirDropConfirm from '../../components/admin/ico/AirDropConfirm';
import Footers from '../../components/common/Footers';
import Headers from '../../components/common/Headers';
import isEmptyObject from '../../lib/isEmptyObject';

const { Content } = Layout;

function AirDrop() {
  window.document.title = 'ERC-20 Token • Air-Drop';
  const location = useLocation();
  const navigate = useNavigate();

  return isEmptyObject(location?.state?.stageInfo) ? (
    <Navigate to='/admin/dashboard' replace />
  ) : (
    <Layout>
      {/* PREVIEW HEADERS */}
      <Headers />

      {/* PREVIEW CONTENTS */}
      <Content
        className='relative overflow-y-auto px-2 md:px-12 lg:px-24'
        style={{ minHeight: 'calc(100vh - 170px)' }}
      >
        <PageHeader
          className='site-page-header'
          onBack={() => {
            navigate('/admin/declared-ico');
          }}
          title='Back to Declared ICO'
        />

        {/* AIR DROP CONFIRMATION */}
        <AirDropConfirm stageInfo={location.state.stageInfo} />
      </Content>

      {/* PREVIEW FOOTERS */}
      <Footers />
    </Layout>
  );
}

export default AirDrop;
