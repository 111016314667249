import { LoadingOutlined, LockOutlined } from '@ant-design/icons';
import {
  Alert, Button, Divider, Form, Input, Layout, Result
} from 'antd';
import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { load } from 'recaptcha-v3';
import Background from '../../assets/images/background.jpg';
import Logo from '../../assets/images/logo.svg';
import CaptchaFooter from '../../components/common/CaptchaFooter';
import eventMsg from '../../data/event-message.json';
import notificationWithIcon from '../../utils/popupNotification';

const { Sider, Content } = Layout;

function ResetPassword() {
  window.document.title = 'ERC-20 Token • Reset Password';
  const [tokenVerifyError, setTokenVerifyError] = useState(true);
  const [tokenUserMail, setTokenUserMail] = useState('');
  const [captchaToken, setCaptchaToken] = useState('');
  const [refreshToken, setRefreshToken] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errMsg, setErrMsg] = useState('');
  const navigation = useNavigate();
  const { token } = useParams();

  // generate google re-captcha-v3 token
  useEffect(() => {
    load(process.env.REACT_APP_CAPTCHA_SITE_KEY, {
      autoHideBadge: true
    }).then((recaptcha) => {
      recaptcha.execute().then((token2) => {
        setCaptchaToken(token2); // Will print the token
      });
    });
  }, [refreshToken]);

  // verify reset password token
  useEffect(() => {
    if (token) {
      const requestOptions = {
        method: 'GET',
        redirect: 'follow'
      };

      fetch(`${process.env.REACT_APP_API_BASE_URL}/api/v1/auth/verify-code/${token}`, requestOptions)
        .then((response) => response.json())
        .then((result) => {
          if (result.result_code === 0) {
            setTokenUserMail(result.result.email);
            setTokenVerifyError(false);
          }
        })
        .catch(() => {
          setTokenVerifyError(true);
        });
    }
  }, []);

  // function to reset password
  const resetPassword = (values) => {
    if (captchaToken) {
      if (values.password !== values.confirmPassword) {
        setErrMsg(eventMsg.auth.resetPassword.passwordNotMatchError);
      } else {
        setLoading(true);
        const myHeaders = new Headers();
        myHeaders.append('Content-Type', 'application/json');

        const raw = JSON.stringify({
          email: tokenUserMail,
          token,
          password: values.password,
          confirm_password: values.confirmPassword
        });

        const requestOptions = {
          method: 'POST',
          headers: myHeaders,
          body: raw,
          redirect: 'follow'
        };

        fetch(`${process.env.REACT_APP_API_BASE_URL}/api/v1/auth/forget-password`, requestOptions)
          .then((response) => response.json())
          .then((result) => {
            if (result.result_code === 0) {
              setLoading(false);
              navigation('/auth/login');
              notificationWithIcon('success', eventMsg.auth.resetPassword.success.title, eventMsg.auth.resetPassword.success.message);
            } else {
              setRefreshToken(!refreshToken);
              setErrMsg(result.error.message);
              setLoading(false);
            }
          })
          .catch((error) => {
            setRefreshToken(!refreshToken);
            setErrMsg(error.message);
            setLoading(false);
          });
      }
    }
  };

  useEffect(() => {
    setTimeout(() => {
      setErrMsg('');
    }, 6000);
  }, [errMsg]);

  return (tokenVerifyError ? (
    <div className='flex h-screen justify-center items-center'>
      <Result
        status='error'
        title='Verification Failed'
        subTitle='Sorry! Your token verification failed. Please try again. Thanks'
        extra={[
          <Link to='/app/home' key='home'>
            <Button type='primary'>Back to Home</Button>
          </Link>,
          <Link to='/auth/forgot-password' key='forgot-password'>
            <Button type='default'>Try to Again</Button>
          </Link>
        ]}
      />
    </div>
  ) : (
    <Layout
      className='min-h-screen bg-no-repeat bg-cover bg-left bg-fixed'
      style={{ backgroundImage: `url(${Background})` }}
    >
      <Content />

      <Sider className='!bg-backgroundColorWhite !min-w-[500px] px-10 pt-[15vh]'>
        {/* APP LOGO & GO TO DASHBOARD */}
        <Link to='/app/home'>
          <img src={Logo} className='w-32 mx-auto my-5' alt='erc-20-token' />
        </Link>

        {/* DIVIDER */}
        <Divider className='!text-primaryColor pb-5'>RESET YOUR PASSWORD HERE</Divider>

        {/* ERROR ALERT MESSAGE */}
        {errMsg && <Alert message={errMsg} type='error' className='!text-center' />}

        {/* RESET PASSWORD FROM */}
        <Form
          name='password-recovery-from'
          className='login-form mt-2'
          initialValues={{ remember: true }}
          onFinish={resetPassword}
          size='large'
        >
          {/* INPUT FIELD PASSWORD */}
          <Form.Item
            name='password'
            rules={[{
              type: 'string',
              required: true,
              message: 'Please input your Password!'
            }]}
          >
            <Input.Password
              prefix={<LockOutlined className='site-form-item-icon mr-2' />}
              placeholder='Type here your password'
              type='password'
            />
          </Form.Item>

          {/* INPUT FIELD CONFIRM PASSWORD */}
          <Form.Item
            name='confirmPassword'
            rules={[{
              type: 'string',
              required: true,
              message: 'Please input your Confirm Password!'
            }]}
          >
            <Input.Password
              prefix={<LockOutlined className='site-form-item-icon mr-2' />}
              placeholder='Type here your confirm password'
              type='password'
            />
          </Form.Item>

          {/* SUBMIT BUTTON */}
          <Form.Item>
            <Button
              className='login-form-button mt-5'
              htmlType='submit'
              type='primary'
              block
              disabled={loading}
            >
              {loading ? <LoadingOutlined /> : 'Reset Password'}
            </Button>
          </Form.Item>
        </Form>

        {/* GO TO REGISTRATION PAGE BUTTON */}
        <Link to='/auth/login'>
          <Button type='link' size='middle' className='login-form-button' block>
            Remember your password? Login instead
          </Button>
        </Link>

        {/* GOOGLE RE-CAPTCHA BADGE HIDE FOOTER */}
        <CaptchaFooter mt='4' />
      </Sider>
    </Layout>
  ));
}

export default ResetPassword;
