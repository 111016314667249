import { SearchOutlined } from '@ant-design/icons';
import {
  Button, Empty, Input, Pagination, Result, Select, Skeleton, Tag, Tooltip, Typography
} from 'antd';
import React, { useEffect, useState } from 'react';
import useFetchData from '../../../hooks/useFetchData';
import truncateString from '../../../lib/truncateString';
import { airDropResponseAsStatus, purchaseResponseAsStatus } from '../../../utils/helperShared';
import testnetAddress from '../../../utils/helperTestnetAddress';

const { Title } = Typography;
const { Option } = Select;

function Transactions() {
  window.document.title = 'ERC-20 Token • Transactions';
  const [searchKeyword, setSearchKeyword] = useState('');
  const [sortType, setSortType] = useState('aesc');
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);

  const [loading, error, response] = useFetchData(`${process.env.REACT_APP_API_BASE_URL}/api/v1/host-token-purchase-transaction-list?page=${page}&search=${searchKeyword}&limit=${limit}&sort=${sortType}`);

  // reset paginate page number
  useEffect(() => {
    setPage(1);
  }, [searchKeyword, limit]);

  return (
    <div className='bg-backgroundColorWhite min-h-[68vh] shadow-md rounded-sm p-4'>
      <Skeleton loading={loading} active paragraph={{ rows: 10 }}>
        {error ? (
          <Result
            status='error'
            title='Fetching Error'
            subTitle={error}
          />
        ) : (
          <div>
            <Title className='text-start pb-1' level={4}>Purchased Token Transactions List</Title>

            <div>
              {/* SEARCH BOX & FILTERS */}
              <div className='search-and-filter-box'>
                <Input
                  className='space-x-4'
                  placeholder='Type in to Search transactions'
                  onChange={(e) => setSearchKeyword(e.target.value)}
                  prefix={<SearchOutlined />}
                  size='large'
                />

                <Select
                  className='w-full sm:w-[240px]'
                  placeholder='-- show rows --'
                  onChange={(value) => setLimit(value)}
                  defaultValue='10'
                  size='large'
                >
                  <Option value='10'>10 Rows</Option>
                  <Option value='20'>20 Rows</Option>
                  <Option value='30'>30 Rows</Option>
                  <Option value='40'>40 Rows</Option>
                  <Option value='50'>50 Rows</Option>
                </Select>

                <Select
                  className='w-full sm:w-[240px]'
                  placeholder='-- select type to sort --'
                  onChange={(value) => setSortType(value)}
                  defaultValue='aesc'
                  size='large'
                >
                  <Option value='aesc'>Sort By Ascending</Option>
                  <Option value='desc'>Sort By Descending</Option>
                </Select>
              </div>

              {/* TRANSACTIONS LIST */}
              {response && response.result.rows.length === 0 ? (
                <Empty
                  className='mt-10'
                  description={(
                    <span>Sorry! Your transactions data was not found.</span>
                  )}
                />
              ) : (
                <div className='w-full shadow bg-white rounded my-3'>
                  <div className='border-gray-200 w-full rounded bg-white overflow-x-auto'>
                    <table className='w-full leading-normal'>
                      {/* TABLE HEADER */}
                      <thead className='table-thead'>
                        <tr className='border-b border-gray-200'>
                          <th scope='col' className='table-thead-th'>
                            TOKEN NAME (SYMBOL)
                          </th>
                          <th scope='col' className='table-thead-th'>
                            ACCEPTED CURRENCY
                          </th>
                          <th scope='col' className='table-thead-th'>
                            TOKEN QUANTITY
                          </th>
                          <th scope='col' className='table-thead-th'>
                            PURCHASE STATUS
                          </th>
                          <th scope='col' className='table-thead-th'>
                            FROM ADDRESS
                          </th>
                          <th scope='col' className='table-thead-th'>
                            TO ADDRESS
                          </th>
                          <th scope='col' className='table-thead-th'>
                            TRANX ID
                          </th>
                          <th scope='col' className='table-thead-th'>
                            AIR-DROP STATUS
                          </th>
                          <th scope='col' className='table-thead-th'>
                            PURCHASE DATE
                          </th>
                        </tr>
                      </thead>

                      {/* DATA MAPPING ON TABLE BODY */}
                      {response && response.result.rows.map((data) => (
                        <tbody key={data.id}>
                          <tr className='table-body-tr'>
                            <td className='table-body-td'>
                              {`${data.token_name} (${data.token_symbol})`}
                            </td>
                            <td className='table-body-td !uppercase'>
                              {data.accepted_currency}
                            </td>
                            <td className='table-body-td'>
                              {data.token_quantity}
                            </td>
                            <td className='table-body-td'>
                              <Tag
                                className='px-2 py-1 text-sm capitalize'
                                color={purchaseResponseAsStatus(data.status).color}
                              >
                                {purchaseResponseAsStatus(data.status).status}
                              </Tag>
                            </td>
                            <td className='table-body-td'>
                              <Tooltip title={data.send_from_address}>
                                {truncateString(data.send_from_address, 14)}
                              </Tooltip>
                            </td>
                            <td className='table-body-td'>
                              <Tooltip title={data.send_to_address}>
                                {truncateString(data.send_to_address, 12)}
                              </Tooltip>
                            </td>
                            <td className='table-body-td'>
                              {data.trx_id ? (
                                <Tooltip title={data.trx_id}>
                                  <Button
                                    className='!px-0'
                                    href={testnetAddress(data?.network, data?.trx_id)}
                                    target='_blank'
                                    size='middle'
                                    type='link'
                                  >
                                    {truncateString(data.trx_id, 12)}
                                  </Button>
                                </Tooltip>
                              ) : 'N/A'}
                            </td>
                            <td className='table-body-td !uppercase'>
                              <Tag
                                className='px-2 py-1 text-sm capitalize'
                                color={airDropResponseAsStatus(data.air_drop_status).color}
                              >
                                {airDropResponseAsStatus(data.air_drop_status).status}
                              </Tag>
                            </td>
                            <td className='table-body-td'>
                              {data.created_at.split(' ')[0]}
                            </td>
                          </tr>
                        </tbody>
                      ))}
                    </table>
                  </div>
                </div>
              )}

              {/* TRANSITIONS LIST PAGINATION */}
              {response && response.result.total_page > 1 && (
                <Pagination
                  className='flex items-center justify-center'
                  defaultCurrent={1}
                  total={response.result.total_page * 10}
                  onChange={(e) => setPage(e)}
                />
              )}
            </div>
          </div>
        )}
      </Skeleton>
    </div>
  );
}

export default Transactions;
