/* eslint-disable no-underscore-dangle */
import { LoadingOutlined, UploadOutlined } from '@ant-design/icons';
import {
  Button, Checkbox, DatePicker, Divider, Empty, Form, Input, message, Modal, Result, Select, Skeleton, Tooltip, Upload
} from 'antd';
import moment from 'moment';
import React, {
  useCallback, useEffect, useRef, useState
} from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Webcam from 'react-webcam';
import eventMsg from '../../../data/event-message.json';
import countries from '../../../data/list-of-countries.json';
import useFetchData from '../../../hooks/useFetchData';
import { getSessionToken, getSessionUser, setSessionUserKeyAgainstValue } from '../../../utils/helperAuthentication';
import ga4EventAction from '../../../utils/helperGAEventAction';

const { Option } = Select;

const videoConstraints = {
  width: 1920,
  height: 1080,
  facingMode: 'user'
};

function KycApplicationForm() {
  const webcamRef = useRef(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [dateOfYear, setDateOfYear] = useState(null);
  const [selfieImg, setSelfieImg] = useState(null);
  const location = useLocation();
  const navigate = useNavigate();
  const token = getSessionToken();
  const user = getSessionUser();
  const { useForm } = Form;
  const [form] = useForm();

  // if already user save kyc application user re-direct dashboard
  useEffect(() => {
    if (user.kyc_id) navigate('/user/dashboard');
  }, []);

  // fetch kyc documents list API data
  const [loading, error, response] = useFetchData(`${process.env.REACT_APP_API_BASE_URL}/api/v1/get-kyc-document-type-list`);

  // function to take a selfie
  const handleOk = useCallback((e) => {
    e.preventDefault();
    const imageSrc = webcamRef.current.getScreenshot();
    setSelfieImg(imageSrc);
    setIsModalVisible(false);
  }, [webcamRef]);

  // function to handle the date of birth
  const handleDateChange = (event) => {
    if (event && event.isValid()) {
      const now = moment(new Date());
      const end = moment(event._d);
      const days = now.diff(end, 'years', false);
      setDateOfYear(days);
    }
  };

  // function to the handle KYC application form submission
  const handleKYCFormSubmit = (values) => {
    // google analytics event handler
    ga4EventAction('link', 'Save KYC Application', 'User Save KYC Application');

    setLoadingSubmit(true);
    const myHeaders = new Headers();
    myHeaders.append('Authorization', `Bearer ${token}`);

    const formdata = new FormData();
    formdata.append('first_name', values.firstName);
    formdata.append('last_name', values.lastName);
    formdata.append('date_of_birth', values.birthOfDate._i);
    formdata.append('phone', values.phoneNumber);
    formdata.append('nationality', values.nationality);
    formdata.append('document_type_id', values.documentType);
    formdata.append('country', values.country);
    formdata.append('city', values.city);
    formdata.append('address', values.address);
    formdata.append('postal_code', values.postalCode);
    formdata.append('file', values.documentImage.fileList[0].originFileObj);
    formdata.append('document_no', values.documentNumber);
    formdata.append('participant_image', selfieImg);

    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    };

    fetch(`${process.env.REACT_APP_API_BASE_URL}/api/v1/save-kyc`, requestOptions)
      .then((res) => res.json())
      .then((result) => {
        if (result.result_code === 0) {
          message.success(eventMsg.user.kycSaveSuccessMessage);
          form.resetFields();
          setSelfieImg(null);

          // set kyc_id store in session user
          setSessionUserKeyAgainstValue('kyc_id', result.result.id);

          // if user come here token click to token purchase button submit kyc
          if (location?.state?.tokenId) {
            // submit kyc application against this host token
            const myHeaders2 = new Headers();
            myHeaders2.append('Authorization', `Bearer ${token}`);
            myHeaders2.append('Content-Type', 'application/json');

            const raw2 = JSON.stringify({
              id: result.result.id,
              token_id: location.state.tokenId
            });

            const requestOptions2 = {
              method: 'POST',
              headers: myHeaders2,
              body: raw2,
              redirect: 'follow'
            };

            fetch(`${process.env.REACT_APP_API_BASE_URL}/api/v1/request-kyc-approval`, requestOptions2)
              .then((res2) => res2.json())
              .then((result2) => {
                setLoadingSubmit(false);

                if (result2.result_code === 0) {
                  message.success(eventMsg.user.tokenPurchase.kycSubmitSuccessMessage);

                  // re-direct to kyc submit message page
                  navigate('/user/kyc-message', {
                    state: {
                      userName: `${user.first_name} ${user.last_name}`,
                      tokenName: location.state.tokenName
                    }
                  });
                } else {
                  message.error(result2.error.message);
                }
              })
              .catch((err2) => {
                message.error(err2.message);
                setLoadingSubmit(false);
              });
          } else {
            // re-direct to view user kyc application
            setTimeout(() => {
              window.location.href = '/user/my-profile?tab=my-kyc-application';
            }, 600);
          }
        } else {
          message.error(result.error.message);
          setLoadingSubmit(false);
        }
      })
      .catch((err) => {
        message.error(err.message);
        setLoadingSubmit(false);
      });
  };

  return (
    <>
      <Skeleton loading={loading} active paragraph={{ rows: 8 }}>
        {error ? (
          <Result
            status='error'
            title='Fetching Error'
            subTitle={error}
          />
        ) : (
          response && response?.result === null ? (
            <Empty
              className='mt-10'
              description={(
                <span>Sorry! KYC documents list data was not found !!!</span>
              )}
            />
          ) : (
            <Form
              form={form}
              layout='vertical'
              autoComplete='off'
              onFinish={handleKYCFormSubmit}
            >
              {/* INPUT GROUP USER NAME */}
              <div className='kyc-application-form-group'>
                <Form.Item
                  className='kyc-application-form-item'
                  label='First Name: '
                  name='firstName'
                  rules={[{ required: true, message: 'Please input here your First name!' }]}
                >
                  <Input placeholder='Input here your First name' size='large' />
                </Form.Item>

                <Form.Item
                  className='kyc-application-form-item'
                  label='Last Name: '
                  name='lastName'
                  rules={[{ required: true, message: 'Please input here your Last name!' }]}
                >
                  <Input placeholder='Input here your Last name' size='large' />
                </Form.Item>
              </div>

              {/* SELECT SUBMITTED OPTION DOCUMENT TYPE */}
              <Form.Item
                className='kyc-application-form-item'
                label='Submitted Document Type: '
                name='documentType'
                rules={[{ required: true, message: 'Please select your Submit Document Type!' }]}
              >
                <Select
                  placeholder='-- select your submitted document type --'
                  size='large'
                >
                  {response && response.result.map((data) => (
                    <Option key={data.id} value={data.id}>{data.document_name}</Option>
                  ))}
                </Select>
              </Form.Item>

              {/* INPUT GROUP DOCUMENT NUMBER & IMAGE */}
              <div className='kyc-application-form-group'>
                <Form.Item
                  className='kyc-application-form-item'
                  label='Submitted Document Number: '
                  name='documentNumber'
                  rules={[{ required: true, message: 'Please input here your document Number!' }]}
                >
                  <Input placeholder='Input here your document number' size='large' />
                </Form.Item>

                <Form.Item
                  className='kyc-application-form-item'
                  label='Submitted Document Image: '
                  name='documentImage'
                  rules={[{ required: true, message: 'Please input here your document Image!' }]}
                  valuePropName='documentImage'
                >
                  <Upload
                    maxCount={1}
                    listType='picture'
                    name='documentImage'
                    accept='.jpg, .jpeg, .png'
                    beforeUpload={() => false}
                  >
                    <Button
                      className='inline-flex items-center'
                      icon={<UploadOutlined />}
                      size='large'
                    >
                      Click to upload document Image
                    </Button>
                  </Upload>
                </Form.Item>
              </div>

              {/* INPUT GROUP USER PHONE & DATE OF BIRTH */}
              <div className='kyc-application-form-group'>
                <Form.Item
                  className='kyc-application-form-item'
                  label='Phone Number: '
                  name='phoneNumber'
                  rules={[{ required: true, message: 'Please input here your Phone number!' }]}
                >
                  <Input type='tel' placeholder='Input here your Phone name' size='large' />
                </Form.Item>

                {/* DATA PICKER USER DATE OF BIRTH */}
                <Form.Item
                  className='kyc-application-form-item'
                  label='Date Of Birth: '
                  name='birthOfDate'
                  rules={[{
                    validator: () => (dateOfYear > 18 ? Promise.resolve() : Promise.reject(new Error('You must be at least 18 years old to apply for this service!')))
                  }]}
                  valuePropName='birthOfDate'
                >
                  <DatePicker
                    className='w-full'
                    size='large'
                    name='birthOfDate'
                    format='DD-MM-YYYY'
                    onChange={(e) => handleDateChange(e)}
                  />
                </Form.Item>
              </div>

              {/* INPUT GROUP USER NATIONALITY & COUNTRY */}
              <div className='kyc-application-form-group'>
                <Form.Item
                  className='kyc-application-form-item'
                  label='Nationality: '
                  name='nationality'
                  rules={[{ required: true, message: 'Please input here your Nationality!' }]}
                >
                  <Input placeholder='Input here your Nationality' size='large' />
                </Form.Item>

                <Form.Item
                  className='kyc-application-form-item'
                  label='Country: '
                  name='country'
                  rules={[{ required: true, message: 'Please input here your Country!' }]}
                >
                  <Select
                    placeholder='-- select a option of your country --'
                    optionFilterProp='children'
                    size='large'
                    showSearch
                  >
                    {countries && countries.map((country) => (
                      <Option key={Math.random()} value={country.value}>{country.name}</Option>
                    ))}
                  </Select>
                </Form.Item>
              </div>

              {/* INPUT GROUP USER CITY & POSTAL CODE */}
              <div className='kyc-application-form-group'>
                <Form.Item
                  className='kyc-application-form-item'
                  label='City: '
                  name='city'
                  rules={[{ required: true, message: 'Please input here your City!' }]}
                >
                  <Input placeholder='Input here your City' size='large' />
                </Form.Item>

                <Form.Item
                  className='kyc-application-form-item'
                  label='Postal Code: '
                  name='postalCode'
                  rules={[{ required: true, message: 'Please input here your Postal Code!' }]}
                >
                  <Input type='number' placeholder='Input here your Postal Code' size='large' />
                </Form.Item>
              </div>

              {/* INPUT GROUP USER ADDRESS & IMAGE */}
              <div className='kyc-application-form-group'>
                <Form.Item
                  className='kyc-application-form-item'
                  label='Postal Address: '
                  name='address'
                  rules={[{ required: true, message: 'Please input here your Your Address!' }]}
                >
                  <Input placeholder='Input here your address' size='large' />
                </Form.Item>

                {/* TAKE A SELFIE INPUT FIELD */}
                <Form.Item
                  className='kyc-application-form-item'
                  label='Your Image: '
                  name='uImage'
                  rules={[{
                    validator: () => (selfieImg ? Promise.resolve() : Promise.reject(new Error('Please take a selfie for identification!')))
                  }]}
                >
                  {selfieImg ? (
                    <Tooltip
                      title='Click image to capture new photo !!!'
                      onClick={() => setIsModalVisible(true)}
                    >
                      <img className='rounded-sm shadow-sm cursor-pointer' src={selfieImg} alt='user-selfie' />
                    </Tooltip>
                  ) :
                    (
                      <Button
                        className='w-full inline-flex items-center'
                        icon={<UploadOutlined />}
                        size='large'
                        onClick={() => setIsModalVisible(true)}
                      >
                        Click to Take Selfie
                      </Button>
                    )}
                </Form.Item>
              </div>

              <Divider />

              {/* CHECKBOX TERMS & CONDITIONS */}
              <Form.Item
                name='termsAgreement'
                valuePropName='checked'
                rules={[{
                  validator: (_, value) => (value ? Promise.resolve() : Promise.reject(new Error('Please agree the Terms and Conditions!')))
                }]}
              >
                <Checkbox>
                  * I have read the Terms and Condition and Privacy and Policy.
                </Checkbox>
              </Form.Item>

              {/* CHECKBOX INFO CORRECTED */}
              <Form.Item
                name='corrected'
                valuePropName='checked'
                rules={[{
                  validator: (_, value) => (value ? Promise.resolve() : Promise.reject(new Error('Please check the provide Correct Information checkbox!')))
                }]}
              >
                <Checkbox>
                  * All the personal information i have entered is correct.
                </Checkbox>
              </Form.Item>

              {/* CHECKBOX INFO CORRECTED */}
              <Form.Item
                name='certifyAgreement'
                valuePropName='checked'
                rules={[{
                  validator: (_, value) => (value ? Promise.resolve() : Promise.reject(new Error('Please check the Certify Agreement checkbox!')))
                }]}
              >
                <Checkbox>
                  * I certify that, I an registering to participate in the token distribution events(s) in the capacity of an individual (and beneficial owner) and not as an agent or representative of a third party corporate entity.
                </Checkbox>
              </Form.Item>

              {/* KYC APPLICATION SUBMIT BUTTON */}
              <Form.Item className='flex items-center justify-center'>
                <Button
                  className='kyc-application-form-submit-btn'
                  htmlType='submit'
                  type='primary'
                  size='large'
                  disabled={loadingSubmit}
                >
                  {loadingSubmit ? <LoadingOutlined /> : 'Save KYC Application'}
                </Button>
              </Form.Item>
            </Form>
          )
        )}
      </Skeleton>

      {/* MODAL FOR TAKE SELFIE */}
      <Modal
        title='Take a Selfie in Webcam'
        visible={isModalVisible}
        open={isModalVisible}
        okText='Capture'
        onOk={handleOk}
        onCancel={() => setIsModalVisible(false)}
      >
        <Webcam
          width={720}
          height={360}
          audio={false}
          ref={webcamRef}
          screenshotFormat='image/png'
          videoConstraints={videoConstraints}
          imageSmoothing
        />
      </Modal>
    </>
  );
}

export default KycApplicationForm;
