import {
  AlignRightOutlined, DeleteOutlined, ExclamationCircleOutlined, PlusSquareOutlined, SearchOutlined
} from '@ant-design/icons';
import {
  Button, Card, Divider, Empty, Input, List, message, Modal, Pagination, Result, Select, Skeleton, Spin
} from 'antd';
import React, { useEffect, useState } from 'react';
import eventMsg from '../../../data/event-message.json';
import useFetchData from '../../../hooks/useFetchData';
import { getSessionToken } from '../../../utils/helperAuthentication';
import ga4EventAction from '../../../utils/helperGAEventAction';
import LoadingSpinner from '../../common/LoadingSpinner';
import AclRoute from '../../routes/AclRoute';

const { Option } = Select;
const { confirm } = Modal;

function AdminUserRoleCard() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isUpdatePermissionModalOpen, setIsUpdatePermissionModalOpen] = useState(false);
  const [isRoleAgainstPermissionModalOpen, setIsRoleAgainstPermissionModalOpen] = useState(false);
  const [assignPermissionLoading, setAssignPermissionLoading] = useState(false);
  const [rolePermissionFetchAgain, setRolePermissionFetchAgain] = useState(false);
  const [selectPermissions, setSelectPermissions] = useState([]);
  const [roleAddLoading, setRoleAddLoading] = useState(false);
  const [fetchAgain, setFetchAgain] = useState(false);
  const [roleName, setRoleName] = useState('');
  const [roleId, setRoleId] = useState(0);
  const [searchKeyword, setSearchKeyword] = useState('');
  const [sortType, setSortType] = useState('aesc');
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const token = getSessionToken();

  // admin role list data fetch
  const [loading, error, response] = useFetchData(`${process.env.REACT_APP_API_BASE_URL}/api/v1/get-role-list?page=${page}&search=${searchKeyword}&limit=${limit}&sort=${sortType}`, fetchAgain);

  // permissions list data fetch
  const [permissionLoading, permissionError, permissionResponse] = useFetchData(`${process.env.REACT_APP_API_BASE_URL}/api/v1/get-permission-list`);

  // role against permissions list data fetch
  const [rolePermissionLoading, rolePermissionError, rolePermissionResponse] = useFetchData(`${process.env.REACT_APP_API_BASE_URL}/api/v1/get-assigned-permission-to-role-list/${roleId}`, rolePermissionFetchAgain);

  // function to handle add new user role
  const handleAddNewRole = () => {
    // google analytics event handler
    ga4EventAction('link', 'Add New Role', 'Admin Create/Add User Role');

    if (roleName === '') {
      message.error('Please, Define a role name first');
    } else {
      setRoleAddLoading(true);
      const myHeaders = new Headers();
      myHeaders.append('Authorization', `Bearer ${token}`);
      myHeaders.append('Content-Type', 'application/json');

      const raw = JSON.stringify({
        role_name: roleName
      });

      const requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
      };

      fetch(`${process.env.REACT_APP_API_BASE_URL}/api/v1/save-role`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
          setRoleAddLoading(false);

          if (result.result_code === 0) {
            setRoleName('');
            setIsModalOpen(false);
            setFetchAgain(!fetchAgain);
            message.success(eventMsg.admin.acl.newRoleAddSuccessMessage);

            setTimeout(() => {
              window.location.reload();
            }, 500);
          } else {
            message.error(result.error.message);
          }
        })
        .catch((err) => {
          message.error(err.message);
          setRoleAddLoading(false);
        });
    }
  };

  // function to handle assign permissions against role
  const handleAssignPermissionsAgainstRole = () => {
    // google analytics event handler
    ga4EventAction('link', 'Role Against Assigned Permission', 'Admin Created Role Against Assigned Permission');

    if (selectPermissions.length === 0) {
      message.error('Please, Select an permissions against role');
    } else {
      setAssignPermissionLoading(true);
      const myHeaders = new Headers();
      myHeaders.append('Authorization', `Bearer ${token}`);
      myHeaders.append('Content-Type', 'application/json');

      const raw = JSON.stringify({
        role_id: roleId,
        permission_ids: selectPermissions
      });

      const requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
      };

      fetch(`${process.env.REACT_APP_API_BASE_URL}/api/v1/assign-permission-to-role`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
          setAssignPermissionLoading(false);

          if (result.result_code === 0) {
            setSelectPermissions([]);
            setIsUpdatePermissionModalOpen(false);
            setRolePermissionFetchAgain(!rolePermissionFetchAgain);
            message.success(eventMsg.admin.acl.roleAgainstPermissionAssignedSuccess);
          } else {
            message.error(result.error.message);
          }
        })
        .catch((err) => {
          message.error(err.message);
          setAssignPermissionLoading(false);
        });
    }
  };

  // function to handle role assigned permission delete
  const handleRoleAssignedPermissionDelete = (id) => {
    // google analytics event handler
    ga4EventAction('link', 'Role Against Delete Permission', 'Admin Created Role Against Delete Permission');

    confirm({
      title: 'Removed permission',
      icon: <ExclamationCircleOutlined />,
      content: 'Are you sure removed this permission against user?',
      okType: 'danger',
      cancelText: 'No',
      okText: 'Yes',

      onOk() {
        return new Promise((resolve, reject) => {
          const myHeaders = new Headers();
          myHeaders.append('Authorization', `Bearer ${token}`);
          myHeaders.append('Content-Type', 'application/json');

          const raw = JSON.stringify({
            ids: [id]
          });

          const requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
          };

          fetch(`${process.env.REACT_APP_API_BASE_URL}/api/v1/remove-assigned-permission-to-role`, requestOptions)
            .then((res) => res.json())
            .then((result) => {
              resolve();

              if (result.result_code === 0) {
                setRolePermissionFetchAgain(!rolePermissionFetchAgain);
                message.success(eventMsg.admin.acl.roleAgainstPermissionDeleteSuccess);
              } else {
                message.error(result.error.message);
              }
            })
            .catch((err) => {
              message.error(err.message);
              reject();
            });
        }).catch(() => {
          message.error('Oops errors!');
        });
      }
    });
  };

  // if role against permissions to set selected permission
  useEffect(() => {
    setSelectPermissions(rolePermissionResponse ? (
      rolePermissionResponse.result.map((data) => (data.permission_id))
    ) : []);
  }, [roleId, rolePermissionResponse]);

  // reset paginate page number
  useEffect(() => {
    setPage(1);
  }, [searchKeyword, limit]);

  return (
    <>
      <Card
        className='bg-backgroundColorWhite min-h-[40vh] shadow-sm rounded-sm'
        title='Admin User Role'
        extra={(
          <AclRoute hasOwnPermission='Ico.Role.Create'>
            <Button
              className='inline-flex items-center'
              onClick={() => setIsModalOpen(true)}
              icon={<PlusSquareOutlined />}
              type='primary'
              size='large'
            >
              Add User Role
            </Button>
          </AclRoute>
        )}
      >
        <AclRoute hasOwnPermission='Ico.Role.View'>
          <Skeleton loading={loading} active paragraph={{ rows: 10 }}>
            {error ? (
              <Result
                title='Fetching Error'
                subTitle={error}
                status='error'
              />
            ) : (
              <div>
                {/* SEARCH BOX & FILTERS */}
                <div className='search-and-filter-box'>
                  <Input
                    className='space-x-4'
                    placeholder='Type in to Search user roles'
                    onChange={(e) => setSearchKeyword(e.target.value)}
                    prefix={<SearchOutlined />}
                    size='large'
                  />

                  <Select
                    className='w-full sm:w-[240px]'
                    placeholder='-- show rows --'
                    onChange={(value) => setLimit(value)}
                    defaultValue='10'
                    size='large'
                  >
                    <Option value='10'>10 Rows</Option>
                    <Option value='20'>20 Rows</Option>
                    <Option value='30'>30 Rows</Option>
                    <Option value='40'>40 Rows</Option>
                    <Option value='50'>50 Rows</Option>
                  </Select>

                  <Select
                    className='w-full sm:w-[240px]'
                    placeholder='-- select type to sort --'
                    onChange={(value) => setSortType(value)}
                    defaultValue='aesc'
                    size='large'
                  >
                    <Option value='aesc'>Sort By Ascending</Option>
                    <Option value='desc'>Sort By Descending</Option>
                  </Select>
                </div>

                {/* USER ROLE LIST */}
                {response && response.result.rows.length === 0 ? (
                  <Empty
                    className='mt-10'
                    description={(<span>Sorry! Your users roles data was not found.</span>)}
                  />
                ) : (
                  <div className='w-full shadow bg-white rounded my-3'>
                    <div className='border-gray-200 w-full rounded bg-white overflow-x-auto'>
                      <table className='w-full leading-normal'>
                        {/* TABLE HEADER */}
                        <thead className='table-thead'>
                          <tr className='border-b border-gray-200'>
                            <th scope='col' className='table-thead-th'>ROLE NAME</th>
                            <th scope='col' className='table-thead-th'>ACTIONS</th>
                          </tr>
                        </thead>

                        {/* DATA MAPPING ON TABLE BODY */}
                        {response && response.result.rows.map((data) => (
                          <tbody key={data.id}>
                            <tr className='table-body-tr'>
                              <td className='table-body-td !w-[50%]'>
                                {data.role_name}
                              </td>
                              <td className='table-body-td !w-[50%] space-x-2'>
                                <AclRoute hasOwnPermission='Ico.Permission.View'>
                                  <Button
                                    type='primary'
                                    onClick={() => {
                                      setRoleId(data.id);
                                      setRoleName(data.role_name);
                                      setIsRoleAgainstPermissionModalOpen(true);
                                    }}
                                  >
                                    VIEW DETAILS
                                  </Button>
                                </AclRoute>
                                <AclRoute hasOwnPermission='Ico.Permission.Role.Assign'>
                                  <Button
                                    type='default'
                                    onClick={() => {
                                      setRoleId(data.id);
                                      setIsUpdatePermissionModalOpen(true);
                                    }}
                                  >
                                    UPDATE PERMISSIONS
                                  </Button>
                                </AclRoute>
                              </td>
                            </tr>
                          </tbody>
                        ))}
                      </table>
                    </div>
                  </div>
                )}

                {/* ADMIN USERS LIST PAGINATION */}
                {response && response.result.total_page > 1 && (
                  <Pagination
                    className='flex items-center justify-center m-4'
                    total={response.result.total_page * 10}
                    onChange={(e) => setPage(e)}
                    defaultCurrent={1}
                  />
                )}
              </div>
            )}
          </Skeleton>
        </AclRoute>
      </Card>

      {/* ADDING NEW ROLE MODAL */}
      <Modal
        title='Add New User Role'
        open={isModalOpen}
        visible={isModalOpen}
        confirmLoading={roleAddLoading}
        okText='Add New User Role'
        onOk={handleAddNewRole}
        onCancel={() => {
          setRoleName('');
          setIsModalOpen(false);
        }}
      >
        <Input
          className='space-x-2'
          placeholder='e.g. Admin'
          prefix={<AlignRightOutlined className='site-form-item-icon' />}
          onChange={(e) => setRoleName(e.target.value)}
          value={roleName}
          size='large'
        />
      </Modal>

      {/* UPDATE ROLE PERMISSIONS MODAL */}
      <Modal
        title='Update Role Against Permissions'
        open={isUpdatePermissionModalOpen}
        visible={isUpdatePermissionModalOpen}
        okText='Assigned Permission'
        onOk={handleAssignPermissionsAgainstRole}
        confirmLoading={assignPermissionLoading}
        onCancel={() => {
          setRoleId(0);
          setSelectPermissions([]);
          setIsUpdatePermissionModalOpen(false);
        }}
      >
        {permissionLoading && rolePermissionLoading ? (
          <LoadingSpinner />
        ) : (
          <Select
            className='w-full mb-16'
            placeholder='-- select permissions for an role --'
            onChange={(value) => setSelectPermissions(value)}
            status={permissionError && 'error'}
            value={selectPermissions}
            mode='multiple'
            size='large'
          >
            {permissionResponse && permissionResponse.result.map((data) => (
              <Select.Option key={data.id} value={data.id}>
                {data.action}
              </Select.Option>
            ))}
          </Select>
        )}
      </Modal>

      {/* ROLE AGAINST PERMISSIONS MODAL */}
      <Modal
        title='Role Against Assigned Permissions'
        open={isRoleAgainstPermissionModalOpen}
        visible={isRoleAgainstPermissionModalOpen}
        onCancel={() => {
          setIsRoleAgainstPermissionModalOpen(false);
        }}
        footer={[
          <Button
            key='cancel'
            onClick={() => {
              setRoleId(0);
              setRoleName('');
              setIsRoleAgainstPermissionModalOpen(false);
            }}
          >
            Cancel
          </Button>
        ]}
      >
        {rolePermissionLoading ? (
          <div className='flex items-center justify-center my-10'>
            <Spin tip='Loading...!!!' />
          </div>
        ) : rolePermissionError ? (
          <Result
            title='Fetching Error'
            subTitle={rolePermissionError}
            status='error'
          />
        ) : (rolePermissionResponse && (
          <List
            bordered
            size='large'
            header={<Divider className='!my-0 uppercase'>{roleName}</Divider>}
            dataSource={rolePermissionResponse.result}
            renderItem={(item) => (
              <List.Item
                actions={[
                  <AclRoute hasOwnPermission='Ico.Permission.Role.Assign.Update'>
                    <Button
                      icon={<DeleteOutlined />}
                      onClick={() => handleRoleAssignedPermissionDelete(item.id)}
                      type='default'
                      shape='circle'
                      size='small'
                      danger
                    />
                  </AclRoute>
                ]}
              >
                {item.action_name}
              </List.Item>
            )}
          />
        ))}
      </Modal>
    </>
  );
}

export default AdminUserRoleCard;
