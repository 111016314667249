import { GET_CHAIN_LIST_ERROR, GET_CHAIN_LIST_LOADING, GET_CHAIN_LIST_REQUEST } from '../constants/chainConstant';

const initialState = {
  // wallet chain list api
  chainListLoading: false,
  chainListError: null,
  chainListData: []
};

// eslint-disable-next-line default-param-last
const chainReducer = (state = initialState, action) => {
  switch (action.type) {
    // get wallet chain list
    case GET_CHAIN_LIST_LOADING:
      return {
        ...state,
        chainListLoading: true
      };
    case GET_CHAIN_LIST_ERROR:
      return {
        chainListLoading: false,
        chainListError: action.payload,
        chainListData: []
      };
    case GET_CHAIN_LIST_REQUEST:
      return {
        chainListLoading: false,
        chainListData: action.payload,
        chainListError: null
      };

    default:
      return state;
  }
};

export default chainReducer;
