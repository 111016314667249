import { DownloadOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import downloadString from '../../../lib/downloadString';
import convertToInternationalCurrencySystem from '../../../lib/internationalCurrencyConvert';
import truncateString from '../../../lib/truncateString';
import { deleteTokenFromData } from '../../../redux/actions/tokenAction';
import testnetAddress from '../../../utils/helperTestnetAddress';

function CreatedTokenSuccess() {
  const dispatch = useDispatch();
  const { tokenDeployData } = useSelector((tokenState) => tokenState.token);

  useEffect(() => {
    // delete token from data in redux store
    dispatch(deleteTokenFromData());
  }, []);

  return (
    <div className='bg-backgroundColorWhite min-h-[68vh]'>
      <h1 className='text-2xl text-center font-bold pt-5'>Token Deployed Successfully</h1>

      <div className='container flex justify-center mx-auto'>
        <div className='flex flex-col'>
          <div className='w-full'>
            <div className='border-b border-gray-200 shadow mt-5 mb-10'>
              <table className='divide-y divide-gray-300'>
                <thead className='bg-gray-50'>
                  <tr>
                    <th className='input-field-table-th'>Input Fields</th>
                    <th className='values-table-th'>Values</th>
                  </tr>
                </thead>

                <tbody className='bg-white divide-y divide-gray-300'>
                  {/* TOKEN NAME */}
                  {tokenDeployData && (
                    <tr className='whitespace-nowrap'>
                      <td className='px-6 py-4'>
                        <div className='text-md text-gray-800'>Token Name</div>
                      </td>
                      <td className='px-6 py-4'>
                        <div className='text-md text-gray-600'>
                          {tokenDeployData?.tokenData?.token_name}
                        </div>
                      </td>
                    </tr>
                  )}

                  {/* SYMBOLS */}
                  {tokenDeployData && (
                    <tr className='whitespace-nowrap'>
                      <td className='px-6 py-4'>
                        <div className='text-md text-gray-800'>Symbols</div>
                      </td>
                      <td className='px-6 py-4'>
                        <div className='text-md text-gray-600'>
                          {tokenDeployData?.tokenData?.token_symbol}
                        </div>
                      </td>
                    </tr>
                  )}

                  {/* INITIAL SUPPLY */}
                  {tokenDeployData && (
                    <tr className='whitespace-nowrap'>
                      <td className='px-6 py-4'>
                        <div className='text-md text-gray-800'>Total Supply</div>
                      </td>
                      <td className='px-6 py-4'>
                        <div className='text-md text-gray-600'>
                          {convertToInternationalCurrencySystem(tokenDeployData?.tokenData?.total_supply)}
                        </div>
                      </td>
                    </tr>
                  )}

                  {/* SUPPLY TYPE */}
                  {tokenDeployData && (
                    <tr className='whitespace-nowrap'>
                      <td className='px-6 py-4'>
                        <div className='text-md text-gray-800'>Supply Type</div>
                      </td>
                      <td className='px-6 py-4'>
                        <div className='text-md text-gray-600 capitalize'>
                          {tokenDeployData?.tokenData?.supply_type}
                        </div>
                      </td>
                    </tr>
                  )}

                  {/* DECIMALS */}
                  {tokenDeployData && (
                    <tr className='whitespace-nowrap'>
                      <td className='px-6 py-4'>
                        <div className='text-md text-gray-800'>Decimals(1-18)</div>
                      </td>
                      <td className='px-6 py-4'>
                        <div className='text-md text-gray-600'>
                          {tokenDeployData?.tokenData?.token_decimals}
                        </div>
                      </td>
                    </tr>
                  )}

                  {/* BURNING TOKEN */}
                  {tokenDeployData && (
                    <tr className='whitespace-nowrap'>
                      <td className='px-6 py-4'>
                        <div className='text-md text-gray-800'>Can Burn</div>
                      </td>
                      <td className='px-6 py-4'>
                        <div className='text-md text-gray-600'>
                          {tokenDeployData?.tokenData?.burning_token === 1 ? 'Yes' : 'No'}
                        </div>
                      </td>
                    </tr>
                  )}

                  {/* FREEZE TOKEN */}
                  {tokenDeployData && (
                    <tr className='whitespace-nowrap'>
                      <td className='px-6 py-4'>
                        <div className='text-md text-gray-800'>Can Freeze</div>
                      </td>
                      <td className='px-6 py-4'>
                        <div className='text-md text-gray-600'>
                          {tokenDeployData?.tokenData?.freeze === 1 ? 'Yes' : 'No'}
                        </div>
                      </td>
                    </tr>
                  )}

                  {/* NETWORK */}
                  {tokenDeployData && (
                    <tr className='whitespace-nowrap'>
                      <td className='px-6 py-4'>
                        <div className='text-md text-gray-800'>Network</div>
                      </td>
                      <td className='px-6 py-4'>
                        <div className='text-md text-gray-600 capitalize'>
                          {tokenDeployData?.tokenData?.network}
                        </div>
                      </td>
                    </tr>
                  )}

                  {/* OWNER ADDRESS */}
                  {tokenDeployData && (
                    <tr className='whitespace-nowrap'>
                      <td className='px-6 py-4'>
                        <div className='text-md text-gray-800'>Owner Address</div>
                      </td>
                      <td className='px-6 py-4'>
                        <div className='text-md text-gray-600'>
                          {tokenDeployData?.tokenData?.owner_address}
                        </div>
                      </td>
                    </tr>
                  )}

                  {/* DOWNLOAD - ABI, BYTECODE & SOURCE_CODE */}
                  <tr className='whitespace-normal lg:whitespace-nowrap'>
                    <td className='px-6 py-4'>
                      <div className='text-md text-gray-800'>Download</div>
                    </td>

                    <td className='px-6 py-4'>
                      <div className='items-start space-y-2'>
                        {/* ABI DOWNLOAD BUTTON */}
                        <Button
                          className='inline-flex items-center mr-2'
                          icon={<DownloadOutlined />}
                          shape='default'
                          type='dashed'
                          size='middle'
                          onClick={() => downloadString(tokenDeployData.abi, 'abi', 'json')}
                        >
                          ABI
                        </Button>

                        {/* BYTECODE DOWNLOAD BUTTON */}
                        <Button
                          className='inline-flex items-center mr-2'
                          icon={<DownloadOutlined />}
                          shape='default'
                          type='dashed'
                          size='middle'
                          onClick={() => downloadString(tokenDeployData.bytecode, 'bytecode', 'txt')}
                        >
                          BYTECODE
                        </Button>

                        {/* SOURCE CODE DOWNLOAD BUTTON */}
                        <Button
                          className='inline-flex items-center'
                          icon={<DownloadOutlined />}
                          shape='default'
                          type='dashed'
                          size='middle'
                          onClick={() => downloadString(tokenDeployData.source_code, 'source_code', 'sol')}
                        >
                          SOURCE CODE
                        </Button>
                      </div>
                    </td>
                  </tr>

                  {/* TRANSACTION HASH */}
                  <tr className='whitespace-nowrap'>
                    <td className='px-6 py-4'>
                      <div className='text-md text-gray-800'>Transaction Hash</div>
                    </td>

                    <td className='px-6 py-4'>
                      <Button
                        type='link'
                        size='middle'
                        target='_blank'
                        href={testnetAddress(tokenDeployData.tokenData.network, tokenDeployData.trxId)}
                      >
                        {truncateString(tokenDeployData.trxId, 27, '.....')}
                      </Button>
                    </td>
                  </tr>

                  {/* DEPLOYED BY */}
                  {tokenDeployData && tokenDeployData?.tokenData?.full_name && (
                    <tr className='whitespace-nowrap'>
                      <td className='px-6 py-4'>
                        <div className='text-md text-gray-800'>Deployed By</div>
                      </td>
                      <td className='px-6 py-4'>
                        <div className='text-md text-gray-600 capitalize'>
                          {`${tokenDeployData?.tokenData?.full_name} - ${tokenDeployData?.tokenData?.updated_at}`}
                        </div>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CreatedTokenSuccess;
