import {
  Button, Dropdown, Menu, Typography
} from 'antd';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import MetaMaskIcon from '../../assets/images/metamask.png';
import truncateString from '../../lib/truncateString';
import {
  deleteWalletAddress, deleteWalletBalance, deleteWalletChainId, deleteWalletNetwork
} from '../../redux/actions/walletAction';

const { Paragraph } = Typography;

function WalletDropdown() {
  const { walletAddress } = useSelector((state) => state.wallet);
  const dispatch = useDispatch();

  // wallet dropdown menu
  const menu = (
    <Menu
      items={[
        {
          key: '1',
          label: (
            <Paragraph
              className='flex items-center justify-between'
              copyable={{ text: walletAddress }}
            >
              Copy Address
            </Paragraph>
          )
        },
        {
          key: '2',
          label: <Paragraph>Quit Wallet</Paragraph>,
          onClick: () => {
            // removed metamask wallet data redux store
            dispatch(deleteWalletNetwork());
            dispatch(deleteWalletAddress());
            dispatch(deleteWalletBalance());
            dispatch(deleteWalletChainId());
          }
        }
      ]}
    />
  );

  return (
    <Dropdown
      overlay={menu}
      placement='bottomRight'
      arrow={{ pointAtCenter: true }}
    >
      <Button type='primary' shape='round' size='middle' className='w-[145px] inline-flex ml-2'>
        <span className='font-bold mr-5'>
          {truncateString(typeof walletAddress === 'object' ? walletAddress[0] : walletAddress, 13)}
        </span>
        <img src={MetaMaskIcon} alt='metamask-icon' className='absolute w-5 right-3' />
      </Button>
    </Dropdown>
  );
}

export default WalletDropdown;
