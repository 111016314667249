import { ExclamationCircleOutlined, LoadingOutlined } from '@ant-design/icons';
import {
  Button, Descriptions, Empty, Image, message, Modal, Result, Skeleton, Tag, Tooltip
} from 'antd';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import useFetchData from '../../../hooks/useFetchData';
import { updateApiState } from '../../../redux/actions/appAction';
import { getSessionToken } from '../../../utils/helperAuthentication';
import ga4EventAction from '../../../utils/helperGAEventAction';
import { responseAsColor } from '../../../utils/helperShared';
import AclRoute from '../../routes/AclRoute';

const { confirm } = Modal;

function MyKycDetails({ id, tokenId }) {
  const token = getSessionToken();
  const [visible, setVisible] = useState(false);
  const [imagePath, setImagePath] = useState('');
  const [approveLoading, setApprovedLoading] = useState(false);
  const [rejectedLoading, setRejectedLoading] = useState(false);
  const [fetchAgain, setFetchAgain] = useState(false);
  const dispatch = useDispatch();

  // redux store state
  const { reloadApiState } = useSelector((state) => state.app);

  const [loading, error, response] = useFetchData(`${process.env.REACT_APP_API_BASE_URL}/api/v1/get-kyc-detail/${id}/${tokenId}`, fetchAgain);

  // function to handle preview image
  const handlePreviewImage = (imageUrl) => {
    setVisible(true);
    setImagePath(process.env.REACT_APP_API_BASE_URL + imageUrl);
  };

  // function to handle approve or rejected kyc application
  const handleApprovedKYC = (status, active) => {
    // google analytics event handler
    ga4EventAction('link', 'Approve KYC Application', 'Admin Approve/Reject KYC Application');

    confirm({
      icon: <ExclamationCircleOutlined />,
      title: `${status} application?`,
      content: `Do you want to ${status} this KYC application?`,

      onOk() {
        if (active === 1) {
          setApprovedLoading(true);
        } else if (active === 2) {
          setRejectedLoading(true);
        }

        const myHeaders = new Headers();
        myHeaders.append('Authorization', `Bearer ${token}`);
        myHeaders.append('Content-Type', 'application/json');

        const raw = JSON.stringify({
          kyc_id: id,
          status,
          is_active: active,
          token_id: response.result.token_id
        });

        const requestOptions = {
          method: 'POST',
          headers: myHeaders,
          body: raw,
          redirect: 'follow'
        };

        fetch(`${process.env.REACT_APP_API_BASE_URL}/api/v1/approve-kyc`, requestOptions)
          .then((res) => res.json())
          .then((result) => {
            if (result.result_code === 0) {
              message.success(`KYC application ${status} successful`, 6);
              setFetchAgain(!fetchAgain);

              // re-fetch called api data for update state
              dispatch(updateApiState(!reloadApiState));
            } else {
              message.error(result.error.message, 6);
            }

            setApprovedLoading(false);
            setRejectedLoading(false);
          })
          .catch((err) => {
            message.error(err.message, 6);
            setApprovedLoading(false);
            setRejectedLoading(false);
          });
      }
    });
  };

  return (
    <Skeleton loading={loading} active avatar paragraph={{ rows: 6 }}>
      {error ? (
        <Result
          status='error'
          title='Fetching Error'
          subTitle={error}
        />
      ) : (
        response && response?.result === null ? (
          <Empty
            className='mt-10'
            description={(
              <span>Sorry! Your KYC application data was not found.</span>
            )}
          >
            <Link to='/user/kyc-application'>
              <Button type='primary'>Submit KYC Application</Button>
            </Link>
          </Empty>
        ) : (
          <>
            <Descriptions
              title='KYC Application Details'
              labelStyle={{ fontWeight: 'bold' }}
              bordered
              extra={(
                <Tag
                  className='px-2 py-1 text-sm capitalize'
                  color={responseAsColor(response?.result?.status)}
                >
                  {response?.result?.status}
                </Tag>
              )}
            >
              {/* PARTICIPANT IMAGE */}
              <Descriptions.Item label='Participant Image' span={3}>
                <Image
                  className='w-[200px] shadow-sm rounded-sm'
                  src={`${process.env.REACT_APP_API_BASE_URL}${response?.result?.participant_image}`}
                  alt='participant-image'
                />
              </Descriptions.Item>

              <Descriptions.Item label='Full Name'>
                {`${response?.result?.first_name} ${response?.result?.last_name}`}
              </Descriptions.Item>
              <Descriptions.Item label='Data Of Birth' span={2}>
                {response?.result?.date_of_birth}
              </Descriptions.Item>

              <Descriptions.Item label='Phone'>
                {response?.result?.phone}
              </Descriptions.Item>
              <Descriptions.Item label='Nationality' span={2}>
                {response?.result?.nationality}
              </Descriptions.Item>

              <Descriptions.Item label='Address'>
                {response?.result?.address}
              </Descriptions.Item>
              <Descriptions.Item label='City' span={2}>
                {response?.result?.city}
              </Descriptions.Item>

              <Descriptions.Item label='Postal Code'>
                {response?.result?.postal_code}
              </Descriptions.Item>
              <Descriptions.Item label='Country' span={2}>
                {response?.result?.country}
              </Descriptions.Item>

              <Descriptions.Item label='Document Name'>
                {response?.result?.document_name}
              </Descriptions.Item>

              <Descriptions.Item label={`${response?.result?.document_name} Number and Image`} span={2}>
                <Tooltip title='Click here to view document image !!!'>
                  <Button
                    type='link'
                    onClick={() => handlePreviewImage(response?.result?.document_image)}
                  >
                    {response?.result?.document_no}
                  </Button>
                </Tooltip>
              </Descriptions.Item>
            </Descriptions>

            {/* PREVIEW DOCUMENT IMAGE */}
            <Image
              width={200}
              style={{ display: 'none' }}
              src={imagePath}
              alt='document-image'
              preview={{
                visible,
                src: imagePath,
                onVisibleChange: (value) => {
                  setVisible(value);
                }
              }}
            />
          </>
        )
      )}

      <AclRoute hasOwnPermission='Ico.Kyc.Approve'>
        {response && response?.result?.status === 'pending' && (
        <div className='flex flex-row items-center justify-center space-x-4 mb-8'>
          {/* KYC APPLICATION APPROVE BUTTON */}
          <Button
            className='kyc-application-form-submit-btn'
            htmlType='submit'
            type='primary'
            size='large'
            onClick={() => handleApprovedKYC('approved', 1)}
            disabled={approveLoading}
          >
            {approveLoading ? <LoadingOutlined /> : 'Approve'}
          </Button>

          {/* KYC APPLICATION REJECTED BUTTON */}
          <Button
            className='kyc-application-form-submit-btn'
            htmlType='submit'
            type='default'
            size='large'
            danger
            onClick={() => handleApprovedKYC('rejected', 2)}
            disabled={rejectedLoading}
          >
            {rejectedLoading ? <LoadingOutlined /> : 'Reject'}
          </Button>
        </div>
        )}
      </AclRoute>
    </Skeleton>
  );
}

export default MyKycDetails;
