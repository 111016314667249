import {
  ArrowLeftOutlined, LoadingOutlined, LockOutlined, MailOutlined
} from '@ant-design/icons';
import {
  Alert, Button, Checkbox, Divider, Form, Input, Layout, message, Modal, Select
} from 'antd';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { load } from 'recaptcha-v3';
import Background from '../../assets/images/background.jpg';
import Logo from '../../assets/images/logo.svg';
import CaptchaFooter from '../../components/common/CaptchaFooter';
import eventMsg from '../../data/event-message.json';
import { setSessionUserAndToken } from '../../utils/helperAuthentication';
import ga4EventAction from '../../utils/helperGAEventAction';
import notificationWithIcon from '../../utils/popupNotification';

const { Sider, Content } = Layout;

function Login() {
  window.document.title = 'ERC-20 Token • Login';
  const [captchaToken, setCaptchaToken] = useState('');
  const [refreshToken, setRefreshToken] = useState(false);
  const [googleLoginToken, setGoogleLoginToken] = useState('');
  const [googleLoginUserType, setGoogleLoginUserType] = useState('');
  const [googleLoginUserTypeModal, setGoogleLoginUserTypeModal] = useState(false);
  const [googleLoginUserTypeLoading, setGoogleLoginUserTypeLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errMsg, setErrMsg] = useState('');

  // generate google re-captcha-v3 token
  useEffect(() => {
    load(process.env.REACT_APP_CAPTCHA_SITE_KEY, {
      autoHideBadge: true
    }).then((recaptcha) => {
      recaptcha.execute().then((token) => {
        setCaptchaToken(token); // Will print the token
      });
    });
  }, [refreshToken]);

  // function to handle user login
  const onFinish = async (values) => {
    // google analytics event handler
    ga4EventAction('link', 'Login', 'Admin/User Login');

    if (values.remember === false) {
      setErrMsg(eventMsg.auth.login.rememberMe);
    } else {
      setLoading(true);
      const myHeaders = new Headers();
      myHeaders.append('Content-Type', 'application/json');

      const raw = JSON.stringify({
        email: values.email,
        password: values.password,
        response_key: captchaToken
      });

      const requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
      };

      fetch(`${process.env.REACT_APP_API_BASE_URL}/api/v1/auth/login`, requestOptions)
        .then((response) => response.json())
        .then((result) => {
          if (result.result_code === 0) {
            setLoading(false);

            // session (localStorage) set user, access-token & refresh-token
            setSessionUserAndToken(result.result.userInfo, result.result.access_token, result.result.refresh_token);

            /**
             * if host redirect admin dashboard
             * else if participant redirect to user dashboard
             * else if sa redirect to super admin dashboard
             * else redirect to home
             */
            if (result.result.userInfo.user_type === 'participant') {
              window.location.href = '/user/dashboard';
            } else if (result.result.userInfo.user_type === 'host') {
              window.location.href = '/admin/dashboard';
            } else if (result.result.userInfo.user_type === 'sa') {
              window.location.href = '/super/dashboard';
            } else {
              window.location.href = '/';
            }
          } else {
            setRefreshToken(!refreshToken);
            setErrMsg(result.error.message);
            setLoading(false);
          }
        })
        .catch((error) => {
          setRefreshToken(!refreshToken);
          setErrMsg(error.message);
          setLoading(false);
        });
    }
  };

  // function to handle google sing-in
  const handleCallbackResponse = (response) => {
    const myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/json');

    const raw = JSON.stringify({
      platform: 'google',
      token: response.credential
    });

    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    fetch(`${process.env.REACT_APP_API_BASE_URL}/api/v1/auth/login-social`, requestOptions)
      .then((res) => res.json())
      .then((result) => {
        // if user type null to set user type
        if (result.result_code === 15) {
          setGoogleLoginToken(response.credential);
          setGoogleLoginUserTypeModal(true);
        } else if (result.result_code === 0) {
          // session (localStorage) set user, access-token & refresh-token
          setSessionUserAndToken(result.result.userInfo, result.result.access_token, result.result.refresh_token);

          /**
          * if host redirect admin dashboard
          * else if participant redirect to user dashboard
          * else redirect to home
          */
          if (result.result.userInfo.user_type === 'participant') {
            window.location.href = '/user/dashboard';
          } else if (result.result.userInfo.user_type === 'host') {
            window.location.href = '/admin/dashboard';
          } else {
            window.location.href = '/';
          }
        } else {
          notificationWithIcon('error', 'Google Sing-in Error', data.error.message);
        }
      })
      .catch((error) => {
        notificationWithIcon('error', 'Google Sing-in Error', error.message);
      });
  };

  // function to handle set google sing-up user type
  const handleToSetUserType = () => {
    if (googleLoginUserType === '') {
      message.error(eventMsg.auth.login.googleLoginUserTypeError);
    } else {
      setGoogleLoginUserTypeLoading(true);
      const myHeaders = new Headers();
      myHeaders.append('Content-Type', 'application/json');

      const raw = JSON.stringify({
        user_type: googleLoginUserType,
        token: googleLoginToken
      });

      const requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
      };

      fetch(`${process.env.REACT_APP_API_BASE_URL}/api/v1/auth/update-user-type`, requestOptions)
        .then((response) => response.json())
        .then((result) => {
          setGoogleLoginUserTypeLoading(false);

          if (result.result_code === 0) {
            // session (localStorage) set user, access-token & refresh-token
            setSessionUserAndToken(result.result.userInfo, result.result.access_token, result.result.refresh_token);

            /**
             * if host redirect admin dashboard
             * else if participant redirect to user dashboard
             * else redirect to home
             */
            if (result.result.userInfo.user_type === 'participant') {
              window.location.href = '/user/dashboard';
            } else if (result.result.userInfo.user_type === 'host') {
              window.location.href = '/admin/dashboard';
            } else {
              window.location.href = '/';
            }
          } else {
            notificationWithIcon('error', 'Google Sing-in Error', result.error.message);
            setGoogleLoginUserTypeLoading(false);
          }
        })
        .catch((error) => {
          notificationWithIcon('error', 'Google Sing-in Error', error.message);
          setGoogleLoginUserTypeLoading(false);
        });
    }
  };

  // generate google login credential
  useEffect(() => {
    /* global google */
    if (google) {
      google.accounts.id.initialize({
        client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
        callback: handleCallbackResponse
      });

      google.accounts.id.renderButton(document.getElementById('signInDiv'), {
        theme: 'filled_blue',
        width: 300
      });
    }
  }, []);

  // after 6 seconds set error message null
  useEffect(() => {
    setTimeout(() => {
      setErrMsg('');
    }, 6000);
  }, [errMsg]);

  return (
    <>
      <Layout
        className='min-h-screen bg-no-repeat bg-cover bg-left bg-fixed'
        style={{ backgroundImage: `url(${Background})` }}
      >
        <Content />

        <Sider className='!bg-backgroundColorWhite !min-w-[500px] pt-[1vh] px-10'>
          {/* APP LOGO & GO TO DASHBOARD */}
          <Link to='/app/home'>
            <img src={Logo} className='w-32 mx-auto my-5' alt='erc-20-token' />
          </Link>

          {/* DIVIDER */}
          <Divider className='!text-primaryColor px-1'>LOGIN HERE</Divider>

          {/* ERROR ALERT MESSAGE */}
          {errMsg && <Alert message={errMsg} type='error' className='!text-center' />}

          {/* LOGIN FROM */}
          <Form
            name='login-from'
            className='login-form mt-2'
            initialValues={{ remember: true }}
            onFinish={onFinish}
            size='large'
          >
            {/* INPUT FIELD EMAIL */}
            <Form.Item
              name='email'
              rules={[{
                type: 'email',
                required: true,
                message: 'Please input your Email!'
              }]}
            >
              <Input
                prefix={<MailOutlined className='site-form-item-icon mr-2' />}
                placeholder='Type here your email'
              />
            </Form.Item>

            {/* INPUT FIELD PASSWORD */}
            <Form.Item
              name='password'
              rules={[{
                type: 'string',
                required: true,
                message: 'Please input your Password!'
              }]}
            >
              <Input.Password
                prefix={<LockOutlined className='site-form-item-icon mr-2' />}
                placeholder='Type here your password'
                type='password'
              />
            </Form.Item>

            {/* REMEMBER CHECKBOX & GO TO FORGOT PASSWORD PAGE BUTTON */}
            <Form.Item>
              <Form.Item name='remember' valuePropName='checked' noStyle>
                <Checkbox>Remember me</Checkbox>
              </Form.Item>

              <Link to='/auth/forgot-password' className='login-form-forgot'>
                <Button type='link' size='middle' className='ml-32'>
                  Forgot password
                </Button>
              </Link>
            </Form.Item>

            {/* SUBMIT BUTTON */}
            <Form.Item>
              <Button
                className='login-form-button'
                htmlType='submit'
                type='primary'
                block
                disabled={loading}
              >
                {loading ? <LoadingOutlined /> : 'Login'}
              </Button>
            </Form.Item>
          </Form>

          {/* GO TO REGISTRATION PAGE BUTTON */}
          <Link to='/auth/registration'>
            <Button type='link' size='middle' className='login-form-button' block>
              Are not registered yet? Registration here
            </Button>
          </Link>

          {/* OR LOGIN DIVIDER */}
          <Divider className='!text-primaryColor px-1'>OR LOGIN WITH GOOGLE</Divider>

          {/* GOOGLE LOGIN BUTTON */}
          <div id='signInDiv' className='ml-14' />

          {/* BACK TO HOME BUTTON */}
          <Link to='/app/home'>
            <Button
              className='!w-[300px] mt-4 ml-[13.5%] inline-flex items-center justify-center'
              icon={<ArrowLeftOutlined />}
              type='primary'
              size='large'
            >
              Back to Home
            </Button>
          </Link>

          {/* GOOGLE RE-CAPTCHA BADGE HIDE FOOTER */}
          <CaptchaFooter mt='6' />
        </Sider>
      </Layout>

      {/* GOOGLE SING-UP USER TYPE SET MODAL */}
      <Modal
        title='Set User Type To Login'
        open={googleLoginUserTypeModal}
        visible={googleLoginUserTypeModal}
        okText='Set User Type'
        closable={false}
        footer={[
          <Button
            key='submit'
            type='primary'
            loading={googleLoginUserTypeLoading}
            disabled={googleLoginUserTypeLoading}
            onClick={handleToSetUserType}
          >
            Submit
          </Button>
        ]}
      >
        <Select
          className='w-full mb-16'
          placeholder='-- select user type --'
          onChange={(value) => setGoogleLoginUserType(value)}
          // value={googleLoginUserType}
          size='large'
        >
          <Select.Option value='participant'>Participant</Select.Option>
          <Select.Option value='host'>Host</Select.Option>
        </Select>
      </Modal>
    </>
  );
}

export default Login;
