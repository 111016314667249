import { composeWithDevTools } from '@redux-devtools/extension';
import { applyMiddleware, createStore } from 'redux';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import rootReducer from './reducers/rootReducer';

const thunk = require('redux-thunk').default;

// persist config
const persistConfig = {
  key: 'persist-wallet',
  storage,
  whitelist: ['wallet'] // only wallet will be persisted
};

// persist reducer
const persistedReducer = persistReducer(persistConfig, rootReducer);

// redux store
const store = createStore(persistedReducer, composeWithDevTools(applyMiddleware(thunk)));

// persist store
export const persistor = persistStore(store);

export default store;
