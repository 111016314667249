import { Layout, PageHeader } from 'antd';
import React from 'react';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import Footers from '../../components/common/Footers';
import Headers from '../../components/common/Headers';
import MyTokenPurchase from '../../components/user/purchase/MyTokenPurchase';
import isEmptyObject from '../../lib/isEmptyObject';

const { Content } = Layout;

function TokenPurchase() {
  window.document.title = 'ERC-20 Token • Token Purchase';
  const location = useLocation();
  const navigate = useNavigate();

  return isEmptyObject(location?.state?.stageInfo) ? (
    <Navigate to='/user/dashboard' replace />
  ) : (
    <Layout>
      {/* PREVIEW HEADERS */}
      <Headers />

      {/* PREVIEW CONTENTS */}
      <Content
        className='relative overflow-y-auto px-2 md:px-12 lg:px-24'
        style={{ minHeight: 'calc(100vh - 170px)' }}
      >
        <PageHeader
          className='site-page-header'
          onBack={() => {
            navigate('/user/ico-list');
          }}
          title='Back to Active ICO List'
        />

        {/* TOKEN PURCHASE COMPONENT */}
        <MyTokenPurchase stageInfo={location.state.stageInfo} />
      </Content>

      {/* PREVIEW FOOTERS */}
      <Footers />
    </Layout>
  );
}

export default TokenPurchase;
