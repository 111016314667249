import ReactGA from 'react-ga4';

/**
 * function to handle google analytics event actions
 * @param {String} myCategory google analytics event category name
 * @param {String} myAction google analytics event action name
 * @param {String} myLabel google analytics event label name
 * @returns action for reflection adding google analytics
 */
const ga4EventAction = (myCategory, myAction, myLabel) => ReactGA.event({
  category: myCategory,
  action: myAction,
  label: myLabel || null, // optional
  value: 99, // optional, must be a number
  nonInteraction: true, // optional, true/false
  transport: 'xhr' // optional, beacon/xhr/image
});

export default ga4EventAction;
