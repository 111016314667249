import {
  Descriptions, Result, Skeleton, Tag
} from 'antd';
import React from 'react';
import useFetchData from '../../../hooks/useFetchData';
import numberWithCommas from '../../../lib/numberFormat';
import { responseAsColorAndStatus, tokenConfigResponseAsStatus } from '../../../utils/helperShared';

function TokenDetails({ id }) {
  // fetch token declaration detail API data
  const [loading, error, response] = useFetchData(`${process.env.REACT_APP_API_BASE_URL}/api/v1/get-ico-declaration-detail-participant/${id}`);

  return (
    <Skeleton loading={loading} active avatar paragraph={{ rows: 10 }}>
      {error ? (
        <Result
          status='error'
          title='Fetching Error'
          subTitle={error}
        />
      ) : (
        response && response?.result && (
          <>
            {/* DECLARED ICO DESCRIPTION'S */}
            <Descriptions
              labelStyle={{ fontWeight: 'bold' }}
              bordered
            >
              <Descriptions.Item label='Token Name'>
                {response?.result?.token_name}
              </Descriptions.Item>
              <Descriptions.Item label='Token Symbol' span={2}>
                {response?.result?.token_symbol}
              </Descriptions.Item>

              <Descriptions.Item label='Total Supply'>
                {numberWithCommas(response?.result?.total_supply)}
              </Descriptions.Item>
              <Descriptions.Item label='Token Decimals' span={2}>
                {response?.result?.token_decimals}
              </Descriptions.Item>

              <Descriptions.Item label='Target Token Sale Quantity'>
                {numberWithCommas(response?.result?.target_token_sale)}
              </Descriptions.Item>
              <Descriptions.Item className='capitalize' label='Supply Type' span={2}>
                {response?.result?.supply_type}
              </Descriptions.Item>

              <Descriptions.Item label='Can Burn'>
                {tokenConfigResponseAsStatus(response?.result?.can_burn)}
              </Descriptions.Item>
              <Descriptions.Item label='Can Freeze' span={2}>
                {tokenConfigResponseAsStatus(response?.result?.is_freeze)}
              </Descriptions.Item>

              <Descriptions.Item className='capitalize' label='Token Issuer'>
                {response?.result?.token_issuer}
              </Descriptions.Item>
              <Descriptions.Item label='Mailing Address' span={2}>
                {response?.result?.contact_address ? response?.result?.contact_address : 'N/A'}
              </Descriptions.Item>

              <Descriptions.Item label='Description' span={3}>
                {response?.result?.description}
              </Descriptions.Item>

              <Descriptions.Item label='Contract Address'>
                {response?.result?.contract ? response?.result?.contract.contract_address : 'N/A'}
              </Descriptions.Item>
              <Descriptions.Item label='Total Stage' span={2}>
                {response?.result?.stages ? response?.result?.stages.length : 'N/A'}
              </Descriptions.Item>
            </Descriptions>

            {/* DECLARED ICO STAGE'S HEADER */}
            <h4 className='text-center text-xl font-semibold uppercase mt-4'>
              ICO Stage Information
            </h4>

            {/* DECLARED ICO STAGE'S DATA */}
            <div className='w-full shadow bg-white rounded my-3' span={2}>
              <div className='border-gray-200 w-full rounded bg-white overflow-x-auto'>
                <table className='w-full leading-normal'>
                  {/* TABLE HEADER */}
                  <thead className='table-thead'>
                    <tr className='border-b border-gray-200'>
                      <th scope='col' className='table-thead-th'>
                        SL
                      </th>
                      <th scope='col' className='table-thead-th'>
                        TOKEN PRICE
                      </th>
                      <th scope='col' className='table-thead-th'>
                        TOKEN SALE AMOUNT
                      </th>
                      <th scope='col' className='table-thead-th'>
                        BONUS
                      </th>
                      <th scope='col' className='table-thead-th'>
                        MIN PURCHASE
                      </th>
                      <th scope='col' className='table-thead-th'>
                        MAX PURCHASE
                      </th>
                      <th scope='col' className='table-thead-th'>
                        START DATE
                      </th>
                      <th scope='col' className='table-thead-th'>
                        END DATE
                      </th>
                      <th scope='col' className='table-thead-th'>
                        STATUS
                      </th>
                    </tr>
                  </thead>

                  {/* DATA MAPPING ON TABLE BODY */}
                  {response && response?.result?.stages.map((data, index) => (
                    <tbody key={data.id}>
                      <tr className='table-body-tr'>
                        <td className='table-body-td'>
                          {index + 1}
                        </td>
                        <td className='table-body-td'>
                          {`$ ${data.token_price} / 1 ${response?.result?.token_symbol}`}
                        </td>
                        <td className='table-body-td'>
                          {data.token_sale_amount}
                        </td>
                        <td className='table-body-td'>
                          {`${data.bonus} %`}
                        </td>
                        <td className='table-body-td'>
                          {data.min_purchase}
                        </td>
                        <td className='table-body-td'>
                          {data.max_purchase}
                        </td>
                        <td className='table-body-td'>
                          {(data.start_date).split(' ')[0]}
                        </td>
                        <td className='table-body-td'>
                          {(data.end_date).split(' ')[0]}
                        </td>
                        <td className='table-body-td'>
                          <Tag
                            className='px-2 py-1 text-sm capitalize'
                            color={responseAsColorAndStatus(data.status).color}
                          >
                            {responseAsColorAndStatus(data.status).status}
                          </Tag>
                        </td>
                      </tr>
                    </tbody>
                  ))}
                </table>
              </div>
            </div>

            {/* DECLARED ICO CURRENCY'S HEADER */}
            <h4 className='text-center text-xl font-semibold uppercase mt-4'>
              Accepted Currencies For Payment
            </h4>

            {/* DECLARED ICO CURRENCY'S DATA */}
            <div className='w-full shadow bg-white rounded my-3' span={2}>
              <div className='border-gray-200 w-full rounded bg-white overflow-x-auto'>
                <table className='w-full leading-normal'>
                  {/* TABLE HEADER */}
                  <thead className='table-thead'>
                    <tr className='border-b border-gray-200'>
                      <th scope='col' className='table-thead-th'>
                        WALLET ADDRESS
                      </th>
                      <th scope='col' className='table-thead-th'>
                        ACCEPTED CURRENCY
                      </th>
                      <th scope='col' className='table-thead-th'>
                        CURRENCY CODE (SYMBOL)
                      </th>
                    </tr>
                  </thead>

                  {/* DATA MAPPING ON TABLE BODY */}
                  {response && response?.result?.wallets.map((data) => (
                    <tbody key={data.id}>
                      <tr className='table-body-tr'>
                        <td className='table-body-td'>
                          {data.wallet_address}
                        </td>
                        <td className='table-body-td !capitalize'>
                          {data.currency_name}
                        </td>
                        <td className='table-body-td !uppercase'>
                          {`${data.currency_code} (${data.currency_symbol})`}
                        </td>
                      </tr>
                    </tbody>
                  ))}
                </table>
              </div>
            </div>
          </>
        )
      )}
    </Skeleton>
  );
}

export default TokenDetails;
