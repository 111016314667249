import {
  Card, Statistic, Typography
} from 'antd';
import React from 'react';

const { Title } = Typography;

function IcoCard({ ico }) {
  return (
    <Card className='dashboard-card h-56 px-2 sm:px-4' hoverable>
      <Title className='inline-flex items-center' level={4}>
        Initial Coin Offering (ICO)
      </Title>

      <div className='flex flex-row justify-start items-center space-x-10 mt-4'>
        <Statistic
          title='Draft ICO'
          value={ico.draftedIco}
          valueStyle={{ color: '#eab308' }}
          prefix='🡪'
        />

        <Statistic
          title='Open ICO'
          value={ico.openIco}
          valueStyle={{ color: '#3f8600' }}
          prefix='🡡'
        />

        <Statistic
          title='Closed ICO'
          value={ico.closedIco}
          valueStyle={{ color: '#cf1322' }}
          prefix='🡫'
        />
      </div>
    </Card>
  );
}

export default IcoCard;
