import { LoadingOutlined, SendOutlined } from '@ant-design/icons';
import {
  Button, Comment, Empty, Form, Input, message, Result, Select, Skeleton
} from 'antd';
import React, { useState } from 'react';
import eventMsg from '../../../data/event-message.json';
import useFetchData from '../../../hooks/useFetchData';
import { getSessionToken } from '../../../utils/helperAuthentication';
import ga4EventAction from '../../../utils/helperGAEventAction';
import UserAvatar from '../../common/UserAvatar';

const { TextArea } = Input;
const { Option } = Select;

function CreateSupportTicket() {
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const token = getSessionToken();
  const { useForm } = Form;
  const [form] = useForm();

  // get available declared ico list
  const [loading, error, response] = useFetchData(`${process.env.REACT_APP_API_BASE_URL}/api/v1/get-ico-declared-list`);

  // function to handle save a support ticket
  const handleSaveTicket = (values) => {
    // google analytics event handler
    ga4EventAction('link', 'Send Message', 'User Create Support Ticket');

    setLoadingSubmit(true);
    const myHeaders = new Headers();
    myHeaders.append('Authorization', `Bearer ${token}`);
    myHeaders.append('Content-Type', 'application/json');

    const raw = JSON.stringify({
      subject: values.ticketSubject,
      description: values.ticketDescription,
      token_id: values.declaredIcoId
    });

    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    fetch(`${process.env.REACT_APP_API_BASE_URL}/api/v1/save-support-ticket`, requestOptions)
      .then((res) => res.json())
      .then((result) => {
        setLoadingSubmit(false);

        if (result.result_code === 0) {
          message.success(eventMsg.user.supperTicketCreateSuccess);
          form.resetFields();

          setTimeout(() => {
            window.location.reload();
          }, 500);
        } else {
          message.error(result.error.message);
        }
      })
      .catch((err) => {
        setLoadingSubmit(false);
        message.error(err.message);
      });
  };

  return (
    <Skeleton loading={loading} active avatar paragraph={{ rows: 6 }}>
      {error ? (
        <Result
          status='error'
          title='Fetching Error'
          subTitle={error}
        />
      ) : response && response.result.length === 0 ? (
        <Empty
          className='mt-10'
          description={(
            <span>Sorry! Without purchase token can not raised support ticket.</span>
          )}
        />
      ) : (
        <Comment
          className='ml-0 md:ml-20 lg:ml-32'
          avatar={(<UserAvatar />)}
          content={(
            <Form
              name='create-ticket'
              autoComplete='off'
              initialValues={{ remember: true }}
              onFinish={handleSaveTicket}
              size='large'
              form={form}
            >
              <Form.Item
                className='w-full sm:w-[80%] md:w-[65%]'
                name='declaredIcoId'
                rules={[{
                  required: true,
                  message: 'Please select an available token'
                }]}
              >
                <Select
                  placeholder='-- select an available token --'
                  optionFilterProp='children'
                  showSearch
                  size='large'
                >
                  {response?.result?.map((data) => (
                    <Option key={data.id} value={data.id}>{data.token_name}</Option>
                  ))}
                </Select>
              </Form.Item>

              <Form.Item
                className='w-full sm:w-[80%] md:w-[65%]'
                name='ticketSubject'
                rules={[{
                  required: true,
                  message: 'Please input your ticket subject!'
                }]}
              >
                <Input placeholder='Type here your support ticket subject' />
              </Form.Item>

              <Form.Item
                className='w-full sm:w-[80%] md:w-[65%]'
                name='ticketDescription'
                rules={[{
                  required: true,
                  message: 'Please input your ticket description!'
                }]}
              >
                <TextArea placeholder='Type here your support ticket description' rows={5} />
              </Form.Item>

              <Form.Item>
                <Button
                  className={`w-[200px] mt-2 ${loadingSubmit || 'inline-flex items-center'}`}
                  icon={loadingSubmit || <SendOutlined />}
                  disabled={loadingSubmit}
                  htmlType='submit'
                  type='primary'
                  size='large'
                >
                  {loadingSubmit ? <LoadingOutlined /> : 'Send Message'}
                </Button>
              </Form.Item>
            </Form>
          )}
        />
      )}
    </Skeleton>
  );
}

export default CreateSupportTicket;
