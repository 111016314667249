import { Descriptions } from 'antd';
import React from 'react';
import numberWithCommas from '../../../lib/numberFormat';

function DeclaredIcoStageDetails({ stageInfo }) {
  return (
    <Descriptions
      title='DECLARED ICO STAGE DETAILS'
      labelStyle={{ fontWeight: 'bold' }}
      bordered
    >
      <Descriptions.Item className='capitalize' label='Token Name (Symbol)'>
        {`${stageInfo.token_name} (${stageInfo.token_symbol})`}
      </Descriptions.Item>
      <Descriptions.Item className='capitalize' label='Network' span={2}>
        {stageInfo.network}
      </Descriptions.Item>

      <Descriptions.Item label='Bonus'>
        {stageInfo.bonus ? `${stageInfo.bonus} %` : 'N/A'}
      </Descriptions.Item>
      <Descriptions.Item label='Token Price' span={2}>
        {`$ ${stageInfo.token_price}`}
      </Descriptions.Item>

      <Descriptions.Item label='Token Sale Amount'>
        {numberWithCommas(stageInfo.token_sale_amount)}
      </Descriptions.Item>
      <Descriptions.Item label='Token Sold Quantity' span={2}>
        {stageInfo.token_sold ? stageInfo.token_sold : 'N/A'}
      </Descriptions.Item>

      <Descriptions.Item label='Min Token Purchase'>
        {stageInfo.min_purchase ? stageInfo.min_purchase : '-'}
      </Descriptions.Item>
      <Descriptions.Item label='Max Token Purchase' span={2}>
        {stageInfo.max_purchase ? stageInfo.max_purchase : '-'}
      </Descriptions.Item>

      <Descriptions.Item label='Start Date'>
        {stageInfo.start_date.split(' ')[0]}
      </Descriptions.Item>
      <Descriptions.Item label='End Date' span={2}>
        {stageInfo.end_date.split(' ')[0]}
      </Descriptions.Item>

      <Descriptions.Item label='Token Description' span={3}>
        {stageInfo.description}
      </Descriptions.Item>
    </Descriptions>
  );
}

export default DeclaredIcoStageDetails;
