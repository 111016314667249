import { Layout, PageHeader } from 'antd';
import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate, useNavigate } from 'react-router-dom';
import CreatedTokenSuccess from '../../components/app/token/CreatedTokenSuccess';
import Footers from '../../components/common/Footers';
import Headers from '../../components/common/Headers';
import isEmptyObject from '../../lib/isEmptyObject';
import { getSessionUser } from '../../utils/helperAuthentication';

const { Content } = Layout;

function Success() {
  window.document.title = 'ERC-20 Token • Token Success';
  const { tokenDeployData } = useSelector((state) => state.token);
  const navigate = useNavigate();
  const user = getSessionUser();

  const backNavigate = () => {
    if (user && user.user_type === 'host') {
      return {
        path: '/admin/dashboard',
        heading: 'Back To Dashboard'
      };
    }

    if (user && user.user_type === 'participant') {
      return {
        path: '/user/dashboard',
        heading: 'Back To Dashboard'
      };
    }

    if (user && user.user_type === 'sa') {
      return {
        path: '/super/dashboard',
        heading: 'Back To Dashboard'
      };
    }

    return {
      path: '/app/hone',
      heading: 'Back To Home'
    };
  };

  return isEmptyObject(tokenDeployData) ? (
    <Navigate to='/app/create-token' replace />
  ) : (
    <Layout>
      {/* SUCCESS HEADERS */}
      <Headers />

      {/* SUCCESS CONTENTS */}
      <Content
        className='relative overflow-y-auto px-2 md:px-12 lg:px-24'
        style={{
          minHeight: 'calc(100vh - 170px)'
        }}
      >
        <PageHeader
          className='site-page-header'
          onBack={() => {
            navigate(backNavigate().path);
          }}
          title={backNavigate().heading}
        />

        {/* Token Success Data Show Component */}
        <CreatedTokenSuccess />
      </Content>

      {/* SUCCESS FOOTERS */}
      <Footers />
    </Layout>
  );
}

export default Success;
