import {
  AuditOutlined, CopyOutlined, DashboardOutlined, ReconciliationOutlined, TransactionOutlined, UnorderedListOutlined, UserOutlined
} from '@ant-design/icons';
import { Button, Layout, Tabs } from 'antd';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Footers from '../../components/common/Footers';
import Headers from '../../components/common/Headers';
import KycApplication from '../../components/user/kyc/KycApplication';
import IcoList from '../../components/user/tabs/IcoList';
import MyDashboard from '../../components/user/tabs/MyDashboard';
import MyProfile from '../../components/user/tabs/MyProfile';
import MyToken from '../../components/user/tabs/MyToken';
import SupportTicket from '../../components/user/tabs/SupportTicket';
import Transactions from '../../components/user/tabs/Transactions';
import { getSessionUser } from '../../utils/helperAuthentication';

const { Content } = Layout;
const { TabPane } = Tabs;

function MyUser() {
  window.document.title = 'ERC-20 Token • User Panel';
  const [activeTabKey, setActiveTabKey] = useState('1');
  const navigate = useNavigate();
  const user = getSessionUser();
  const { tab } = useParams();

  const handleTabChange = (key) => {
    switch (key) {
      case '1': {
        navigate('/user/dashboard');
        break;
      }
      case '2': {
        navigate('/user/ico-list');
        break;
      }
      case '3': {
        navigate('/user/transactions');
        break;
      }
      case '4': {
        navigate('/user/my-profile');
        break;
      }
      case '5': {
        navigate('/user/my-token');
        break;
      }
      case '6': {
        navigate('/user/support-ticket');
        break;
      }
      case '7': {
        navigate('/user/kyc-application');
        break;
      }
      default: {
        navigate('/user/dashboard');
      }
    }
  };

  useEffect(() => {
    switch (activeTabKey) {
      case '1': {
        window.document.title = 'ERC-20 Token • User Dashboard';
        break;
      }
      case '2': {
        window.document.title = 'ERC-20 Token • ICO List';
        break;
      }
      case '3': {
        window.document.title = 'ERC-20 Token • Transactions';
        break;
      }
      case '4': {
        window.document.title = 'ERC-20 Token • My Profile';
        break;
      }
      case '5': {
        window.document.title = 'ERC-20 Token • My Token';
        break;
      }
      case '6': {
        window.document.title = 'ERC-20 Token • Support Ticket';
        break;
      }
      case '7': {
        window.document.title = 'ERC-20 Token • KYC Application';
        break;
      }
      default: {
        window.document.title = 'ERC-20 Token • User Dashboard';
      }
    }
  }, [activeTabKey]);

  useEffect(() => {
    if (tab) {
      switch (tab) {
        case 'dashboard': {
          setActiveTabKey('1');
          break;
        }
        case 'ico-list': {
          setActiveTabKey('2');
          break;
        }
        case 'transactions': {
          setActiveTabKey('3');
          break;
        }
        case 'my-profile': {
          setActiveTabKey('4');
          break;
        }
        case 'my-token': {
          setActiveTabKey('5');
          break;
        }
        case 'support-ticket': {
          setActiveTabKey('6');
          break;
        }
        case 'kyc-application': {
          setActiveTabKey('7');
          break;
        }
        default: {
          navigate('/not-found');
        }
      }
    }
  }, [tab, navigate]);

  return (
    <Layout>
      {/* MY-APP HEADER */}
      <Headers />

      {/* MY-APP CONTENT */}
      <Content
        className='relative overflow-y-auto'
        style={{ minHeight: 'calc(100vh - 170px)' }}
      >
        <Tabs
          className='px-2 md:px-12 lg:px-24'
          activeKey={activeTabKey === '7' ? '7' : activeTabKey}
          onChange={(key) => handleTabChange(key)}
          tabBarExtraContent={!user.kyc_id && (
            <Button
              className='inline-flex items-center'
              icon={<CopyOutlined />}
              type='default'
              size='middle'
              onClick={() => {
                handleTabChange('7');
              }}
            >
              Save KYC Application
            </Button>
          )}
          size='middle'
        >
          <TabPane
            tab={(
              <span className='inline-flex items-center space-x-2'>
                <DashboardOutlined />
                <span>Dashboard</span>
              </span>
            )}
            key='1'
          >
            <MyDashboard />
          </TabPane>

          <TabPane
            tab={(
              <span className='inline-flex items-center space-x-2'>
                <UnorderedListOutlined />
                <span>ICO List</span>
              </span>
            )}
            key='2'
          >
            <IcoList />
          </TabPane>

          <TabPane
            tab={(
              <span className='inline-flex items-center space-x-2'>
                <TransactionOutlined />
                <span>Transactions</span>
              </span>
            )}
            key='3'
          >
            <Transactions />
          </TabPane>

          <TabPane
            tab={(
              <span className='inline-flex items-center space-x-2'>
                <UserOutlined />
                <span>My Profile</span>
              </span>
            )}
            key='4'
          >
            <MyProfile />
          </TabPane>

          <TabPane
            tab={(
              <span className='inline-flex items-center space-x-2'>
                <AuditOutlined />
                <span>My Token</span>
              </span>
            )}
            key='5'
          >
            <MyToken />
          </TabPane>

          <TabPane
            tab={(
              <span className='inline-flex items-center space-x-2'>
                <ReconciliationOutlined />
                <span>Support Ticket</span>
              </span>
            )}
            key='6'
          >
            <SupportTicket />
          </TabPane>

          <TabPane key='7'>
            <KycApplication />
          </TabPane>
        </Tabs>
      </Content>

      {/* MY-APP FOOTER */}
      <Footers />
    </Layout>
  );
}

export default MyUser;
