import {
  AppstoreOutlined, ControlOutlined, DashboardOutlined, LogoutOutlined, UserOutlined
} from '@ant-design/icons';
import {
  Avatar, Menu, Popover, Typography
} from 'antd';
import React from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  deleteWalletAddress, deleteWalletBalance, deleteWalletChainId, deleteWalletNetwork
} from '../../redux/actions/walletAction';
import { getSessionUser, removeSessionAndLogoutUser } from '../../utils/helperAuthentication';

const { Title, Paragraph } = Typography;

function UserPopover() {
  const user = getSessionUser();
  const userName = `${user.first_name} ${user.last_name}`;
  const dispatch = useDispatch();

  const menu = (
    <Menu
      items={[
        {
          key: '1',
          label: (
            <Link to='/app/home'>
              <Paragraph className='inline-flex items-center'>
                <AppstoreOutlined className='mr-3' />
                Home
              </Paragraph>
            </Link>
          )
        },
        user.user_type === 'host' && {
          key: '2',
          label: (
            <Link to='/admin/dashboard'>
              <Paragraph className='inline-flex items-center'>
                <ControlOutlined className='mr-3' />
                Admin Dashboard
              </Paragraph>
            </Link>
          )
        },
        user.user_type === 'host' && {
          key: '3',
          label: (
            <Link to='/admin/my-profile'>
              <Paragraph className='inline-flex items-center'>
                <UserOutlined className='mr-3' />
                My Profile
              </Paragraph>
            </Link>
          )
        },
        user.user_type === 'participant' && {
          key: '4',
          label: (
            <Link to='/user/dashboard'>
              <Paragraph className='inline-flex items-center'>
                <DashboardOutlined className='mr-3' />
                User Dashboard
              </Paragraph>
            </Link>
          )
        },
        user.user_type === 'participant' && {
          key: '5',
          label: (
            <Link to='/user/my-profile'>
              <Paragraph className='inline-flex items-center'>
                <UserOutlined className='mr-3' />
                My Profile
              </Paragraph>
            </Link>
          )
        },
        user.user_type === 'sa' && {
          key: '6',
          label: (
            <Link to='/super/dashboard'>
              <Paragraph className='inline-flex items-center'>
                <DashboardOutlined className='mr-3' />
                Admin Dashboard
              </Paragraph>
            </Link>
          )
        },
        user.user_type === 'sa' && {
          key: '7',
          label: (
            <Link to='/super/my-profile'>
              <Paragraph className='inline-flex items-center'>
                <UserOutlined className='mr-3' />
                My Profile
              </Paragraph>
            </Link>
          )
        },
        {
          key: '8',
          label: (
            <Paragraph className='inline-flex items-center'>
              <LogoutOutlined className='mr-3' />
              Logout
            </Paragraph>
          ),
          onClick: () => {
            removeSessionAndLogoutUser(); // logout user
            // removed metamask wallet data redux store
            dispatch(deleteWalletNetwork());
            dispatch(deleteWalletAddress());
            dispatch(deleteWalletBalance());
            dispatch(deleteWalletChainId());
          }
        }
      ]}
    />
  );

  return (
    <Popover
      placement='bottomRight'
      title={(
        <span>
          <Title level={4} className='!text-textColorWhite min-w-[260px]'>{userName}</Title>
          <Paragraph className='!text-textColorWhite text-md'>{user.email}</Paragraph>
        </span>
      )}
      trigger='click'
      content={menu}
    >
      {user && user.profile_image ? (
        <Avatar
          style={{
            cursor: 'pointer',
            backgroundColor: '#87d068',
            border: '2.5px solid #ffffff',
            marginBottom: '1px'
          }}
          src={user.profile_image.includes('googleusercontent') ? user.profile_image : `${process.env.REACT_APP_API_BASE_URL}/temp/${user.profile_image}`}
        />
      ) : (
        <Avatar
          style={{
            cursor: 'pointer',
            backgroundColor: '#87d068'
          }}
          icon={<UserOutlined />}
        />
      )}
    </Popover>
  );
}

export default UserPopover;
