import {
  Button, Card, Tag, Typography
} from 'antd';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import ga4EventAction from '../../../utils/helperGAEventAction';

const { Title, Text } = Typography;

function IcoCard({ appliedIco }) {
  const navigate = useNavigate();

  return (
    <Card className='dashboard-card cursor-default h-60 px-4' hoverable>
      <Title className='inline-flex items-center mt-20' level={4}>
        My Participation
      </Title>

      <Text className='block text-lg'>
        Applied ICO =
        <Tag className='ml-2 text-lg' color='cyan'>{appliedIco?.applied}</Tag>
      </Text>

      <Button
        className='mt-5 mb-24 rounded-sm'
        type='primary'
        size='large'
        onClick={() => {
          // google analytics event handler
          ga4EventAction('link', 'Token List', 'User Purchase Token List');
          navigate('/user/my-token'); // navigate to my token list tab
        }}
      >
        Token List
      </Button>
    </Card>
  );
}

export default IcoCard;
