import { Result, Skeleton } from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';
import useFetchData from '../../../hooks/useFetchData';
import AclRoute from '../../routes/AclRoute';
import IcoCard from '../card/IcoCard';
import InvestorCard from '../card/InvestorCard';
import KycCard from '../card/KycCard';
import PaymentCard from '../card/PaymentCard';

function AdminDashboard() {
  window.document.title = 'ERC-20 Token • Admin Dashboard';

  // fetching admin dashboard API data
  const [loading, error, response] = useFetchData(`${process.env.REACT_APP_API_BASE_URL}/api/v1/ico-dashboard`);

  return (
    <AclRoute hasOwnPermission='Ico.Dashboard.View'>
      <Skeleton loading={loading} active paragraph={{ rows: 12 }}>
        {error ? (
          <Result
            status='error'
            title='Fetching Error'
            subTitle={error}
          />
        ) : (
          <div className='min-h-[68vh] mt-4 rounded-sm'>
            <div className='flex flex-wrap -mx-2 mb-8'>
              <Link
                className='w-full sm:w-1/2 px-2 mb-4'
                to='/admin/declared-ico'
              >
                {response && <IcoCard ico={response.result[0].ico} />}
              </Link>

              <Link
                className='w-full sm:w-1/2 px-2 mb-4'
                to='/admin/kyc-list'
              >
                {response && <KycCard kyc={response.result[1].kyc} />}
              </Link>

              <Link
                className='w-full sm:w-1/2 px-2 mb-4'
                to='/admin/participant'
              >
                {response && <InvestorCard investor={response.result[2].investor} />}
              </Link>

              <div className='w-full sm:w-1/2 px-2 mb-4'>
                {response && (<PaymentCard payment={response.result[3].payment} />)}
              </div>
            </div>
          </div>
        )}
      </Skeleton>
    </AclRoute>
  );
}

export default AdminDashboard;
