import { useEffect } from 'react';
import ReactGA4 from 'react-ga4';
import { useDispatch } from 'react-redux';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import AdminRoute from './components/routes/AdminRoute';
import PrivateRoute from './components/routes/PrivateRoute';
import PublicRoute from './components/routes/PublicRoute';
import SuperAdminRoute from './components/routes/SuperAdminRoute';
import UserRoute from './components/routes/UserRoute';
import AirDrop from './pages/admin/AirDrop';
import MyAdmin from './pages/admin/MyAdmin';
import MyApp from './pages/app/MyApp';
import Preview from './pages/app/Preview';
import Success from './pages/app/Success';
import ForgotPassword from './pages/auth/ForgotPassword';
import Login from './pages/auth/Login';
import Registration from './pages/auth/Registration';
import ResetPassword from './pages/auth/ResetPassword';
import VerifyEmail from './pages/auth/VerifyEmail';
import HomePage from './pages/Home';
import NotFound from './pages/NotFound';
import MySuperAdmin from './pages/super/MySuperAdmin';
import KycMessage from './pages/user/KycMessage';
import MyUser from './pages/user/MyUser';
import TokenPurchase from './pages/user/TokenPurchase';
import { getChainList } from './redux/actions/chainAction';

function App() {
  // GOOGLE ANALYTICS v4
  ReactGA4.initialize(process.env.REACT_APP_GA_MEASUREMENT_ID);
  ReactGA4.send({ hitType: 'pageview', page: window.location.pathname });

  // dispatch chain list api data
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getChainList);
  }, []);

  return (
    <BrowserRouter>
      <Routes>
        {/* HOME ROUTE */}
        <Route path='/' element={<HomePage />} />

        {/* APP ROUTE'S */}
        <Route path='/app/:tab' element={<MyApp />} />
        <Route path='/app/token-preview' element={<Preview />} />
        <Route path='/app/token-success' element={<Success />} />

        {/* USER ROUTE'S */}
        <Route
          path='/user/:tab'
          element={<UserRoute><MyUser /></UserRoute>}
        />
        <Route
          path='/user/token-purchase'
          element={<UserRoute><TokenPurchase /></UserRoute>}
        />
        <Route
          path='/user/kyc-message'
          element={<UserRoute><KycMessage /></UserRoute>}
        />

        {/* ADMIN ROUTE'S */}
        <Route
          path='/admin/:tab'
          element={<AdminRoute><MyAdmin /></AdminRoute>}
        />
        <Route
          path='/admin/air-drop'
          element={<AdminRoute><AirDrop /></AdminRoute>}
        />

        {/* SUPER ADMIN ROUTE'S */}
        <Route
          path='/super/:tab'
          element={<SuperAdminRoute><MySuperAdmin /></SuperAdminRoute>}
        />

        {/* PUBLIC ROUTE'S */}
        <Route
          path='/auth/registration'
          element={<PublicRoute><Registration /></PublicRoute>}
        />
        <Route
          path='/auth/login'
          element={<PublicRoute><Login /></PublicRoute>}
        />
        <Route
          path='/auth/forgot-password'
          element={<PublicRoute><ForgotPassword /></PublicRoute>}
        />
        <Route
          path='/auth/reset-password/:token'
          element={<PublicRoute><ResetPassword /></PublicRoute>}
        />

        {/* PRIVATE ROUTE'S */}
        <Route
          path='/auth/email-verification/:token'
          element={<PrivateRoute><VerifyEmail /></PrivateRoute>}
        />

        {/* 404 (NOT FOUND) ROUTE */}
        <Route path='*' element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
