import {
  DELETE_WALLET_ADDRESS, DELETE_WALLET_BALANCE, DELETE_WALLET_CHAIN_ID, DELETE_WALLET_NETWORK, SET_WALLET_ADDRESS, SET_WALLET_BALANCE, SET_WALLET_CHAIN_ID, SET_WALLET_NETWORK
} from '../constants/walletConstant';

// make a action to set wallet network
export const setWalletNetwork = (network) => ({
  type: SET_WALLET_NETWORK,
  payload: network
});

// make a action to delete wallet network
export const deleteWalletNetwork = () => ({
  type: DELETE_WALLET_NETWORK,
  payload: null
});

// make a action to set wallet address
export const setWalletAddress = (address) => ({
  type: SET_WALLET_ADDRESS,
  payload: address
});

// make a action to delete wallet address
export const deleteWalletAddress = () => ({
  type: DELETE_WALLET_ADDRESS,
  payload: null
});

// make a action to set wallet balance
export const setWalletBalance = (balance) => ({
  type: SET_WALLET_BALANCE,
  payload: balance
});

// make a action to delete wallet balance
export const deleteWalletBalance = () => ({
  type: DELETE_WALLET_BALANCE,
  payload: null
});

// make a action to set wallet chain id
export const setWalletChainId = (id) => ({
  type: SET_WALLET_CHAIN_ID,
  payload: id
});

// make a action to delete wallet chain id
export const deleteWalletChainId = () => ({
  type: DELETE_WALLET_CHAIN_ID,
  payload: null
});
