import {
  Button, Descriptions, Empty, Image, Result, Skeleton, Tag, Tooltip
} from 'antd';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import useFetchData from '../../../hooks/useFetchData';
import { responseAsColor } from '../../../utils/helperShared';

function MyKycApplication() {
  const [visible, setVisible] = useState(false);
  const [imagePath, setImagePath] = useState('');
  const [loading, error, response] = useFetchData(`${process.env.REACT_APP_API_BASE_URL}/api/v1/get-my-kyc-detail`);

  // function to handle preview image
  const handlePreviewImage = (imageUrl) => {
    setVisible(true);
    setImagePath(process.env.REACT_APP_API_BASE_URL + imageUrl);
  };

  return (
    <Skeleton loading={loading} active avatar paragraph={{ rows: 6 }}>
      {error ? (
        <Result
          status='error'
          title='Fetching Error'
          subTitle={error}
        />
      ) : (
        response && response?.result === null ? (
          <Empty
            className='mt-10'
            description={(
              <span>Sorry! Your KYC application data was not found !!!</span>
            )}
          >
            <Link to='/user/kyc-application'>
              <Button type='primary'>Save KYC Application</Button>
            </Link>
          </Empty>
        ) : (
          <>
            <Descriptions
              title='My KYC Application Details'
              labelStyle={{ fontWeight: 'bold' }}
              bordered
              extra={(
                <Tag
                  className='px-2 py-1 text-sm capitalize'
                  color={response?.result?.status ? responseAsColor(response?.result?.status) : 'purple'}
                >
                  {response?.result?.status ? response?.result?.status : 'KYC SAVED'}
                </Tag>
              )}
            >
              {/* PARTICIPANT IMAGE */}
              <Descriptions.Item label='Participant Image' span={3}>
                <Image
                  className='w-[200px] shadow-sm rounded-sm'
                  src={`${process.env.REACT_APP_API_BASE_URL}${response?.result?.participant_image}`}
                  alt='participant-image'
                />
              </Descriptions.Item>

              <Descriptions.Item label='Full Name'>
                {`${response?.result?.first_name} ${response?.result?.last_name}`}
              </Descriptions.Item>
              <Descriptions.Item label='Data Of Birth' span={2}>
                {response?.result?.date_of_birth}
              </Descriptions.Item>

              <Descriptions.Item label='Phone'>
                {response?.result?.phone}
              </Descriptions.Item>
              <Descriptions.Item label='Nationality' span={2}>
                {response?.result?.nationality}
              </Descriptions.Item>

              <Descriptions.Item label='Address'>
                {response?.result?.address}
              </Descriptions.Item>
              <Descriptions.Item label='City' span={2}>
                {response?.result?.city}
              </Descriptions.Item>

              <Descriptions.Item label='Postal Code'>
                {response?.result?.postal_code}
              </Descriptions.Item>
              <Descriptions.Item label='Country' span={2}>
                {response?.result?.country}
              </Descriptions.Item>

              <Descriptions.Item label='Document Name'>
                {response?.result?.document_name}
              </Descriptions.Item>

              <Descriptions.Item label={`${response?.result?.document_name} Number and Image`} span={2}>
                <Tooltip title='Click here to view document image !!!'>
                  <Button
                    type='link'
                    onClick={() => handlePreviewImage(response?.result?.document_image)}
                  >
                    {response?.result?.document_no}
                  </Button>
                </Tooltip>
              </Descriptions.Item>
            </Descriptions>

            {/* PREVIEW DOCUMENT IMAGE */}
            <Image
              width={200}
              style={{ display: 'none' }}
              src={imagePath}
              alt='document-image'
              preview={{
                visible,
                src: imagePath,
                onVisibleChange: (value) => {
                  setVisible(value);
                }
              }}
            />
          </>
        )
      )}
    </Skeleton>
  );
}

export default MyKycApplication;
