import { WalletOutlined } from '@ant-design/icons';
import { Avatar, Typography } from 'antd';
import React from 'react';

const { Title, Text } = Typography;

function CurrencySegmentedLabel({ name, price, currencyRate }) {
  return (
    <div className='flex flex-row items-center justify-between py-4'>
      <div className='flex flex-row items-center justify-start space-x-2'>
        <Avatar
          className='bg-primaryColor inline-flex items-center pl-2'
          icon={<WalletOutlined />}
        />
        <Title className='uppercase pt-2' level={5}>{name}</Title>
      </div>

      <div className='flex flex-col items-end justify-center'>
        <Text className='uppercase'>{`${price || 0} ${name}`}</Text>
        <Text className='p-0 text-gray-400 text-xs uppercase'>
          {`1 ${name} = $ ${currencyRate}`}
        </Text>
      </div>
    </div>
  );
}

export default CurrencySegmentedLabel;
