import {
  Button, Result, Space, Spin
} from 'antd';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import eventMsg from '../../data/event-message.json';
import { getSessionToken, setSessionUserKeyAgainstValue } from '../../utils/helperAuthentication';

function VerifyEmail() {
  window.document.title = 'ERC-20 Token • Email Verify';
  const [emailVerified, setEmailVerified] = useState(false);
  const [loading, setLoading] = useState(true);
  const accessToken = getSessionToken();
  const navigation = useNavigate();
  const { token } = useParams();

  useEffect(() => {
    const myHeaders = new Headers();
    myHeaders.append('Authorization', `Bearer ${accessToken}`);

    const requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };

    fetch(`${process.env.REACT_APP_API_BASE_URL}/api/v1/auth/verify-email/${token}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setLoading(false);

        if (result.result_code === 0) {
          setEmailVerified(true);
          setSessionUserKeyAgainstValue('is_verified', 1);
        }
      })
      .catch(() => {
        setLoading(false);
      });
  }, []);

  return (
    <div className='flex flex-row items-center justify-center pt-[10%]'>
      {loading ? (
        <Space size='large'>
          <Spin
            size='large'
            tip='Verifying...!'
          />
        </Space>
      ) : (
        <Result
          status={emailVerified ? 'success' : 'error'}
          title={emailVerified ? eventMsg.auth.verifyEmail.success : eventMsg.auth.verifyEmail.failed}
          extra={(
            <Button
              key='dashboard'
              type='primary'
              size='large'
              onClick={() => navigation('/user/dashboard')}
            >
              {emailVerified ? 'Go To Dashboard' : 'Try Again To Verify Email'}
            </Button>
          )}
        />
      )}
    </div>
  );
}

export default VerifyEmail;
