import { Tabs, Typography } from 'antd';
import React from 'react';
import ChangePassword from '../../account/ChangePassword';
import PersonalData from '../../account/PersonalData';

const { Title } = Typography;
const { TabPane } = Tabs;

function MyProfile() {
  window.document.title = 'ERC-20 Token • My Profile';

  return (
    <div className='bg-backgroundColorWhite min-h-[68vh] shadow-md rounded-sm p-2 md:p-4'>
      <Title className='text-textColorBlack' level={4}>Profile Details</Title>

      <Tabs type='card' size='middle'>
        <TabPane tab='Personal Data' key='1'>
          <PersonalData />
        </TabPane>

        <TabPane tab='Change Password' key='2'>
          <ChangePassword />
        </TabPane>
      </Tabs>
    </div>
  );
}

export default MyProfile;
