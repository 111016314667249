import { ArrowLeftOutlined, LoadingOutlined, MailOutlined } from '@ant-design/icons';
import {
  Alert, Button, Divider, Form, Input, Layout
} from 'antd';
import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { load } from 'recaptcha-v3';
import Background from '../../assets/images/background.jpg';
import Logo from '../../assets/images/logo.svg';
import CaptchaFooter from '../../components/common/CaptchaFooter';
import eventMsg from '../../data/event-message.json';
import notificationWithIcon from '../../utils/popupNotification';

const { Sider, Content } = Layout;

function ForgotPassword() {
  window.document.title = 'ERC-20 Token • Forgot Password';
  const [captchaToken, setCaptchaToken] = useState('');
  const [refreshToken, setRefreshToken] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errMsg, setErrMsg] = useState('');
  const navigation = useNavigate();

  // generate google re-captcha-v3 token
  useEffect(() => {
    load(process.env.REACT_APP_CAPTCHA_SITE_KEY, {
      autoHideBadge: true
    }).then((recaptcha) => {
      recaptcha.execute().then((token) => {
        setCaptchaToken(token); // Will print the token
      });
    });
  }, [refreshToken]);

  // make function to recover password
  const recoveryPassword = async (values) => {
    if (captchaToken) {
      setLoading(true);
      const myHeaders = new Headers();
      myHeaders.append('Content-Type', 'application/json');

      const raw = JSON.stringify({
        email: values.email
      });

      const requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
      };

      fetch(`${process.env.REACT_APP_API_BASE_URL}/api/v1/auth/send-verification-code`, requestOptions)
        .then((response) => response.json())
        .then((result) => {
          if (result.result_code === 0) {
            setLoading(false);
            notificationWithIcon('success', eventMsg.auth.forgotPassword.success.title, eventMsg.auth.forgotPassword.success.message);
            navigation('/auth/login');
          } else {
            setRefreshToken(!refreshToken);
            setErrMsg(result.error.message);
            setLoading(false);
          }
        })
        .catch((error) => {
          setRefreshToken(!refreshToken);
          setErrMsg(error.message);
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    setTimeout(() => {
      setErrMsg('');
    }, 6000);
  }, [errMsg]);

  return (
    <Layout
      className='min-h-screen bg-no-repeat bg-cover bg-left bg-fixed'
      style={{ backgroundImage: `url(${Background})` }}
    >
      <Content />

      <Sider className='!bg-backgroundColorWhite !min-w-[500px] px-10 pt-[15vh]'>
        {/* APP LOGO & GO TO DASHBOARD */}
        <Link to='/app/home'>
          <img src={Logo} className='w-32 mx-auto my-5' alt='erc-20-token' />
        </Link>

        {/* DIVIDER */}
        <Divider className='!text-primaryColor pb-5'>RECOVERY YOUR PASSWORD HERE</Divider>

        {/* ERROR ALERT MESSAGE */}
        {errMsg && <Alert message={errMsg} type='error' className='!text-center' />}

        {/* PASSWORD RECOVERY FROM */}
        <Form
          name='password-recovery-from'
          className='login-form mt-2'
          initialValues={{ remember: true }}
          onFinish={recoveryPassword}
          size='large'
        >
          {/* INPUT FIELD EMAIL */}
          <Form.Item
            name='email'
            rules={[{
              type: 'email',
              required: true,
              message: 'Please input your Email!'
            }]}
          >
            <Input
              prefix={<MailOutlined className='site-form-item-icon mr-2' />}
              placeholder='Type here your email'
            />
          </Form.Item>

          {/* SUBMIT BUTTON */}
          <Form.Item>
            <Button
              className='login-form-button mt-5'
              htmlType='submit'
              type='primary'
              block
              disabled={loading}
            >
              {loading ? <LoadingOutlined /> : 'Send Password Reset Link Your Email'}
            </Button>
          </Form.Item>
        </Form>

        {/* GO TO REGISTRATION PAGE BUTTON */}
        <Link to='/auth/login'>
          <Button type='link' size='middle' className='login-form-button' block>
            Remember your password? Login instead
          </Button>
        </Link>

        {/* BACK TO HOME BUTTON */}
        <Link to='/app/home'>
          <Button
            className='!w-[250px] mt-4 mb-4 ml-[20%] inline-flex items-center justify-center'
            icon={<ArrowLeftOutlined />}
            type='primary'
            size='middle'
          >
            Back to Home
          </Button>
        </Link>

        {/* GOOGLE RE-CAPTCHA BADGE HIDE FOOTER */}
        <CaptchaFooter mt='2' />
      </Sider>
    </Layout>
  );
}

export default ForgotPassword;
