import { LoadingOutlined } from '@ant-design/icons';
import {
  Button, Card, message, Tag, Typography
} from 'antd';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import eventMsg from '../../../data/event-message.json';
import { getSessionToken, getSessionUser } from '../../../utils/helperAuthentication';
import ga4EventAction from '../../../utils/helperGAEventAction';

const { Title } = Typography;

function AccountStatusCard() {
  const [loading, setLoading] = useState(false);
  const token = getSessionToken();
  const navigate = useNavigate();
  const user = getSessionUser();

  // function to email verification code send user email'
  const handleVerifyEmail = () => {
    // google analytics event handler
    ga4EventAction('link', 'Verify Your Email', 'User Email Verification');

    setLoading(true);
    const myHeaders = new Headers();
    myHeaders.append('Authorization', `Bearer ${token}`);
    myHeaders.append('Content-Type', 'application/json');

    const raw = JSON.stringify({
      email: user.email
    });

    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    fetch(`${process.env.REACT_APP_API_BASE_URL}/api/v1/auth/send-email-verification-code`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.result_code === 0) {
          message.success(eventMsg.user.emailVerificationMailSuccessMessage);
          setLoading(false);
        } else {
          message.error(result.error.message);
          setLoading(false);
        }
      })
      .catch((error) => {
        message.error(error.message);
      });
  };

  return (
    <Card className='dashboard-card cursor-default h-60 px-2 sm:px-4' hoverable>
      <Title className='inline-flex items-center' level={4}>
        My Account Status
      </Title>

      <div className='space-x-4 mb-20'>
        {user.is_verified === 1 ? (
          <Tag
            className='px-2 py-2.5 text-sm uppercase'
            color='success'
          >
            Email Verified
          </Tag>
        ) : (
          <Button
            className='min-w-[180px]'
            onClick={handleVerifyEmail}
            disabled={loading}
            type='primary'
            size='large'
          >
            {loading ? <LoadingOutlined /> : 'Verify Your Email'}
          </Button>
        )}

        {user.kyc_id ? (
          <Tag
            className='px-2 py-2.5 text-sm uppercase cursor-pointer'
            onClick={() => navigate('/user/my-profile?tab=my-kyc-application')}
            color='success'
          >
            View KYC
          </Tag>
        ) : (
          <Button
            onClick={() => navigate('/user/kyc-application')}
            type='primary'
            size='large'
          >
            Save KYC
          </Button>
        )}
      </div>
    </Card>
  );
}

export default AccountStatusCard;
