import { Spin } from 'antd';
import React from 'react';

function LoadingSpinner() {
  return (
    <div className='flex items-center justify-center my-20'>
      <Spin tip='Loading...!!!' />
    </div>
  );
}

export default LoadingSpinner;
