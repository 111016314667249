import {
  DELETE_WALLET_ADDRESS, DELETE_WALLET_BALANCE, DELETE_WALLET_CHAIN_ID, DELETE_WALLET_NETWORK, SET_WALLET_ADDRESS, SET_WALLET_BALANCE, SET_WALLET_CHAIN_ID, SET_WALLET_NETWORK
} from '../constants/walletConstant';

const initialState = {
  walletNetwork: null,
  walletAddress: null,
  walletBalance: null,
  walletChainId: null
};

// eslint-disable-next-line default-param-last
const walletReducer = (state = initialState, action) => {
  switch (action.type) {
    // token create network
    case SET_WALLET_NETWORK:
      return {
        ...state,
        walletNetwork: action.payload
      };
    case DELETE_WALLET_NETWORK:
      return {
        ...state,
        walletNetwork: null
      };

    // wallet address
    case SET_WALLET_ADDRESS:
      return {
        ...state,
        walletAddress: action.payload
      };
    case DELETE_WALLET_ADDRESS:
      return {
        ...state,
        walletAddress: null
      };

    // wallet balance
    case SET_WALLET_BALANCE:
      return {
        ...state,
        walletBalance: action.payload
      };
    case DELETE_WALLET_BALANCE:
      return {
        ...state,
        walletBalance: null
      };

    // wallet chain id
    case SET_WALLET_CHAIN_ID:
      return {
        ...state,
        walletChainId: action.payload
      };
    case DELETE_WALLET_CHAIN_ID:
      return {
        ...state,
        walletChainId: null
      };

    default:
      return state;
  }
};

export default walletReducer;
