import { SearchOutlined } from '@ant-design/icons';
import {
  Button, Empty, Input, Pagination, Result, Select, Skeleton, Tag, Tooltip
} from 'antd';
import React, { useEffect, useState } from 'react';
import useFetchData from '../../../hooks/useFetchData';
import truncateString from '../../../lib/truncateString';
import { supportTicketResponseAsStatus } from '../../../utils/helperShared';

const { Option } = Select;

function MySupportTicketList({ add }) {
  const [searchKeyword, setSearchKeyword] = useState('');
  const [sortType, setSortType] = useState('desc');
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);

  const [loading, error, response] = useFetchData(`${process.env.REACT_APP_API_BASE_URL}/api/v1/get-participant-support-ticket-list?page=${page}&search=${searchKeyword}&limit=${limit}&sort=${sortType}`);

  // reset paginate page number
  useEffect(() => {
    setPage(1);
  }, [searchKeyword, limit]);

  return (
    <Skeleton loading={loading} active paragraph={{ rows: 10 }}>
      {error ? (
        <Result
          status='error'
          title='Fetching Error'
          subTitle={error}
        />
      ) : (
        <div>
          {/* SEARCH BOX & FILTERS */}
          <div className='search-and-filter-box'>
            <Input
              className='space-x-4'
              placeholder='Type in to Search support tickets'
              onChange={(e) => setSearchKeyword(e.target.value)}
              prefix={<SearchOutlined />}
              size='large'
            />

            <Select
              className='w-full sm:w-[240px]'
              placeholder='-- show rows --'
              onChange={(value) => setLimit(value)}
              defaultValue='10'
              size='large'
            >
              <Option value='10'>10 Rows</Option>
              <Option value='20'>20 Rows</Option>
              <Option value='30'>30 Rows</Option>
              <Option value='40'>40 Rows</Option>
              <Option value='50'>50 Rows</Option>
            </Select>

            <Select
              className='w-full sm:w-[240px]'
              placeholder='-- select type to sort --'
              onChange={(value) => setSortType(value)}
              defaultValue='desc'
              size='large'
            >
              <Option value='aesc'>Sort By Ascending</Option>
              <Option value='desc'>Sort By Descending</Option>
            </Select>
          </div>

          {/* SUPPORT TICKET LIST */}
          {response && response.result.rows.length === 0 ? (
            <Empty
              className='mt-10'
              description={(
                <span>Sorry! Your support ticket data was not found.</span>
              )}
            />
          ) : (
            <div className='w-full shadow bg-white rounded my-3'>
              <div className='border-gray-200 w-full rounded bg-white overflow-x-auto'>
                <table className='w-full leading-normal'>
                  {/* TABLE HEADER */}
                  <thead className='table-thead'>
                    <tr className='border-b border-gray-200'>
                      <th scope='col' className='table-thead-th'>
                        SL
                      </th>
                      <th scope='col' className='table-thead-th'>
                        NAME
                      </th>
                      <th scope='col' className='table-thead-th'>
                        RAISED DATE
                      </th>
                      <th scope='col' className='table-thead-th'>
                        STATUS
                      </th>
                      <th scope='col' className='table-thead-th'>
                        SUBJECT
                      </th>
                      <th scope='col' className='table-thead-th'>
                        DESCRIPTIONS
                      </th>
                    </tr>
                  </thead>

                  {/* DATA MAPPING ON TABLE BODY */}
                  {response && response.result.rows.map((data) => (
                    <tbody key={data.id}>
                      <tr className='table-body-tr'>
                        <td className='table-body-td'>
                          {data.id}
                        </td>
                        <td className='table-body-td'>
                          {`${data.first_name} ${data.last_name}`}
                        </td>
                        <td className='table-body-td'>
                          {data.created_at.split(' ')[0]}
                        </td>
                        <td className='table-body-td'>
                          <Tag
                            className='px-2 py-1 text-sm capitalize'
                            color={supportTicketResponseAsStatus(data.status).color}
                          >
                            {supportTicketResponseAsStatus(data.status).status}
                          </Tag>
                        </td>
                        <td className='table-body-td'>
                          {truncateString(data.subject, 20, '...')}
                        </td>
                        <td className='table-body-td'>
                          <Tooltip title='Click here to view details or Add a comment'>
                            <Button
                              className='capitalize font-bold'
                              type='link'
                              onClick={() => add(data.id)}
                            >
                              {truncateString(data.description, 20, '...')}
                            </Button>
                          </Tooltip>
                        </td>
                      </tr>
                    </tbody>
                  ))}
                </table>
              </div>
            </div>
          )}

          {/* SUPPORT TICKET LIST PAGINATION */}
          {response && response.result.total_page > 1 && (
            <Pagination
              className='flex items-center justify-center m-4'
              defaultCurrent={1}
              total={response.result.total_page * 10}
              onChange={(e) => setPage(e)}
            />
          )}
        </div>
      )}
    </Skeleton>
  );
}

export default MySupportTicketList;
