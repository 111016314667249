import React from 'react';
import { Navigate } from 'react-router-dom';
import { getSessionToken, getSessionUser } from '../../utils/helperAuthentication';

function SuperAdminRoute({ children }) {
  const user = getSessionUser();
  const token = getSessionToken();

  if (!user && !token) {
    return <Navigate to='/auth/login' replace />;
  }

  if (user.user_type !== 'sa') {
    return <Navigate to='/super/dashboard' replace />;
  }

  return children;
}

export default SuperAdminRoute;
