import {
  ArrowLeftOutlined, FormOutlined, LoadingOutlined, LockOutlined, MailOutlined, PhoneOutlined, UserOutlined
} from '@ant-design/icons';
import {
  Alert, Button, Divider, Form, Input, Layout, Select
} from 'antd';
import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { load } from 'recaptcha-v3';
import Background from '../../assets/images/background.jpg';
import Logo from '../../assets/images/logo.svg';
import CaptchaFooter from '../../components/common/CaptchaFooter';
import eventMsg from '../../data/event-message.json';
import ga4EventAction from '../../utils/helperGAEventAction';
import notificationWithIcon from '../../utils/popupNotification';

const { Sider, Content } = Layout;

function Registration() {
  window.document.title = 'ERC-20 Token • Registration';
  const [userType, setUserType] = useState('participant');
  const [captchaToken, setCaptchaToken] = useState();
  const [refreshToken, setRefreshToken] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errMsg, setErrMsg] = useState('');
  const navigate = useNavigate();

  // generate google re-captcha-v3 token
  useEffect(() => {
    load(process.env.REACT_APP_CAPTCHA_SITE_KEY, {
      autoHideBadge: true
    }).then((recaptcha) => {
      recaptcha.execute().then((token) => {
        setCaptchaToken(token); // Will print the token
      });
    });
  }, [refreshToken]);

  const onFinish = async (values) => {
    // google analytics event handler
    ga4EventAction('link', 'Registration', 'Admin/User Registration');

    if (values.password !== values.confirmPassword) {
      setErrMsg(eventMsg.auth.registration.passwordNotMatchError);
    } else {
      setLoading(true);
      const myHeaders = new Headers();
      myHeaders.append('Content-Type', 'application/json');

      const raw = JSON.stringify({
        first_name: values.firstName,
        last_name: values.lastName,
        email: values.email,
        password: values.password,
        user_type: values.userType,
        confirm_password: values.confirmPassword,
        company_name: userType === 'host' ? values.companyName : '',
        contact_no: userType === 'host' ? values.contactNumber : '',
        response_key: captchaToken
      });

      const requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
      };

      fetch(`${process.env.REACT_APP_API_BASE_URL}/api/v1/auth/sign-up`, requestOptions)
        .then((response) => response.json())
        .then((result) => {
          if (result.result_code === 0) {
            setLoading(false);
            navigate('/auth/login');
            notificationWithIcon('success', 'User Registration', eventMsg.auth.registration.success);
          } else {
            setRefreshToken(!refreshToken);
            setErrMsg(result.error.message);
            setLoading(false);
          }
        })
        .catch((error) => {
          setRefreshToken(!refreshToken);
          setErrMsg(error.message);
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    setTimeout(() => {
      setErrMsg('');
    }, 6000);
  }, [errMsg]);

  return (
    <Layout
      className='min-h-screen bg-no-repeat bg-cover bg-left bg-fixed'
      style={{ backgroundImage: `url(${Background})` }}
    >
      <Content />

      <Sider className='!bg-backgroundColorWhite !min-w-[483px] px-10'>
        {/* APP LOGO & GO TO DASHBOARD */}
        <Link to='/app/home'>
          <img src={Logo} className='w-32 mx-auto my-4' alt='erc-20-token' />
        </Link>

        {/* DIVIDER */}
        <Divider className='!text-primaryColor px-1'>REGISTRATION HERE</Divider>

        {/* ERROR ALERT */}
        {errMsg && <Alert message={errMsg} type='error' className='!text-center' />}

        {/* REGISTRATION FROM */}
        <Form
          name='registration-from'
          className='login-form mt-2'
          initialValues={{ remember: true }}
          onFinish={onFinish}
          size='middle'
        >
          {/* INPUT FIELD FIRST NAME */}
          <Form.Item
            name='firstName'
            rules={[{
              type: 'string',
              required: true,
              message: 'Please input your first name!'
            }]}
          >
            <Input
              prefix={<UserOutlined className='site-form-item-icon mr-2' />}
              placeholder='Type here your first name'
            />
          </Form.Item>

          {/* INPUT FIELD LAST NAME */}
          <Form.Item
            name='lastName'
            rules={[{
              type: 'string',
              required: true,
              message: 'Please input your last name!'
            }]}
          >
            <Input
              prefix={<UserOutlined className='site-form-item-icon mr-2' />}
              placeholder='Type here your last name'
            />
          </Form.Item>

          {/* INPUT FIELD EMAIL */}
          <Form.Item
            name='email'
            rules={[{
              type: 'email',
              required: true,
              message: 'Please input your email!'
            }]}
          >
            <Input
              prefix={<MailOutlined className='site-form-item-icon mr-2' />}
              placeholder='Type here your email'
            />
          </Form.Item>

          {/* INPUT FIELD SELECT USER TYPE */}
          <Form.Item
            name='userType'
            initialValue='participant'
            rules={[{
              type: 'string',
              required: true,
              message: 'Please select user type!'
            }]}
          >
            <Select
              onChange={(value) => {
                setUserType(value);
              }}
            >
              <Select.Option value='host'>Host</Select.Option>
              <Select.Option value='participant'>Participant</Select.Option>
            </Select>
          </Form.Item>

          {/* INPUT FIELD CONTACT NUMBER */}
          {userType === 'host' && (
          <Form.Item
            name='contactNumber'
            rules={[{
              type: 'string',
              required: true,
              message: 'Please input your contact number!'
            }]}
          >
            <Input
              prefix={<PhoneOutlined className='site-form-item-icon mr-2' />}
              placeholder='Type here your contact number'
            />
          </Form.Item>
          )}

          {/* INPUT FIELD COMPANY NAME */}
          {userType === 'host' && (
          <Form.Item
            name='companyName'
            rules={[{
              type: 'string',
              required: true,
              message: 'Please input your company name!'
            }]}
          >
            <Input
              prefix={<FormOutlined className='site-form-item-icon mr-2' />}
              placeholder='Type here your company name'
            />
          </Form.Item>
          )}

          {/* INPUT FIELD PASSWORD */}
          <Form.Item
            name='password'
            rules={[{
              type: 'string',
              required: true,
              message: 'Please input your Password!'
            }]}
          >
            <Input.Password
              prefix={<LockOutlined className='site-form-item-icon mr-2' />}
              placeholder='Type here your password'
              type='password'
            />
          </Form.Item>

          {/* INPUT FIELD CONFIRM PASSWORD */}
          <Form.Item
            name='confirmPassword'
            rules={[{
              type: 'string',
              required: true,
              message: 'Please input your Confirm Password!'
            }]}
          >
            <Input.Password
              prefix={<LockOutlined className='site-form-item-icon mr-2' />}
              placeholder='Type here your confirm password'
              type='password'
            />
          </Form.Item>

          {/* SUBMIT BUTTON */}
          <Form.Item>
            <Button
              className='login-form-button'
              htmlType='submit'
              type='primary'
              block
              disabled={loading}
            >
              {loading ? <LoadingOutlined /> : 'Register'}
            </Button>
          </Form.Item>
        </Form>

        {/* GO TO LOGIN PAGE */}
        <Link to='/auth/login'>
          <Button type='link' size='middle' className='login-form-button !pt-0' block>
            Already have an account? Login instead
          </Button>
        </Link>

        {/* BACK TO HOME BUTTON */}
        <Link to='/app/home'>
          <Button
            className='!w-[245px] mt-2 mb-2 ml-[19.5%] inline-flex items-center justify-center'
            icon={<ArrowLeftOutlined />}
            type='primary'
            size='middle'
          >
            Back to Home
          </Button>
        </Link>

        {/* GOOGLE RE-CAPTCHA BADGE HIDE FOOTER */}
        <CaptchaFooter mt='2' />
      </Sider>
    </Layout>
  );
}

export default Registration;
