import { GET_CHAIN_LIST_ERROR, GET_CHAIN_LIST_LOADING, GET_CHAIN_LIST_REQUEST } from '../constants/chainConstant';

// make a action to gets wallet chain list
export const getChainList = async (dispatch) => {
  dispatch({ type: GET_CHAIN_LIST_LOADING });

  try {
    const res = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/v1/get-chain-list`);
    const response = await res.json();

    dispatch({ type: GET_CHAIN_LIST_REQUEST, payload: response.result });
  } catch (error) {
    dispatch({ type: GET_CHAIN_LIST_ERROR, payload: error });
  }
};
