import {
  DashboardOutlined, UsergroupAddOutlined, UserOutlined
} from '@ant-design/icons';
import { Layout, Tabs } from 'antd';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Footers from '../../components/common/Footers';
import Headers from '../../components/common/Headers';
import MyProfile from '../../components/super/tabs/MyProfile';
import SuperAdminDashboard from '../../components/super/tabs/SuperAdminDashboard';
import SuperAdminUser from '../../components/super/tabs/SuperAdminUser';

const { Content } = Layout;
const { TabPane } = Tabs;

function MySuperAdmin() {
  window.document.title = 'ERC-20 Token • My Super Admin';
  const [activeTabKey, setActiveTabKey] = useState('1');
  const navigate = useNavigate();
  const { tab } = useParams();

  const handleTabChange = (key) => {
    switch (key) {
      case '1': {
        navigate('/super/dashboard');
        break;
      }
      case '2': {
        navigate('/super/users');
        break;
      }
      case '3': {
        navigate('/super/my-profile');
        break;
      }
      default: {
        navigate('/super/dashboard');
      }
    }
  };

  useEffect(() => {
    switch (activeTabKey) {
      case '1': {
        window.document.title = 'ERC-20 Token • Super Admin Dashboard';
        break;
      }
      case '2': {
        window.document.title = 'ERC-20 Token • Super User';
        break;
      }
      case '3': {
        window.document.title = 'ERC-20 Token • My Profile';
        break;
      }
      default: {
        window.document.title = 'ERC-20 Token • Super Admin Dashboard';
      }
    }
  }, [activeTabKey]);

  useEffect(() => {
    if (tab) {
      switch (tab) {
        case 'dashboard': {
          setActiveTabKey('1');
          break;
        }
        case 'users': {
          setActiveTabKey('2');
          break;
        }
        case 'my-profile': {
          setActiveTabKey('3');
          break;
        }
        default: {
          navigate('/not-found');
        }
      }
    }
  }, [tab, navigate]);

  return (
    <Layout>
      {/* MY-APP HEADER */}
      <Headers />

      {/* MY-APP CONTENT */}
      <Content
        className='relative overflow-y-auto'
        style={{ minHeight: 'calc(100vh - 170px)' }}
      >
        <Tabs
          className='px-2 md:px-12 lg:px-24'
          onChange={(key) => handleTabChange(key)}
          activeKey={activeTabKey}
          size='middle'
        >
          {/* TAB PANE FOR SUPER ADMIN DASHBOARD */}
          <TabPane
            tab={(
              <span className='inline-flex items-center space-x-2'>
                <DashboardOutlined />
                <span>Dashboard</span>
              </span>
            )}
            key='1'
          >
            <SuperAdminDashboard />
          </TabPane>

          {/* TAB PANE FOR SUPER ADMIN USER */}
          <TabPane
            tab={(
              <span className='inline-flex items-center space-x-2'>
                <UsergroupAddOutlined />
                <span>Users</span>
              </span>
            )}
            key='2'
          >
            <SuperAdminUser />
          </TabPane>

          {/* TAB PANE FOR SUPER ADMIN PROFILE */}
          <TabPane
            tab={(
              <span className='inline-flex items-center space-x-2'>
                <UserOutlined />
                <span>My Profile</span>
              </span>
            )}
            key='3'
          >
            <MyProfile />
          </TabPane>
        </Tabs>
      </Content>

      {/* MY-APP FOOTER */}
      <Footers />
    </Layout>
  );
}

export default MySuperAdmin;
