import { Tabs } from 'antd';
import React, { useRef, useState } from 'react';
import ga4EventAction from '../../../utils/helperGAEventAction';
import CreateSupportTicket from '../support-ticket/CreateSupportTicket';
import MySupportTicketDetails from '../support-ticket/MySupportTicketDetails';
import MySupportTicketList from '../support-ticket/MySupportTicketList';

const { TabPane } = Tabs;

function SupportTicket() {
  window.document.title = 'ERC-20 Token • Support Ticket';
  const newTabIndex = useRef(0);

  // tab click to change active key
  const onChange = (key) => {
    setActiveKey(key);
  };

  // function to add new tab
  const add = (id) => {
    // google analytics event handler
    ga4EventAction('link', 'Support Ticket Details', 'User Support Ticket View Details');

    const newActiveKey = `newTab${newTabIndex.current + 1}`;
    setPanes([
      ...panes, {
        title: 'Ticket Details',
        content: <MySupportTicketDetails id={id} />,
        key: newActiveKey
      }]);
    setActiveKey(newActiveKey);
  };

  // dynamic tab default pane
  const defaultPanes = Array.from({ length: 1 }).map((_, index) => {
    const x = String(index + 1);

    return {
      key: x,
      title: 'Support Ticket List',
      content: <MySupportTicketList add={add} />
    };
  });

  // tab initial states
  const [activeKey, setActiveKey] = useState(defaultPanes[0].key);
  const [panes, setPanes] = useState(defaultPanes);

  // function to removed tab
  const remove = (targetKey) => {
    const targetIndex = panes.findIndex((pane) => pane.key === targetKey);
    const newPanes = panes.filter((pane) => pane.key !== targetKey);

    if (newPanes.length && targetKey === activeKey) {
      const { key } = newPanes[targetIndex === newPanes.length ? targetIndex - 1 : targetIndex];
      setActiveKey(key);
    }

    setPanes(newPanes);
  };

  // function to tabs actions controller
  const onEdit = (targetKey, action) => {
    if (action === 'add') {
      add();
    } else {
      remove(targetKey);
    }
  };

  return (
    <div className='bg-backgroundColorWhite min-h-[68vh] shadow-md rounded-sm pt-0.5'>
      <Tabs
        className='m-2 md:m-4'
        type='editable-card'
        onEdit={onEdit}
        onChange={onChange}
        activeKey={activeKey}
        size='middle'
        hideAdd
      >
        {panes.map((pane) => (
          <TabPane key={pane.key} tab={pane.title} closable={pane.key !== '1'}>
            {pane.content}
          </TabPane>
        ))}

        <TabPane key='100' tab='⊞ Create Ticket' closable={false}>
          <CreateSupportTicket />
        </TabPane>
      </Tabs>
    </div>
  );
}

export default SupportTicket;
