import {
  Card, Statistic, Typography
} from 'antd';
import React from 'react';

const { Title } = Typography;

function KycCard({ kyc }) {
  return (
    <Card className='dashboard-card h-56 px-2 sm:px-4' hoverable>
      <Title level={4}>
        Participants KYC Application
      </Title>

      <div className='flex flex-row justify-start items-center space-x-8 mt-6'>
        <Statistic
          title='KYC Submitted'
          value={kyc.newKyc}
          valueStyle={{ color: '#eab308' }}
          prefix='⇲'
        />

        <Statistic
          title='KYC Approved'
          value={kyc.approvedKyc}
          valueStyle={{ color: '#3f8600' }}
          prefix='✓'
        />

        <Statistic
          title='KYC Rejected'
          value={kyc.rejectedKyc}
          valueStyle={{ color: '#cf1322' }}
          prefix='✘'
        />
      </div>
    </Card>
  );
}

export default KycCard;
