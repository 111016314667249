import React from 'react';
import AdminUserCard from '../settings/AdminUserCard';
import AdminUserRoleCard from '../settings/AdminUserRoleCard';

function Settings() {
  window.document.title = 'ERC-20 Token • Settings';

  return (
    <div className='space-y-2'>
      <AdminUserCard />
      <AdminUserRoleCard />
    </div>
  );
}

export default Settings;
