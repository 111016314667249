import { Button, Result } from 'antd';
import { Link } from 'react-router-dom';
import { getSessionUser } from '../utils/helperAuthentication';

function NotFound() {
  window.document.title = 'ERC-20 Token • Not Found';
  const user = getSessionUser();

  // function to back button click to redirect
  const buttonClickToAction = () => {
    if (user && user.user_type === 'host') {
      return '/admin/dashboard';
    }

    if (user && user.user_type === 'participant') {
      return '/user/dashboard';
    }

    if (user && user.user_type === 'sa') {
      return '/super/dashboard';
    }

    return '/app/home';
  };

  return (
    <div className='flex h-screen justify-center items-center'>
      <Result
        status='404'
        title='404 - Not Found!'
        subTitle='Sorry, the page you visited does not exist.'
        extra={(
          <Link to={buttonClickToAction()}>
            <Button type='primary'>
              {user ? 'Back To Dashboard' : 'Back To Home'}
            </Button>
          </Link>
        )}
      />
    </div>
  );
}

export default NotFound;
