import dayjs from 'dayjs';
import jwtDecode from 'jwt-decode';
import {
  getRefreshToken, getSessionToken, removeSessionAndLogoutUser, setSessionAccessAndRefreshToken
} from './helperAuthentication';

function handleRefreshTokenGenerate() {
  const accessToken = getSessionToken();
  const refreshToken = getRefreshToken();
  const decode = jwtDecode(accessToken);

  if (dayjs.unix(decode.exp).diff(dayjs()) < 1) {
    const myHeaders = new Headers();
    myHeaders.append('Authorization', `Bearer ${refreshToken}`);

    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      redirect: 'follow'
    };

    fetch(`${process.env.REACT_APP_API_BASE_URL}/api/v1/auth/refresh-token`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.result_code === 0) {
          setSessionAccessAndRefreshToken(result.result.access_token, result.result.refresh_token);
        } else {
          removeSessionAndLogoutUser();
        }
      })
      .catch(() => {
        removeSessionAndLogoutUser();
      });
  }
}

export default handleRefreshTokenGenerate;
