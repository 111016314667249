import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getSessionToken, removeSessionAndLogoutUser } from '../utils/helperAuthentication';
import handleRefreshTokenGenerate from '../utils/helperRefreshToken';

const useFetchData = (url, fetchAgain) => {
  const [loading, setLoading] = useState(true);
  const [response, setResponse] = useState(null);
  const [error, setError] = useState(null);
  const token = getSessionToken();

  // re-fetch api data redux state
  const { reloadApiState } = useSelector((state) => state.app);

  // if access-token expired to generate new token
  handleRefreshTokenGenerate();

  useEffect(() => {
    const myHeaders = new Headers();
    token && myHeaders.append('Authorization', `Bearer ${token}`);

    const requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };

    fetch(url, requestOptions)
      .then((res) => res.json())
      .then((result) => {
        if (result.result_code === 0) {
          setLoading(false);
          setResponse(result);
        } else if (result.result_code === 11) {
          // if access-token invalid logout user
          removeSessionAndLogoutUser();
        } else {
          setError(result.error.message);
          setLoading(false);
        }
      })
      .catch((err) => {
        setError(err.message);
        setLoading(false);
      });
  }, [url, fetchAgain, reloadApiState]);

  return [loading, error, response];
};

export default useFetchData;
