import {
  FacebookOutlined, InstagramOutlined, TwitterOutlined, YoutubeOutlined
} from '@ant-design/icons';
import { Button, Layout, Typography } from 'antd';
import React from 'react';

const { Footer } = Layout;
const { Text } = Typography;

function Footers() {
  return (
    <Footer className='flex flex-col items-center justify-between md:flex-row px-2 md:px-12 lg:px-24'>
      <div className='flex flex-col items-center justify-center md:items-start md:justify-start'>
        <Text>
          &copy;
          {' '}
          {`${new Date().getFullYear()} ERC-20 Token. All rights reserved.`}
        </Text>

        <div className='inline-flex items-center'>
          <Button className='px-0 sm:px-4' type='link' size='small'>How to buy?</Button>
          <Button className='px-1 sm:px-4' type='link' size='small'>Privacy and Policy</Button>
          <Button className='px-0 sm:px-4' type='link' size='small'>Terms and Condition</Button>
        </div>
      </div>

      <div className='space-x-4 md:space-x-2'>
        <Button type='dashed' shape='circle' icon={<FacebookOutlined />} size='middle' />
        <Button type='dashed' shape='circle' icon={<InstagramOutlined />} size='middle' />
        <Button type='dashed' shape='circle' icon={<TwitterOutlined />} size='middle' />
        <Button type='dashed' shape='circle' icon={<YoutubeOutlined />} size='middle' />
      </div>
    </Footer>
  );
}

export default Footers;
