import { SearchOutlined } from '@ant-design/icons';
import {
  Button, Empty, Input, Pagination, Result, Select, Skeleton, Tag
} from 'antd';
import React, { useEffect, useState } from 'react';
import useFetchData from '../../../hooks/useFetchData';
import { responseAsColor } from '../../../utils/helperShared';
import AclRoute from '../../routes/AclRoute';

const { Option } = Select;

function MyKycList({ add }) {
  const [searchKeyword, setSearchKeyword] = useState('');
  const [sortType, setSortType] = useState('aesc');
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);

  const [loading, error, response] = useFetchData(`${process.env.REACT_APP_API_BASE_URL}/api/v1/get-kyc-list?page=${page}&search=${searchKeyword}&limit=${limit}&sort=${sortType}`);

  // reset page number when search or limiting
  useEffect(() => {
    setPage(1);
  }, [searchKeyword, limit]);

  return (
    <AclRoute hasOwnPermission='Ico.Kyc.View'>
      <Skeleton loading={loading} active paragraph={{ rows: 10 }}>
        {error ? (
          <Result
            status='error'
            title='Fetching Error'
            subTitle={error}
          />
        ) : (
          <div>
            {/* SEARCH BOX & FILTERS */}
            <div className='search-and-filter-box'>
              <Input
                className='space-x-4'
                prefix={<SearchOutlined />}
                placeholder='Type in to Search KYC applications'
                onChange={(e) => setSearchKeyword(e.target.value)}
                size='large'
              />

              <Select
                className='w-full sm:w-[240px]'
                placeholder='-- show rows --'
                onChange={(value) => setLimit(value)}
                defaultValue='10'
                size='large'
              >
                <Option value='10'>10 Rows</Option>
                <Option value='20'>20 Rows</Option>
                <Option value='30'>30 Rows</Option>
                <Option value='40'>40 Rows</Option>
                <Option value='50'>50 Rows</Option>
              </Select>

              <Select
                className='w-full sm:w-[240px]'
                placeholder='-- select type to sort --'
                onChange={(value) => setSortType(value)}
                defaultValue='aesc'
                size='large'
              >
                <Option value='aesc'>Sort By Ascending</Option>
                <Option value='desc'>Sort By Descending</Option>
              </Select>
            </div>

            {/* KYC LIST */}
            {response && response.result.rows.length === 0 ? (
              <Empty
                className='mt-10'
                description={(
                  <span>Sorry! Your KYC application data was not found.</span>
                )}
              />
            ) : (
              <div className='w-full shadow bg-white rounded my-3'>
                <div className='border-gray-200 w-full rounded bg-white overflow-x-auto'>
                  <table className='w-full leading-normal'>
                    {/* TABLE HEADER */}
                    <thead className='table-thead'>
                      <tr className='border-b border-gray-200'>
                        <th scope='col' className='table-thead-th'>
                          FULL NAME
                        </th>
                        <th scope='col' className='table-thead-th'>
                          PHONE
                        </th>
                        <th scope='col' className='table-thead-th'>
                          REGISTRATION DATE
                        </th>
                        <th scope='col' className='table-thead-th'>
                          STATUS
                        </th>
                        <th scope='col' className='table-thead-th'>
                          ACTION
                        </th>
                      </tr>
                    </thead>

                    {/* DATA MAPPING ON TABLE BODY */}
                    {response && response.result.rows.map((data) => (
                      <tbody key={Math.random()}>
                        <tr className='table-body-tr'>
                          <td className='table-body-td'>
                            {`${data.first_name} ${data.last_name}`}
                          </td>
                          <td className='table-body-td'>
                            {data.phone}
                          </td>
                          <td className='table-body-td'>
                            {data.created_at.split(' ')[0]}
                          </td>
                          <td className='table-body-td'>
                            <Tag
                              className='px-2 py-1 text-sm capitalize'
                              color={responseAsColor(data.status)}
                            >
                              {data.status}
                            </Tag>
                          </td>
                          <td className='table-body-td'>
                            <AclRoute hasOwnPermission='Ico.Kyc.Detail'>
                              <Button
                                type='primary'
                                onClick={() => add(data.id, data.token_id)}
                              >
                                View Details
                              </Button>
                            </AclRoute>
                          </td>
                        </tr>
                      </tbody>
                    ))}
                  </table>
                </div>
              </div>
            )}

            {/* KYC LIST PAGINATION */}
            {response && response.result.total_page > 1 && (
              <Pagination
                className='flex items-center justify-center m-4'
                defaultCurrent={1}
                total={response.result.total_page * 10}
                onChange={(e) => setPage(e)}
              />
            )}
          </div>
        )}
      </Skeleton>
    </AclRoute>
  );
}

export default MyKycList;
