import { SearchOutlined } from '@ant-design/icons';
import {
  Button, Empty, Input, Pagination, Result, Select, Skeleton, Tag
} from 'antd';
import React, { useEffect, useState } from 'react';
import useFetchData from '../../../hooks/useFetchData';
import { purchaseResponseAsStatus } from '../../../utils/helperShared';

const { Option } = Select;

function MyTokenList({ add }) {
  const [searchKeyword, setSearchKeyword] = useState('');
  const [sortType, setSortType] = useState('aesc');
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);

  const [loading, error, response] = useFetchData(`${process.env.REACT_APP_API_BASE_URL}/api/v1/purchase-list?page=${page}&search=${searchKeyword}&limit=${limit}&sort=${sortType}`);

  // reset paginate page number
  useEffect(() => {
    setPage(1);
  }, [searchKeyword, limit]);

  return (
    <Skeleton loading={loading} active paragraph={{ rows: 10 }}>
      {error ? (
        <Result
          status='error'
          title='Fetching Error'
          subTitle={error}
        />
      ) : (
        <div>
          {/* SEARCH BOX & FILTERS */}
          <div className='search-and-filter-box'>
            <Input
              className='space-x-4'
              placeholder='Type in to Search your purchase Tokens'
              onChange={(e) => setSearchKeyword(e.target.value)}
              prefix={<SearchOutlined />}
              size='large'
            />

            <Select
              className='w-full sm:w-[240px]'
              placeholder='-- show rows --'
              onChange={(value) => setLimit(value)}
              defaultValue='10'
              size='large'
            >
              <Option value='10'>10 Rows</Option>
              <Option value='20'>20 Rows</Option>
              <Option value='30'>30 Rows</Option>
              <Option value='40'>40 Rows</Option>
              <Option value='50'>50 Rows</Option>
            </Select>

            <Select
              className='w-full sm:w-[240px]'
              placeholder='-- select type to sort --'
              onChange={(value) => setSortType(value)}
              defaultValue='aesc'
              size='large'
            >
              <Option value='aesc'>Sort By Ascending</Option>
              <Option value='desc'>Sort By Descending</Option>
            </Select>
          </div>

          {/* MY TOKEN LIST */}
          {response && response.result.rows.length === 0 ? (
            <Empty
              className='mt-10'
              description={(
                <span>Sorry! Your purchase tokens data was not found.</span>
              )}
            />
          ) : (
            <div className='w-full shadow bg-white rounded my-3'>
              <div className='border-gray-200 w-full rounded bg-white overflow-x-auto'>
                <table className='w-full leading-normal'>
                  {/* TABLE HEADER */}
                  <thead className='table-thead border-gray border-gray-200 border-b-2'>
                    <tr className='border-b border-gray-200'>
                      <th scope='col' className='table-thead-th'>
                        Token Name (Symbol)
                      </th>
                      <th scope='col' className='table-thead-th'>
                        Token Quantity
                      </th>
                      <th scope='col' className='table-thead-th'>
                        Accepted Currency
                      </th>
                      <th scope='col' className='table-thead-th'>
                        Purchase Status
                      </th>
                      <th scope='col' className='table-thead-th'>
                        Purchase Date
                      </th>
                      <th scope='col' className='table-thead-th'>
                        ACTION
                      </th>
                    </tr>
                  </thead>

                  {/* DATA MAPPING ON TABLE BODY */}
                  {response && response.result.rows.map((data) => (
                    <tbody key={data.id}>
                      <tr className='table-body-tr'>
                        <td className='table-body-td'>
                          {`${data.token_name} (${data.token_symbol})`}
                        </td>
                        <td className='table-body-td'>
                          {data.token_quantity}
                        </td>
                        <td className='table-body-td !uppercase'>
                          {data.accepted_currency}
                        </td>
                        <td className='table-body-td'>
                          <Tag
                            className='min-w-[100px] px-1 py-1 text-sm text-center capitalize'
                            color={purchaseResponseAsStatus(data.status).color}
                          >
                            {purchaseResponseAsStatus(data.status).status}
                          </Tag>
                        </td>
                        <td className='table-body-td'>
                          {data.created_at.split(' ')[0]}
                        </td>
                        <td className='table-body-td'>
                          <Button type='primary' onClick={() => add(data.id)}>
                            View Details
                          </Button>
                        </td>
                      </tr>
                    </tbody>
                  ))}
                </table>
              </div>
            </div>
          )}

          {/* MY TOKEN LIST PAGINATION */}
          {response && response.result.total_page > 1 && (
            <Pagination
              className='flex items-center justify-center m-4'
              defaultCurrent={1}
              total={response.result.total_page * 10}
              onChange={(e) => setPage(e)}
            />
          )}
        </div>
      )}
    </Skeleton>
  );
}

export default MyTokenList;
