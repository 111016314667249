/* eslint-disable no-restricted-syntax */
/* eslint-disable react/jsx-props-no-spreading */
import {
  LoadingOutlined, MinusCircleOutlined, PlusOutlined, WalletOutlined
} from '@ant-design/icons';
import {
  Button, DatePicker, Divider, Form, Input, InputNumber, message, Result, Select, Space, Typography
} from 'antd';
import moment from 'moment';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import eventMsg from '../../../data/event-message.json';
import useFetchData from '../../../hooks/useFetchData';
import convertToInternationalCurrencySystem from '../../../lib/internationalCurrencyConvert';
import { getSessionToken } from '../../../utils/helperAuthentication';
import AclRoute from '../../routes/AclRoute';

const { RangePicker } = DatePicker;
const { Search, TextArea } = Input;
const { Title } = Typography;
const { Option } = Select;

function CreateIco() {
  window.document.title = 'ERC-20 Token • Create ICO';
  const [contractAddress, setContractAddress] = useState(null);
  const [tokenInfoLoading, setTokenInfoLoading] = useState(false);
  const [getTokenInfo, setGetTokenInfo] = useState(false);
  const [initialSupply, setInitialSupply] = useState(0);
  const [networkChain, setNetworkChain] = useState('mainnet');
  const [loading, setLoading] = useState(false);
  const token = getSessionToken();
  const { useForm } = Form;
  const [form] = useForm();

  // fetch currency list API data
  const [currencyListLoading, currencyListError, currencyListResponse] = useFetchData(`${process.env.REACT_APP_API_BASE_URL}/api/v1/get-currency-list`);

  const { chainListError, chainListData } = useSelector((state) => state.chain);

  // create-ico form initial values
  const initialValues = {
    stages: [{
      dateOfStage: undefined,
      tokenPrice: undefined,
      tokenSaleAmount: undefined
    }],
    currency: [{
      acceptedCurrency: undefined,
      walletAddress: undefined
    }]
  };

  // function to handle form submit (create ico for token sailing)
  const handleSubmitForm = (values) => {
    // formatting multiple stages array
    const multipleStages = [
      ...values.stages.map((stage, index) => ({
        start_date: moment(values.stages[index].dateOfStage[0]).format('YYYY-MM-DD'),
        end_date: moment(values.stages[index].dateOfStage[1]).format('YYYY-MM-DD'),
        token_sale_amount: stage.tokenSaleAmount,
        token_price: stage.tokenPrice,
        bonus: stage.stageBonus,
        min_purchase: stage.minTokenPurchase,
        max_purchase: stage.maxTokenPurchase
      }))
    ];

    // formatting multiple wallets array
    const formattedWallets = [
      ...values.currency.map((wallet) => ({
        wallet_address: wallet.walletAddress,
        currency_id: wallet.acceptedCurrency
      }))
    ];

    // function to sum of stage token sale amount
    const sumOfTokenSaleAmount = () => {
      let totalSaleAmount = 0;
      multipleStages.forEach((data) => {
        totalSaleAmount += data.token_sale_amount;
      });

      return totalSaleAmount;
    };

    // check target token sale quantity larger then sum if stage token sale amount
    if (sumOfTokenSaleAmount() > values.targetTokenSale) {
      message.error(eventMsg.admin.createICO.stageTokenQuantityLargerThenTargetTokenSaleQuantity);
      return;
    }

    // check stage mix token purchase wants max token purchase larger
    for (const data of multipleStages) {
      if (data.min_purchase > data.max_purchase) {
        message.error(eventMsg.admin.createICO.minTokenQuantityLargerThenMaxQuantity);
        return;
      }
    }

    setLoading(true);
    const myHeaders = new Headers();
    myHeaders.append('Authorization', `Bearer ${token}`);
    myHeaders.append('Content-Type', 'application/json');

    const raw = JSON.stringify({
      token_name: values.tokenName,
      token_symbol: values.tokenSymbol,
      total_supply: values.initialSupply,
      token_decimals: parseInt(values.decimals, 10),
      token_issuer: values.tokenIssuer,
      target_token_sale: values.targetTokenSale,
      network: networkChain,
      contract_address: contractAddress,
      stages: multipleStages,
      wallets: formattedWallets,
      contact_address: values.contactAddress,
      description: values.tokenDescription
    });

    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    fetch(`${process.env.REACT_APP_API_BASE_URL}/api/v1/save-ico-declaration-info`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.result_code === 0) {
          message.success(eventMsg.admin.createICO.successCreateICO);
          form.resetFields();
          setLoading(false);

          setTimeout(() => {
            window.location.reload();
          }, 600);
        } else {
          message.error(result.error.message);
          setLoading(false);
        }
      })
      .catch((error) => {
        message.error(error.message);
        setLoading(false);
      });
  };

  // function to get token info by contract address
  const handleGetTokenInfo = () => {
    if (contractAddress === null) {
      message.error(eventMsg.admin.createICO.tokenInfoFindingContractAddressNullError);
    } else {
      setTokenInfoLoading(true);
      const myHeaders = new Headers();
      myHeaders.append('Authorization', `Bearer ${token}`);

      const requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
      };

      fetch(`${process.env.REACT_APP_API_BASE_URL}/api/v1/get-token-contract-info?contract_address=${contractAddress}&chain=${networkChain}`, requestOptions)
        .then((response) => response.json())
        .then((result) => {
          setTokenInfoLoading(false);

          if (result.result_code === 0) {
            // set from fields value
            form.setFieldsValue({
              tokenName: result.result.tokenName,
              tokenSymbol: result.result.tokenSymbol,
              initialSupply: result.result.tokenTotalSupply,
              decimals: result.result.tokenDecimals
            });

            message.success(eventMsg.admin.createICO.tokenInfoFindingSuccess);
            setInitialSupply(result.result.tokenTotalSupply);
            setGetTokenInfo(true);
          } else {
            message.error(result.error.message ? result.error.message : result.message);
          }
        })
        .catch((error) => {
          message.error(error.message);
          setTokenInfoLoading(false);
        });
    }
  };

  return (
    <div>
      <Title className='mt-2' level={4}>Create ICO For Sale Token</Title>
      <Divider />

      {/* SEARCH TOKEN INFO */}
      {!getTokenInfo && (
        <AclRoute hasOwnPermission='Ico.Declaration.Contract.Info.View'>
          <Input.Group className='px-0 mb-5 md:px-40' compact>
            <Select
              defaultValue='mainnet'
              onChange={(value) => setNetworkChain(value)}
              size='large'
            >
              {chainListError === null && chainListData && chainListData.map((data) => (
                <Option key={data.id} value={data.chain_name}>{data.network_name}</Option>
              ))}
            </Select>

            <Search
              className='w-[100%] sm:w-[80%]'
              placeholder='Input here your token contract address (e.g. 0x8FD22Db75...)'
              onChange={(e) => setContractAddress(e.target.value)}
              onSearch={handleGetTokenInfo}
              enterButton='Get Token Info'
              loading={tokenInfoLoading}
              size='large'
            />
          </Input.Group>
        </AclRoute>
      )}

      {/* CREATE ICO FORM */}
      {getTokenInfo && (
        <Form
          form={form}
          name='create-ico-form'
          initialValues={initialValues}
          onFinish={handleSubmitForm}
          autoComplete='off'
          layout='vertical'
        >
          {/* INPUT FIELD GROUP TOKEN NAME & SYMBOLS */}
          <div className='kyc-application-form-group'>
            <Form.Item
              className='kyc-application-form-item'
              label='Token Name'
              name='tokenName'
              rules={[{
                required: true,
                message: 'Please input your Token Name'
              }]}
            >
              <Input placeholder='e.g. Good Luck Token' size='large' disabled />
            </Form.Item>

            <Form.Item
              className='kyc-application-form-item'
              label='Token Symbol'
              name='tokenSymbol'
              rules={[{
                required: true,
                message: 'Please input here Token Symbols'
              }]}
            >
              <Input placeholder='e.g. GLT' size='large' disabled />
            </Form.Item>
          </div>

          {/* INPUT FIELD GROUP TOKEN ISSUER NAME & CONTACT ADDRESS */}
          <div className='kyc-application-form-group'>
            <Form.Item
              className='kyc-application-form-item'
              label='Token Issuer'
              name='tokenIssuer'
              rules={[{
                required: true,
                message: 'Please input here Token Issuer'
              }]}
            >
              <Input placeholder='e.g. John Smith / Example Ltd.' size='large' />
            </Form.Item>

            <Form.Item
              className='kyc-application-form-item'
              label='Mailing Address'
              name='contactAddress'
              rules={[{
                required: false,
                message: 'Please input here Your Address'
              }]}
            >
              <Input placeholder='e.g. 123 Main Street, New York, NY 10030' size='large' />
            </Form.Item>
          </div>

          {/* TEXT AREA TOKEN DESCRIPTION */}
          <Form.Item
            className='kyc-application-form-item'
            label='Token Description'
            name='tokenDescription'
            rules={[{
              required: true,
              message: 'Please input here Token Description'
            }]}
          >
            <TextArea
              style={{ height: 120 }}
              placeholder='Write down here your token description'
              size='large'
            />
          </Form.Item>

          {/* INPUT FIELD GROUP TOKEN INITIAL SUPPLY & DECIMALS */}
          <div className='kyc-application-form-group'>
            <Form.Item
              className='kyc-application-form-item'
              label='Total Token Supply'
            >
              <Form.Item
                name='initialSupply'
                rules={[{
                  required: true,
                  message: 'Please input here Initial Supply'
                }]}
                noStyle
              >
                <InputNumber
                  className='!w-[400px]'
                  placeholder='e.g. 21000000'
                  type='number'
                  size='large'
                  disabled
                  onChange={(value) => {
                    setInitialSupply(value);
                  }}
                />
              </Form.Item>

              <span className='ant-form-text'>
                {`≈ ${convertToInternationalCurrencySystem(initialSupply)}`}
              </span>
            </Form.Item>

            <Form.Item
              className='kyc-application-form-item'
              label='Token Decimals (1-18)'
              name='decimals'
              rules={[{
                required: true,
                message: 'Please input here Decimals'
              }]}
            >
              <InputNumber
                className='!w-[400px]'
                placeholder='e.g. 13'
                type='number'
                size='large'
                disabled
                max={18}
                min={1}
              />
            </Form.Item>
          </div>

          {/* INPUT FILED GROUP TARGET AMOUNT & TARGET TOKEN SALE */}
          <div className='kyc-application-form-group'>
            <Form.Item
              className='kyc-application-form-item'
              label='Target Token Sale Quantity'
              name='targetTokenSale'
              rules={[{
                required: true,
                message: 'Please input here Target Token Sale'
              }]}
            >
              <InputNumber
                className='!w-[400px]'
                placeholder='e.g. 1000'
                type='number'
                size='large'
                min={1}
                max={initialSupply}
              />
            </Form.Item>
          </div>

          {/* MULTIPLE STAGE'S LABEL */}
          <h4 className='text-center text-xl font-semibold my-4'>
            ADD STAGE INFORMATION
          </h4>

          {/* STAGE'S FORM LIST */}
          <Form.List name='stages'>
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, ...restField }) => (
                  <Space
                    className='flex flex-col items-center justify-between'
                    align='baseline'
                    key={key}
                  >
                    <div className='flex flex-col items-center justify-between space-x-0 md:flex-row md:space-x-4'>
                      {/* RANGE PICKER FOR DATE OF STAGE */}
                      <Form.Item
                        {...restField}
                        name={[name, 'dateOfStage']}
                        rules={[{
                          required: true,
                          message: 'Please input here stage Start & End date'
                        }]}
                      >
                        <RangePicker
                          className='w-[400px] md:w-[340px]'
                          format='YYYY-MM-DD'
                          size='large'
                        />
                      </Form.Item>

                      {/* INPUT NUMBER OF TOKEN SALE AMOUNT */}
                      <Form.Item
                        {...restField}
                        name={[name, 'tokenSaleAmount']}
                        rules={[{
                          required: true,
                          message: 'Please input here Token Sale Quantity'
                        }]}
                      >
                        <InputNumber
                          className='w-[400px] md:w-[270px]'
                          placeholder='Token Sale Quantity (e.g. 100)'
                          addonBefore='Q'
                          type='number'
                          size='large'
                          min={1}
                        />
                      </Form.Item>

                      {/* INPUT NUMBER OF TOKEN PRICE */}
                      <Form.Item
                        {...restField}
                        name={[name, 'tokenPrice']}
                        rules={[{
                          required: true,
                          message: 'Please input here Token Unit Price'
                        }]}
                      >
                        <InputNumber
                          className='w-[400px] md:w-[250px]'
                          placeholder='Token Unit Price (e.g. $ 20)'
                          addonBefore='$'
                          type='number'
                          size='large'
                          min={1}
                        />
                      </Form.Item>
                    </div>

                    <div className='flex flex-col items-baseline justify-between space-x-0 md:flex-row md:space-x-4'>
                      {/* INPUT NUMBER OF TOKEN BONUS */}
                      <Form.Item
                        {...restField}
                        name={[name, 'stageBonus']}
                        rules={[{
                          required: true,
                          message: 'Please input here Stage Token Bonus'
                        }]}
                      >
                        <InputNumber
                          className={key > 0 ? 'w-[400px] md:w-[265px]' : 'w-[400px] md:w-[270px]'}
                          placeholder='Stage Bonus (e.g. 10 %)'
                          addonBefore='%'
                          type='number'
                          size='large'
                          min={1}
                        />
                      </Form.Item>

                      {/* INPUT NUMBER OF MIN TOKEN PURCHASE */}
                      <Form.Item
                        {...restField}
                        name={[name, 'minTokenPurchase']}
                        rules={[{
                          required: true,
                          message: 'Please input here Min Token Purchase'
                        }]}
                      >
                        <InputNumber
                          className={key > 0 ? 'w-[400px] md:w-[285px]' : 'w-[400px] md:w-[295px]'}
                          placeholder='Min Token Purchase (e.g. 0.0001)'
                          addonBefore='Min'
                          type='number'
                          size='large'
                          min={0.0001}
                        />
                      </Form.Item>

                      {/* INPUT NUMBER OF TOKEN MAX PURCHASE */}
                      <Form.Item
                        {...restField}
                        name={[name, 'maxTokenPurchase']}
                        rules={[{
                          required: true,
                          message: 'Please input here Max Token Purchase'
                        }]}
                      >
                        <InputNumber
                          className={key > 0 ? 'w-[400px] md:w-[285px]' : 'w-[400px] md:w-[295px]'}
                          placeholder='Max Token Purchase (e.g. 100)'
                          addonBefore='Max'
                          type='number'
                          size='large'
                          min={1}
                        />
                      </Form.Item>

                      {key > 0 && (
                        <MinusCircleOutlined
                          className='!ml-[50%] !mb-4 md:!ml-2 md:!mb-0 hover:text-errorColor'
                          onClick={() => remove(name)}
                        />
                      )}

                    </div>
                  </Space>
                ))}

                {/* ADD NEW STAGE BUTTON */}
                <Form.Item className='flex items-center justify-center'>
                  <Button
                    className='!w-[400px] font-bold md:!w-[890px]'
                    icon={<PlusOutlined />}
                    onClick={() => add()}
                    type='dashed'
                    block
                  >
                    Add New Stage
                  </Button>
                </Form.Item>
              </>
            )}
          </Form.List>

          {/* STAGE'S LABEL */}
          <h4 className='text-center text-xl font-semibold my-4'>
            ADD CURRENCY INFORMATION
          </h4>

          {/* STAGE'S FORM LIST */}
          <Form.List name='currency'>
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, ...restField }) => (
                  <Space
                    className='flex flex-row justify-center'
                    align='baseline'
                    key={key}
                  >
                    {currencyListError ? (
                      <Result
                        status='error'
                        title='Fetching Error'
                        subTitle={currencyListError}
                      />
                    ) : (
                      <div className='flex flex-col items-center justify-center space-x-0 md:flex-row md:space-x-4'>
                        {/* SELECT OPTION ACCEPTED CURRENCY */}
                        <Form.Item
                          {...restField}
                          name={[name, 'acceptedCurrency']}
                          rules={[{
                            required: true,
                            message: 'Please select your Accepted Currency'
                          }]}
                        >
                          <Select
                            className={key > 0 ? '!w-[380px] md:!w-[400px]' : '!w-[400px] md:!w-[400px]'}
                            placeholder='-- select accepted currency --'
                            loading={currencyListLoading}
                            size='large'
                            showSearch
                          >
                            {currencyListResponse?.result?.map((data) => (
                              <Option key={data.id} value={data.id}>
                                {`${data.currency_name} - ${data.currency_code.toUpperCase()} (${data.currency_symbol.toUpperCase()})`}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>

                        {/* INPUT FIELD WALLET ADDRESS */}
                        <Form.Item
                          {...restField}
                          name={[name, 'walletAddress']}
                          rules={[{
                            required: true,
                            message: 'Please input here Wallet Address'
                          }]}
                        >
                          <Input
                            className={key > 0 ? '!w-[380px] md:!w-[460px]' : '!w-[400px] md:!w-[480px]'}
                            placeholder='Your Wallet Address (e.g. 0x4758c74...)'
                            addonBefore={<WalletOutlined />}
                            size='large'
                          />
                        </Form.Item>
                      </div>
                    )}

                    {key > 0 && (
                      <MinusCircleOutlined
                        className='hover:text-errorColor'
                        onClick={() => remove(name)}
                      />
                    )}
                  </Space>
                ))}

                {/* ADD NEW CURRENCY BUTTON */}
                <Form.Item className='flex items-center justify-center'>
                  <Button
                    className='!w-[400px] font-bold md:!w-[890px]'
                    icon={<PlusOutlined />}
                    onClick={() => add()}
                    type='dashed'
                    block
                  >
                    Add New Currency
                  </Button>
                </Form.Item>
              </>
            )}
          </Form.List>

          {/* FORM SUBMIT BUTTON */}
          <Form.Item className='flex items-center justify-center'>
            <Button
              className='w-[400px] font-bold'
              disabled={loading}
              htmlType='submit'
              type='primary'
              size='large'
            >
              {loading ? <LoadingOutlined /> : 'Save ICO Declaration Info'}
            </Button>
          </Form.Item>
        </Form>
      )}
    </div>
  );
}

export default CreateIco;
