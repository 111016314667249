import { Card, Statistic, Typography } from 'antd';
import React from 'react';

const { Title } = Typography;

function UserCard({ user }) {
  return (
    <Card className='dashboard-card cursor-default h-56 px-2 sm:px-4' hoverable>
      <Title className='inline-flex items-center mt-2' level={4}>
        Users Information
      </Title>

      <div className='flex flex-row justify-start items-center space-x-8 mt-2'>
        <Statistic
          title='Total Host'
          value={user?.host}
          valueStyle={{ color: '#1f2937' }}
          prefix='↹'
        />

        <Statistic
          title='Total Participants'
          value={user?.participant}
          valueStyle={{ color: '#2563eb' }}
          prefix='⇆'
        />
      </div>
    </Card>
  );
}

export default UserCard;
