import {
  Button, Layout, Result, Typography
} from 'antd';
import React from 'react';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import Footers from '../../components/common/Footers';
import Headers from '../../components/common/Headers';
import isEmptyObject from '../../lib/isEmptyObject';

const { Paragraph } = Typography;
const { Content } = Layout;

function KycMessage() {
  window.document.title = 'ERC-20 Token • KYC Message';
  const navigation = useNavigate();
  const location = useLocation();

  return isEmptyObject(location?.state) ? (
    <Navigate to='/user/dashboard' replace />
  ) : (
    <Layout>
      {/* MY-APP HEADER */}
      <Headers />

      {/* MY-APP CONTENT */}
      <Content className='min-h-[80vh] mx-auto'>
        <Result
          status='success'
          title='KYC Submit Successful'
          extra={(
            <>
              <Paragraph className='w-[400px] md:w-[550px] mt-10 pb-5 text-justify'>
                {`Hi ${location?.state?.userName}`}
                ,
                {' '}
                <br />
                {`Thank you for submitting KYC. We received all information successfully. We will inform you after verifying the necessary doc. You can purchase (${location?.state?.tokenName}) after approving your KYC.`}
                <br />
                {' '}
                <br />
                Thanks for being with us.
              </Paragraph>

              <Button
                key='dashboard'
                type='primary'
                size='large'
                onClick={() => navigation('/user/dashboard')}
              >
                Back To Dashboard
              </Button>
            </>
          )}
        />
      </Content>

      {/* MY-APP FOOTER */}
      <Footers />
    </Layout>
  );
}

export default KycMessage;
