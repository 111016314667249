import { Navigate } from 'react-router-dom';
import { getSessionToken, getSessionUser } from '../../utils/helperAuthentication';

function AdminRoute({ children }) {
  const user = getSessionUser();
  const token = getSessionToken();

  if (!user && !token) {
    return <Navigate to='/auth/login' replace />;
  }

  if (user.user_type !== 'host') {
    return <Navigate to='/user/dashboard' replace />;
  }

  return children;
}

export default AdminRoute;
