import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import {
  Button, Form, Input, message, Tooltip, Upload
} from 'antd';
import React, { useEffect, useState } from 'react';
import eventMsg from '../../data/event-message.json';
import {
  getSessionToken, getSessionUser, setSessionUserKeyAgainstValue
} from '../../utils/helperAuthentication';
import ga4EventAction from '../../utils/helperGAEventAction';

function PersonalData() {
  const [loading, setLoading] = useState(false);
  const token = getSessionToken();
  const user = getSessionUser();
  const { useForm } = Form;
  const [form] = useForm();

  useEffect(() => {
    form.setFieldsValue({
      firstName: user.first_name,
      lastName: user.last_name
    });
  }, [form]);

  const updateProfile = (values) => {
    // google analytics event handler
    ga4EventAction('link', 'Update Profile', 'Admin/User Update Profile');

    setLoading(true);
    const myHeaders = new Headers();
    myHeaders.append('Authorization', `Bearer ${token}`);

    const formdata = new FormData();
    formdata.append('first_name', values.firstName);
    formdata.append('last_name', values.lastName);
    formdata.append('profile_image', values.userAvatar.fileList[0].originFileObj);

    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    };

    fetch(`${process.env.REACT_APP_API_BASE_URL}/api/v1/auth/update-profile`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setLoading(false);

        if (result.result_code === 0) {
          message.success(eventMsg.auth.profileUpdateSuccessMessage);

          // set's updated user info in session
          setSessionUserKeyAgainstValue('first_name', result.result.user_info.first_name);
          setSessionUserKeyAgainstValue('last_name', result.result.user_info.last_name);
          setSessionUserKeyAgainstValue('profile_image', result.result.user_info.profile_image);

          setTimeout(() => {
            window.location.reload();
          }, 500);
        } else {
          message.error(result.error.message);
        }
      })
      .catch((error) => {
        message.error(error.message);
        setLoading(false);
      });
  };

  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  return (
    <Form
      form={form}
      name='profile-form'
      labelCol={{ span: 6 }}
      wrapperCol={{ span: 12 }}
      initialValues={{ remember: true }}
      onFinish={updateProfile}
      autoComplete='off'
    >
      {/* INPUT FIELD USER AVATAR */}
      <Form.Item
        label='User Avatar'
        name='userAvatar'
        valuePropName='userAvatar'
        rules={[{
          required: true,
          message: 'Please upload your avatar'
        }]}
      >
        <Upload
          maxCount={1}
          name='userAvatar'
          listType='picture-card'
          accept='.jpg, .jpeg, .png'
          beforeUpload={() => false}
          showUploadList
        >
          <Tooltip title='Change or Upload your profile image to click the image !!!'>
            {user && user.profile_image ? (
              <img
                className='w-full object-cover'
                src={user.profile_image.includes('googleusercontent') ? user.profile_image : `${process.env.REACT_APP_API_BASE_URL}/temp/${user.profile_image}`}
                alt='user-avatar'
              />
            ) : uploadButton}
          </Tooltip>
        </Upload>
      </Form.Item>

      {/* INPUT FIELD FIRST NAME */}
      <Form.Item
        label='First Name'
        name='firstName'
        rules={[{
          required: true,
          message: 'Please input here your first name!'
        }]}
      >
        <Input placeholder='Type here your first name' size='large' />
      </Form.Item>

      {/* INPUT FIELD LAST NAME */}
      <Form.Item
        label='Last Name'
        name='lastName'
        rules={[{
          required: true,
          message: 'Please input here your last name!'
        }]}
      >
        <Input placeholder='Type here your last name' size='large' />
      </Form.Item>

      {/* FORM SUBMIT BUTTON */}
      <Form.Item className='flex items-center justify-center'>
        <Button
          className='w-[300px] font-bold'
          disabled={loading}
          htmlType='submit'
          type='primary'
          size='large'
        >
          {loading ? <LoadingOutlined /> : 'Update Profile'}
        </Button>
      </Form.Item>
    </Form>
  );
}

export default PersonalData;
