import {
  Descriptions, Image, Result, Skeleton
} from 'antd';
import useFetchData from '../../../hooks/useFetchData';

function ParticipantDetails({ id }) {
  const [loading, error, response] = useFetchData(`${process.env.REACT_APP_API_BASE_URL}/api/v1/participant-detail/${id}`);

  return (
    <Skeleton loading={loading} active avatar paragraph={{ rows: 6 }}>
      {error ? (
        <Result
          status='error'
          title='Fetching Error'
          subTitle={error}
        />
      ) : (
        <Descriptions
          title='Participant Details'
          labelStyle={{ fontWeight: 'bold' }}
          bordered
        >
          {/* PARTICIPANT IMAGE */}
          <Descriptions.Item label='Participant Image' span={3}>
            {response?.result?.profile_image ? (
              <Image
                className='w-[200px] shadow-sm rounded-sm'
                src={response?.result?.profile_image?.includes('googleusercontent') ? response?.result?.profile_image : `${process.env.REACT_APP_API_BASE_URL}/temp/${response?.result?.profile_image}`}
                alt='participant-image'
              />
            ) : 'N/A'}
          </Descriptions.Item>

          <Descriptions.Item label='First Name'>
            {response?.result?.first_name}
          </Descriptions.Item>
          <Descriptions.Item label='Last Name' span={2}>
            {response?.result?.last_name}
          </Descriptions.Item>

          <Descriptions.Item label='Email'>
            {response?.result?.email}
          </Descriptions.Item>
          <Descriptions.Item className='capitalize' label='User Type' span={2}>
            {response?.result?.user_type}
          </Descriptions.Item>
        </Descriptions>
      )}
    </Skeleton>
  );
}

export default ParticipantDetails;
