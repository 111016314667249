import React from 'react';
import { Navigate } from 'react-router-dom';
import { getSessionUser } from '../utils/helperAuthentication';

function Home() {
  window.document.title = 'ERC-20 Token • Home';
  const user = getSessionUser();

  if (user && user.user_type === 'host') {
    return <Navigate to='/admin/dashboard' replace />;
  }

  if (user && user.user_type === 'participant') {
    return <Navigate to='/user/dashboard' replace />;
  }

  if (user && user.user_type === 'sa') {
    return <Navigate to='/super/dashboard' replace />;
  }

  return <Navigate to='/app/home' replace />;
}

export default Home;
