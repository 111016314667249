import { ethers } from 'ethers';
import { useDispatch } from 'react-redux';
import eventMsg from '../data/event-message.json';
import {
  deleteWalletAddress, deleteWalletBalance, deleteWalletChainId, deleteWalletNetwork, setWalletAddress, setWalletBalance, setWalletChainId, setWalletNetwork
} from '../redux/actions/walletAction';
import ga4EventAction from '../utils/helperGAEventAction';
import notificationWithIcon from '../utils/popupNotification';

const useConnectWallet = () => {
  const dispatch = useDispatch();

  // wallet connect handler function
  const connectWalletHandler = () => {
    // google analytics event handler
    ga4EventAction('link', 'connect wallet', 'wallet connection');

    if (window?.ethereum && window?.ethereum?.isMetaMask) {
      window?.ethereum
        .request({ method: 'eth_requestAccounts' })
        .then((result) => {
          accountChangedHandler(result[0]);
          getAccountBalance(result[0]);
        })
        .catch((error) => {
          notificationWithIcon('error', 'Wallet Connection Error', error?.message);
        });
    } else {
      notificationWithIcon('error', eventMsg.app.walletConnectionError.title, eventMsg.app.walletConnectionError.message);
    }
  };

  // update account, will cause component re-render
  const accountChangedHandler = (newAccount) => {
    // redux store set wallet address
    dispatch(setWalletAddress(typeof newAccount === 'object' ? newAccount[0] : newAccount));

    getAccountBalance(newAccount?.toString());
    getAccountNetworks();
  };

  // get connected wallet account balance
  const getAccountBalance = (account) => {
    window?.ethereum
      .request({ method: 'eth_getBalance', params: [account, 'latest'] })
      .then((balance) => {
        // redux store set wallet balance
        dispatch(setWalletBalance(ethers?.utils?.formatEther(balance)));
      })
      .catch((error) => {
        notificationWithIcon('error', 'Wallet Balance Error', error?.message);
        // removed metamask wallet data redux store
        dispatch(deleteWalletNetwork());
        dispatch(deleteWalletAddress());
        dispatch(deleteWalletBalance());
        dispatch(deleteWalletChainId());
      });
  };

  // get connected wallet networks
  const getAccountNetworks = () => {
    window?.ethereum
      .request({ method: 'eth_chainId' })
      .then((ChainId) => {
        const parseChainId = parseInt(ChainId, 16);

        if (parseChainId === 1 || parseChainId === 5 || parseChainId === 11155111) {
          // redux store set wallet chain id
          dispatch(setWalletChainId(parseChainId));

          // redux store set token create network
          switch (parseChainId) {
            case 1: {
              dispatch(setWalletNetwork('mainnet'));
              break;
            }
            case 5: {
              dispatch(setWalletNetwork('goerli'));
              break;
            }
            case 11155111: {
              dispatch(setWalletNetwork('sepolia'));
              break;
            }
            default: {
              dispatch(setWalletNetwork('mainnet'));
              break;
            }
          }
        } else {
          notificationWithIcon('error', eventMsg.app.networkNotSupportError.title, eventMsg.app.networkNotSupportError.message);
          // removed metamask wallet data redux store
          dispatch(deleteWalletNetwork());
          dispatch(deleteWalletAddress());
          dispatch(deleteWalletBalance());
          dispatch(deleteWalletChainId());
        }
      })
      .catch((error) => {
        notificationWithIcon('error', 'Wallet Networks Error', error?.message);
        // removed metamask wallet data redux store
        dispatch(deleteWalletNetwork());
        dispatch(deleteWalletAddress());
        dispatch(deleteWalletBalance());
        dispatch(deleteWalletChainId());
      });
  };

  // wallet change handler
  const chainChangedHandler = () => {
    // reload the page to avoid any errors with chain change mid use of application
    window.location.reload();
  };

  // listen for account changes
  window?.ethereum?.on('accountsChanged', accountChangedHandler);
  window?.ethereum?.on('chainChanged', chainChangedHandler);

  return { connectWalletHandler };
};

export default useConnectWallet;
