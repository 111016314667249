import { Navigate } from 'react-router-dom';
import { getSessionToken, getSessionUser } from '../../utils/helperAuthentication';

function UserRoute({ children }) {
  const user = getSessionUser();
  const token = getSessionToken();

  if (!user && !token) {
    return <Navigate to='/auth/login' replace />;
  }

  if (user.user_type !== 'participant') {
    return <Navigate to='/admin/dashboard' replace />;
  }

  return children;
}

export default UserRoute;
