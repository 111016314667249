import { Tabs, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import { getSessionUser } from '../../../utils/helperAuthentication';
import ChangePassword from '../../account/ChangePassword';
import PersonalData from '../../account/PersonalData';
import MyKycApplication from '../kyc/MyKycApplication';

const { Title } = Typography;
const { TabPane } = Tabs;

function MyProfile() {
  window.document.title = 'ERC-20 Token • My Profile';
  const [searchParams, setSearchParams] = useSearchParams();
  const [activeTabKey, setActiveTabKey] = useState('1');
  const { search } = useLocation();
  const user = getSessionUser();

  const handleTabChange = (key) => {
    switch (key) {
      case '1': {
        setActiveTabKey('1');
        searchParams.set('tab', 'personal-data');
        setSearchParams(searchParams);
        break;
      }
      case '2': {
        setActiveTabKey('2');
        searchParams.set('tab', 'change-password');
        setSearchParams(searchParams);
        break;
      }
      case '3': {
        setActiveTabKey('3');
        searchParams.set('tab', 'my-kyc-application');
        setSearchParams(searchParams);
        break;
      }
      default: {
        setActiveTabKey('1');
      }
    }
  };

  useEffect(() => {
    switch (search) {
      case '?tab=personal-data': {
        setActiveTabKey('1');
        break;
      }
      case '?tab=change-password': {
        setActiveTabKey('2');
        break;
      }
      case '?tab=my-kyc-application': {
        setActiveTabKey('3');
        break;
      }
      default: {
        setActiveTabKey('1');
      }
    }
  }, [search]);

  return (
    <div className='bg-backgroundColorWhite min-h-[68vh] shadow-md rounded-sm p-2 md:p-4'>
      <Title className='text-textColorBlack' level={4}>Profile Details</Title>

      <Tabs
        activeKey={activeTabKey}
        onChange={(key) => handleTabChange(key)}
        size='middle'
        type='card'
      >
        <TabPane tab='Personal Data' key='1'>
          <PersonalData />
        </TabPane>

        {user && user.platform !== 'google' && (
          <TabPane tab='Change Password' key='2'>
            <ChangePassword />
          </TabPane>
        )}

        <TabPane tab='My KYC Application' key='3'>
          <MyKycApplication />
        </TabPane>
      </Tabs>
    </div>
  );
}

export default MyProfile;
