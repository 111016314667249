import React from 'react';

function CaptchaFooter({ mt }) {
  return (
    <div className={`text-gray-400 text-center mt-${mt} mb-4`}>
      This site is protected by reCAPTCHA and the Google
      {' '}
      <a className='text-blue-400 hover:text-blue-500' href='https://policies.google.com/privacy'>Privacy Policy</a>
      {' '}
      and
      {' '}
      <a className='text-blue-400 hover:text-blue-500' href='https://policies.google.com/terms'>Terms of Service</a>
      {' '}
      apply.
    </div>
  );
}

export default CaptchaFooter;
