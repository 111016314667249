/* eslint-disable no-underscore-dangle */
/* eslint-disable no-unsafe-optional-chaining */
import {
  CheckCircleOutlined, CopyOutlined, InfoCircleFilled, LoadingOutlined, NumberOutlined, QrcodeOutlined, WalletOutlined
} from '@ant-design/icons';
import {
  Button, Divider, Form, Input, InputNumber, message, Modal, Result, Segmented, Skeleton, Tag, Tooltip, Typography
} from 'antd';
import { ethers } from 'ethers';
import moment from 'moment';
import React, { useState } from 'react';
import QRCode from 'react-qr-code';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import eventMsg from '../../../data/event-message.json';
import useFetchData from '../../../hooks/useFetchData';
import isEmptyObject from '../../../lib/isEmptyObject';
import { getSessionToken } from '../../../utils/helperAuthentication';
import ga4EventAction from '../../../utils/helperGAEventAction';
import notificationWithIcon from '../../../utils/popupNotification';
import CurrencySegmentedLabel from './CurrencySegmentedLabel';
import DeclaredIcoStageDetails from './DeclaredIcoStageDetails';

const { Title, Text } = Typography;

function MyTokenPurchase({ stageInfo }) {
  const navigate = useNavigate();
  const token = getSessionToken();
  const [currencyId, setCurrencyID] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [initiateLoading, setInitiateLoading] = useState(false);
  const [tokenAmount, setTokenAmount] = useState(stageInfo.min_purchase);
  const [purchaseConfirmLoading, setPurchaseConfirmLoading] = useState(false);
  const [purchaseInitiateData, setPurchaseInitiateData] = useState({});
  const [paymentInitiate, setPaymentInitiate] = useState(false);

  const { walletAddress } = useSelector((walletState) => walletState.wallet);

  const [loading, error, response] = useFetchData(`${process.env.REACT_APP_API_BASE_URL}/api/v1/get-wallet-list/${stageInfo.token_id}`);

  // functions handle token purchase initiate
  const handleIntinePurchase = (values) => {
    // google analytics event handler
    ga4EventAction('link', 'Initiate Token Purchase', 'User Initiate Token Purchase');

    if (moment() >= moment(stageInfo.end_date)) {
      message.error(eventMsg.user.tokenPurchase.purchaseDateOverErrorMessage);
    } else if (moment() <= moment(stageInfo.start_date)) {
      message.error(eventMsg.user.tokenPurchase.purchaseNotStartErrorMessage);
    } else if (stageInfo?.token_sold > stageInfo.token_sale_amount) {
      message.error(eventMsg.user.tokenPurchase.allTokenSoldErrorMessage);
    } else if (stageInfo?.token_sold + tokenAmount > stageInfo.token_sale_amount) {
      message.error(eventMsg.user.tokenPurchase.requestedTokenQuantityCrossTotalTokenQuantity);
    } else {
      setInitiateLoading(true);
      const myHeaders = new Headers();
      myHeaders.append('Authorization', `Bearer ${token}`);
      myHeaders.append('Content-Type', 'application/json');

      const raw = JSON.stringify({
        token_id: stageInfo.token_id,
        stage_id: stageInfo.id,
        wallet_id: response?.result[currencyId]?.id,
        token_quantity: tokenAmount,
        send_from_address: values.userWalletAddress,
        token_receive_address: values.receiveWalletAddress,
        currency_rate: response?.result[currencyId]?.currency_rate
      });

      const requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
      };

      fetch(`${process.env.REACT_APP_API_BASE_URL}/api/v1/initiate-purchase`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
          if (result.result_code === 0) {
            setPurchaseInitiateData({
              id: result.result.id,
              price: result.result.total_price
            });

            setInitiateLoading(false);
            setPaymentInitiate(true);
            message.success(eventMsg.user.tokenPurchase.purchaseInitiateSuccess);
          } else {
            message.error(result.error.message);
            setInitiateLoading(false);
          }
        })
        .catch((err) => {
          message.error(err.message);
          setInitiateLoading(false);
        });
    }
  };

  // function to handle token purchase confirmation
  const handlePurchaseConfirmation = (values) => {
    // google analytics event handler
    ga4EventAction('link', 'Confirm Purchase', 'User Purchase Token Confirm');

    setPurchaseConfirmLoading(true);
    const myHeaders = new Headers();
    myHeaders.append('Authorization', `Bearer ${token}`);
    myHeaders.append('Content-Type', 'application/json');

    const raw = JSON.stringify({
      id: purchaseInitiateData.id,
      trx_id: values.tranxId,
      token_id: stageInfo.token_id
    });

    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    fetch(`${process.env.REACT_APP_API_BASE_URL}/api/v1/confirm-purchase`, requestOptions)
      .then((res) => res.json())
      .then((result) => {
        if (result.result_code === 0) {
          message.success(eventMsg.user.tokenPurchase.purchaseConfirmSuccess);
          setPurchaseConfirmLoading(false);
          navigate('/user/my-token');
        } else {
          message.error(result.error.message);
          setPurchaseConfirmLoading(false);
        }
      })
      .catch((err) => {
        message.error(err.message);
        setPurchaseConfirmLoading(false);
      });
  };

  // function to pay with metamask wallet and confirm purchase token
  const handlePayNowAndConfirmPurchase = () => {
    // google analytics event handler
    ga4EventAction('link', 'Pay Now & Confirm Purchase', 'User Purchase Token Confirm');

    if (walletAddress) {
      setPurchaseConfirmLoading(true);

      window.ethereum
        .request({
          method: 'eth_sendTransaction',
          params: [{
            from: walletAddress,
            to: response?.result[currencyId]?.wallet_address,
            value: ethers?.utils?.parseUnits(purchaseInitiateData.price)._hex
            // gasPrice: "0x9184e72a000",
            // gas: "0x76c0",
            // data: hex
          }]
        })
        .then((trxId) => {
          // token purchase confirmation API call
          const myHeaders = new Headers();
          myHeaders.append('Authorization', `Bearer ${token}`);
          myHeaders.append('Content-Type', 'application/json');

          const raw = JSON.stringify({
            id: purchaseInitiateData.id,
            trx_id: trxId,
            token_id: stageInfo.token_id
          });

          const requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
          };

          fetch(`${process.env.REACT_APP_API_BASE_URL}/api/v1/confirm-purchase`, requestOptions)
            .then((res) => res.json())
            .then((result) => {
              if (result.result_code === 0) {
                message.success(eventMsg.user.tokenPurchase.purchaseConfirmSuccess);
                setPurchaseConfirmLoading(false);
                navigate('/user/my-token');
              } else {
                message.error(result.error.message);
                setPurchaseConfirmLoading(false);
              }
            })
            .catch((err) => {
              message.error(err.message);
              setPurchaseConfirmLoading(false);
            });
        })
        .catch((err) => {
          notificationWithIcon('error', 'Token Purchase Error', err.message);
          setPurchaseConfirmLoading(false);
        });
    } else {
      notificationWithIcon('error', eventMsg.app.walletConnectionError.title, eventMsg.app.walletConnectionError.message);
    }
  };

  return (
    <>
      <Skeleton
        className='bg-backgroundColorWhite min-h-[68vh] w-full xl:w-[80%] mx-auto px-6 py-6 shadow-md rounded-sm flex flex-col'
        paragraph={{ rows: 10 }}
        loading={loading}
        active
        avatar
      >
        {error ? (
          <Result
            status='error'
            title='Fetching Error'
            subTitle={error}
          />
        ) : (
          <div
            className='bg-backgroundColorWhite min-h-[68vh] w-full xl:w-[80%] mx-auto px-6 py-6 shadow-md rounded-sm flex flex-col'
          >
            {/* DECLARED ICO STAGE DETAIL'S PART */}
            <DeclaredIcoStageDetails stageInfo={stageInfo} />

            {/* HEADING PART */}
            <Title className='uppercase mt-5' level={5}>
              Chose Currency to Purchase Token:
            </Title>
            <Text>
              You can buy out token using the below currency choices to become part of out project.
            </Text>

            {/* CURRENCY CHOSE PART */}
            <Segmented
              className='mt-5'
              onChange={(value) => setCurrencyID(value)}
              options={response && response.result.map((data, index) => ({
                label: (<CurrencySegmentedLabel
                  name={data.currency_code}
                  price={data.currency_rate !== 0 ? ((parseInt(tokenAmount, 10) * parseFloat(stageInfo.token_price)) / data.currency_rate) : 0}
                  currencyRate={data.currency_rate}
                />),
                value: index
              }))}
              block
            />

            {/* CONTRIBUTION PART PART */}
            <Title className='uppercase mt-5' level={5}>
              AMOUNT OF CONTRIBUTE:
            </Title>
            <Text>
              Enter the amount you would like to contribute in order to calculate the amount of tokens you will receive. The calculator below helps to convert the required quantity of tokens into the amount of your selected currency.
            </Text>

            <div className='mt-5 flex flex-row items-center justify-start space-x-4'>
              <InputNumber
                className='w-[300px] space-x-1'
                min={stageInfo.min_purchase}
                max={stageInfo.max_purchase}
                defaultValue={stageInfo.min_purchase}
                onChange={(value) => setTokenAmount(value)}
                addonBefore={<NumberOutlined />}
                disabled={paymentInitiate}
                type='number'
                size='large'
              />

              <Title className='uppercase mt-1' level={5}>
                {`≈ $ ${stageInfo.token_price * tokenAmount} (${tokenAmount || 0} x ${stageInfo.token_price})`}
              </Title>
            </div>

            {/* MIN MAX PURCHASE ALERT PART */}
            {(tokenAmount < stageInfo.min_purchase || tokenAmount > stageInfo.max_purchase) && (
              <div className='flex flex-row items-center justify-start my-2 space-x-4'>
                <InfoCircleFilled className='text-red-500' />
                <Text className='text-start text-red-500 font-bold'>
                  {`Minimum ${stageInfo.min_purchase} Token and Maximum ${stageInfo.max_purchase} Tokens can be purchased!`}
                </Text>
              </div>
            )}

            {/* TOKEN PURCHASE PAYMENT INITIATE */}
            <Title className='uppercase mt-5' level={5}>
              PAYMENT INITIATE:
            </Title>
            <Text>
              Your contribution will be calculated based on currency rate at the amount when your transaction is confirmed.
            </Text>

            {/* TOKEN PURCHASE PAYMENT INITIATE FORM */}
            <Form
              className='mt-5'
              layout='vertical'
              onFinish={handleIntinePurchase}
            >
              <Form.Item label='Transaction Wallet Address'>
                <Input.Group compact>
                  <Input
                    className='!w-[325px] sm:!w-[400px]'
                    value={response?.result[currencyId]?.wallet_address}
                    size='large'
                    disabled
                  />

                  <Tooltip title='Copy Wallet Address in Clipboard'>
                    <Button
                      size='large'
                      icon={<CopyOutlined />}
                      onClick={async () => {
                        await navigator.clipboard.writeText(response?.result[currencyId]?.wallet_address);
                      }}
                    />
                  </Tooltip>

                  <Tooltip title='Show wallet address QrCode'>
                    <Button
                      size='large'
                      icon={<QrcodeOutlined />}
                      onClick={() => setIsModalOpen(true)}
                    />
                  </Tooltip>
                </Input.Group>
              </Form.Item>

              <Form.Item
                label='Your Wallet Address'
                name='userWalletAddress'
                rules={[{
                  required: true,
                  message: 'Please input Your Wallet Address!'
                }]}
              >
                <Input
                  className='w-[400px]'
                  placeholder='Type here your wallet address'
                  disabled={paymentInitiate}
                  size='large'
                  type='text'
                />
              </Form.Item>

              <Form.Item
                label='Token Received Wallet Address'
                name='receiveWalletAddress'
                rules={[{
                  required: true,
                  message: 'Please input Token Received Wallet Address!'
                }]}
              >
                <Input
                  className='w-[400px]'
                  placeholder='Type here token received wallet address'
                  disabled={paymentInitiate}
                  size='large'
                  type='text'
                />
              </Form.Item>

              <Form.Item>
                <Button
                  className={`mt-2 w-[200px] ${initiateLoading || 'inline-flex items-center'}`}
                  disabled={initiateLoading || paymentInitiate}
                  icon={initiateLoading || <WalletOutlined />}
                  htmlType='submit'
                  type='primary'
                  size='large'
                >
                  {initiateLoading ? <LoadingOutlined /> : 'Payment Initiate'}
                </Button>
              </Form.Item>
            </Form>

            {!isEmptyObject(purchaseInitiateData) && currencyId !== 0 && (
              <>
                {/* TOKEN PURCHASE CONFIRM */}
                <Title className='uppercase mt-5' level={5}>
                  TOKEN PURCHASE CONFIRM:
                </Title>

                <Title className='mt-5' level={5}>
                  Total Price =
                  {' '}
                  <Tooltip title='Click to Copy Price in Clipboard'>
                    <Tag
                      className='py-1 text-lg cursor-pointer'
                      color='success'
                      onClick={async () => {
                        await navigator.clipboard.writeText(purchaseInitiateData.price);
                      }}
                    >
                      {purchaseInitiateData.price}
                    </Tag>
                  </Tooltip>
                  {' '}
                  ETH
                </Title>

                {/* TOKEN PURCHASE PAYMENT CONFIRM FORM */}
                <Form
                  className='mt-5'
                  layout='vertical'
                  onFinish={handlePurchaseConfirmation}
                >
                  <Form.Item
                    label='Tranx Id'
                    name='tranxId'
                    rules={[{
                      required: true,
                      message: 'Please input Your Tranx Id!'
                    }]}
                  >
                    <Input
                      className='w-[400px]'
                      placeholder='Type here your Tranx Id'
                      size='large'
                      type='text'
                    />
                  </Form.Item>

                  <Form.Item>
                    <Button
                      className={`mt-2 w-[200px] ${purchaseConfirmLoading || 'inline-flex items-center'}`}
                      icon={purchaseConfirmLoading || <CheckCircleOutlined />}
                      disabled={purchaseConfirmLoading}
                      htmlType='submit'
                      type='primary'
                      size='large'
                    >
                      {purchaseConfirmLoading ? <LoadingOutlined /> : 'Confirm Purchase'}
                    </Button>
                  </Form.Item>
                </Form>
              </>
            )}

            {/* IF CHOSE ETH TO PAY WITH METAMASK WALLET */}
            {!isEmptyObject(purchaseInitiateData) && currencyId === 0 && (
              <Button
                className={`mt-2 w-[265px] ${purchaseConfirmLoading || 'inline-flex items-center'}`}
                icon={purchaseConfirmLoading || <CheckCircleOutlined />}
                onClick={handlePayNowAndConfirmPurchase}
                disabled={purchaseConfirmLoading}
                htmlType='submit'
                type='primary'
                size='large'
              >
                {purchaseConfirmLoading ? <LoadingOutlined /> : 'Pay Now & Confirm Purchase'}
              </Button>
            )}

            <Divider />
            <div className='flex flex-row items-baseline justify-start my-2 space-x-4'>
              <InfoCircleFilled />
              <Text className='text-start'>
                Tokens will appear in your account after payment successfully made and approved by our team. Please note that ERC-20 Token will be distributed after the token sales end date.
              </Text>
            </div>

            <div className='flex flex-row items-baseline justify-start my-2 space-x-4'>
              <InfoCircleFilled />
              <Text className='text-start'>
                User have to pay with in 2 hours. Unless initiated data will be invalid.
              </Text>
            </div>
          </div>
        )}
      </Skeleton>

      {/* QR-CODE POPUP MODAL */}
      <Modal
        className='!w-[400px]'
        title={`${response?.result[currencyId]?.currency_name} Wallet Address`.toUpperCase()}
        open={isModalOpen}
        visible={isModalOpen}
        closable={false}
        footer={[
          <Button
            key='back'
            onClick={() => setIsModalOpen(false)}
          >
            Closed
          </Button>
        ]}
      >
        <QRCode
          value={response?.result[currencyId]?.wallet_address}
          size={350}
        />
      </Modal>
    </>
  );
}

export default MyTokenPurchase;
