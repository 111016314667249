import { Select, Tabs } from 'antd';
import React, { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setWalletNetwork } from '../../../redux/actions/walletAction';
import ga4EventAction from '../../../utils/helperGAEventAction';
import CreateTokenForm from '../../app/token/CreateTokenForm';
import TokenDetails from '../token/TokenDetails';
import TokenList from '../token/TokenList';

const { TabPane } = Tabs;
const { Option } = Select;

function Tokens() {
  window.document.title = 'ERC-20 Token • Tokens';
  const newTabIndex = useRef(0);

  const { walletNetwork } = useSelector((state) => state.wallet);
  const { chainListLoading, chainListError, chainListData } = useSelector((state) => state.chain);
  const dispatch = useDispatch();

  // tab click to change active key
  const onChange = (key) => {
    setActiveKey(key);
  };

  // function to add new tab
  const add = (id) => {
    // google analytics event handler
    ga4EventAction('link', 'View Details', 'Admin Created Token View Details');

    const newActiveKey = `newTab${newTabIndex.current + 1}`;
    setPanes([
      ...panes, {
        title: 'Token Details',
        content: <TokenDetails id={id} />,
        key: newActiveKey
      }
    ]);
    setActiveKey(newActiveKey);
  };

  // dynamic tab default pane
  const defaultPanes = Array.from({ length: 1 }).map((_, index) => {
    const x = String(index + 1);

    return {
      key: x,
      title: 'Token List',
      content: <TokenList add={add} />
    };
  });

  // tab initial states
  const [activeKey, setActiveKey] = useState(defaultPanes[0].key);
  const [panes, setPanes] = useState(defaultPanes);

  // function to removed tab
  const remove = (targetKey) => {
    const targetIndex = panes.findIndex((pane) => pane.key === targetKey);
    const newPanes = panes.filter((pane) => pane.key !== targetKey);

    if (newPanes.length && targetKey === activeKey) {
      const { key } = newPanes[targetIndex === newPanes.length ? targetIndex - 1 : targetIndex];
      setActiveKey(key);
    }

    setPanes(newPanes);
  };

  // function to tabs actions controller
  const onEdit = (targetKey, action) => {
    if (action === 'add') {
      add();
    } else {
      remove(targetKey);
    }
  };

  return (
    <div className='bg-backgroundColorWhite min-h-[68vh] shadow-md rounded-sm pt-0.5'>
      <Tabs
        className='m-2 md:m-4'
        type='editable-card'
        onEdit={onEdit}
        onChange={onChange}
        activeKey={activeKey}
        size='middle'
        hideAdd
        tabBarExtraContent={(
          <Select
            style={{ width: 180 }}
            value={walletNetwork}
            placeholder='-- wallet network --'
            loading={chainListLoading}
            onChange={(value) => {
              dispatch(setWalletNetwork(value));
            }}
            size='middle'
            disabled
          >
            {chainListError === null && chainListData && chainListData.map((data) => (
              <Option key={data.id} value={data.chain_name}>{data.network_name}</Option>
            ))}
          </Select>
        )}
      >
        {panes.map((pane) => (
          <TabPane key={pane.key} tab={pane.title} closable={pane.key !== '1'}>
            {pane.content}
          </TabPane>
        ))}

        <TabPane key='100' tab='⊞ Create Token' closable={false}>
          <CreateTokenForm />
        </TabPane>
      </Tabs>
    </div>
  );
}

export default Tokens;
