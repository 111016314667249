import { DownloadOutlined } from '@ant-design/icons';
import {
  Button, Result, Skeleton, Tooltip
} from 'antd';
import React from 'react';
import useFetchData from '../../../hooks/useFetchData';
import downloadString from '../../../lib/downloadString';
import convertToInternationalCurrencySystem from '../../../lib/internationalCurrencyConvert';
import truncateString from '../../../lib/truncateString';
import { tokenConfigResponseAsStatus } from '../../../utils/helperShared';
import testnetAddress from '../../../utils/helperTestnetAddress';

function TokenDetails({ id }) {
  const [loading, error, response] = useFetchData(`${process.env.REACT_APP_API_BASE_URL}/api/v1/deployed-token-detail/${id}`);

  return (
    <Skeleton loading={loading} active paragraph={{ rows: 10 }}>
      {error ? (
        <Result
          status='error'
          title='Fetching Error'
          subTitle={error}
        />
      ) : (
        <div className='bg-backgroundColorWhite min-h-[68vh]'>
          <h1 className='text-2xl text-center font-bold pt-5'>Created Token Details</h1>

          {response && (
            <div className='container flex justify-center mx-auto'>
              <div className='flex flex-col'>
                <div className='w-full'>
                  <div className='border-b border-gray-200 shadow mt-5 mb-10'>
                    <table className='divide-y divide-gray-300'>
                      <thead className='bg-gray-50'>
                        <tr>
                          <th className='input-field-table-th'>FIELDS</th>
                          <th className='values-table-th'>VALUES</th>
                        </tr>
                      </thead>

                      <tbody className='bg-white divide-y divide-gray-300'>
                        {/* TOKEN NAME */}
                        <tr className='whitespace-nowrap'>
                          <td className='px-6 py-4'>
                            <div className='text-md text-gray-800'>Token Name</div>
                          </td>
                          <td className='px-6 py-4'>
                            <div className='text-md text-gray-600'>
                              {response.result.detail.token_name}
                            </div>
                          </td>
                        </tr>

                        {/* SYMBOLS */}
                        <tr className='whitespace-nowrap'>
                          <td className='px-6 py-4'>
                            <div className='text-md text-gray-800'>Symbols</div>
                          </td>
                          <td className='px-6 py-4'>
                            <div className='text-md text-gray-600'>
                              {response.result.detail.token_symbol}
                            </div>
                          </td>
                        </tr>

                        {/* INITIAL SUPPLY */}
                        <tr className='whitespace-nowrap'>
                          <td className='px-6 py-4'>
                            <div className='text-md text-gray-800'>Total Supply</div>
                          </td>
                          <td className='px-6 py-4'>
                            <div className='text-md text-gray-600'>
                              {convertToInternationalCurrencySystem(response.result.detail.total_supply)}
                            </div>
                          </td>
                        </tr>

                        {/* DECIMALS */}
                        <tr className='whitespace-nowrap'>
                          <td className='px-6 py-4'>
                            <div className='text-md text-gray-800'>Decimals(1-18)</div>
                          </td>
                          <td className='px-6 py-4'>
                            <div className='text-md text-gray-600'>
                              {response.result.detail.token_decimals}
                            </div>
                          </td>
                        </tr>

                        {/* SUPPLY TYPE */}
                        <tr className='whitespace-nowrap'>
                          <td className='px-6 py-4'>
                            <div className='text-md text-gray-800'>Supply Type</div>
                          </td>
                          <td className='px-6 py-4'>
                            <div className='text-md text-gray-600 capitalize'>
                              {response.result.detail.supply_type}
                            </div>
                          </td>
                        </tr>

                        {/* CAN BURN */}
                        <tr className='whitespace-nowrap'>
                          <td className='px-6 py-4'>
                            <div className='text-md text-gray-800'>Can Burn</div>
                          </td>
                          <td className='px-6 py-4'>
                            <div className='text-md text-gray-600 capitalize'>
                              {tokenConfigResponseAsStatus(response.result.detail.burning_token)}
                            </div>
                          </td>
                        </tr>

                        {/* CAN MINT */}
                        <tr className='whitespace-nowrap'>
                          <td className='px-6 py-4'>
                            <div className='text-md text-gray-800'>Can Freeze</div>
                          </td>
                          <td className='px-6 py-4'>
                            <div className='text-md text-gray-600 capitalize'>
                              {tokenConfigResponseAsStatus(response.result.detail.freeze)}
                            </div>
                          </td>
                        </tr>

                        {/* OWNER ADDRESS */}
                        <tr className='whitespace-nowrap'>
                          <td className='px-6 py-4'>
                            <div className='text-md text-gray-800'>Owner Address</div>
                          </td>
                          <td className='px-6 py-4'>
                            <div className='text-md text-gray-600'>
                              <Tooltip title={response.result.detail.owner_address}>
                                {truncateString(response.result.detail.owner_address, 28, '.....')}
                              </Tooltip>
                            </div>
                          </td>
                        </tr>

                        {/* NETWORK */}
                        <tr className='whitespace-nowrap'>
                          <td className='px-6 py-4'>
                            <div className='text-md text-gray-800'>Network</div>
                          </td>
                          <td className='px-6 py-4'>
                            <div className='text-md text-gray-600 capitalize'>
                              {response.result.detail.network}
                            </div>
                          </td>
                        </tr>

                        {/* DOWNLOAD - ABI, BYTECODE & SOURCE_CODE */}
                        <tr className='whitespace-normal lg:whitespace-nowrap'>
                          <td className='px-6 py-4'>
                            <div className='text-md text-gray-800'>Download</div>
                          </td>
                          <td className='px-6 py-4'>
                            <div className='items-start space-y-2'>
                              {/* ABI DOWNLOAD BUTTON */}
                              <Button
                                className='inline-flex items-center mr-2'
                                icon={<DownloadOutlined />}
                                shape='default'
                                type='dashed'
                                size='middle'
                                onClick={() => {
                                  downloadString(response.result.abi, 'abi', 'json');
                                }}
                              >
                                ABI
                              </Button>

                              {/* BYTECODE DOWNLOAD BUTTON */}
                              <Button
                                className='inline-flex items-center mr-2'
                                icon={<DownloadOutlined />}
                                shape='default'
                                type='dashed'
                                size='middle'
                                onClick={() => {
                                  downloadString(response.result.bytecode, 'bytecode', 'txt');
                                }}
                              >
                                BYTECODE
                              </Button>

                              {/* SOURCE CODE DOWNLOAD BUTTON */}
                              <Button
                                className='inline-flex items-center'
                                icon={<DownloadOutlined />}
                                shape='default'
                                type='dashed'
                                size='middle'
                                onClick={() => {
                                  downloadString(response.result.source_code, 'source_code', 'sol');
                                }}
                              >
                                SOURCE CODE
                              </Button>
                            </div>
                          </td>
                        </tr>

                        {/* TRANSACTION HASH */}
                        <tr className='whitespace-nowrap'>
                          <td className='px-6 py-4'>
                            <div className='text-md text-gray-800'>Transaction Hash</div>
                          </td>
                          <td className='px-6 py-4'>
                            <Button
                              type='link'
                              size='middle'
                              target='_blank'
                              href={testnetAddress(response.result.detail.network, response.result.detail.trxId)}
                            >
                              {truncateString(response.result.detail.trxId, 27, '.....')}
                            </Button>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </Skeleton>
  );
}

export default TokenDetails;
