import { ExclamationCircleOutlined, SearchOutlined } from '@ant-design/icons';
import {
  Button, Empty, Input, message, Modal, Pagination, Result, Select, Skeleton, Tag, Tooltip, Typography
} from 'antd';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import eventMsg from '../../../data/event-message.json';
import useFetchData from '../../../hooks/useFetchData';
import { getSessionToken, getSessionUser } from '../../../utils/helperAuthentication';
import ga4EventAction from '../../../utils/helperGAEventAction';
import { responseAsColor, responseAsColorAndStatus } from '../../../utils/helperShared';
import TokenDetails from '../tokens/TokenDetails';

const { Title } = Typography;
const { Option } = Select;
const { confirm } = Modal;

function IcoList() {
  window.document.title = 'ERC-20 Token • Active ICO List';
  const [fetchAgain, setFetchAgain] = useState(false);
  const [searchKeyword, setSearchKeyword] = useState('');
  const [tokenDetailsModal, setTokenDetailsModal] = useState(false);
  const [tokenId, setTokenId] = useState(null);
  const [sortType, setSortType] = useState('aesc');
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const navigate = useNavigate();
  const user = getSessionUser();
  const token = getSessionToken();

  // declared ico available stage list API's data
  const [loading, error, response] = useFetchData(`${process.env.REACT_APP_API_BASE_URL}/api/v1/get-ico-stage-list?page=${page}&search=${searchKeyword}&limit=${limit}&sort=${sortType}`, fetchAgain);

  // function to handle purchase token
  const handlePurchaseToken = (purchaseTokenId, data) => {
    // google analytics event handler
    ga4EventAction('link', 'Token Purchase', 'User Token Purchase');

    if (!user.kyc_id) {
      message.info(eventMsg.user.tokenPurchase.kycSaveMessage);

      // re-direct to save kyc application tab
      navigate('/user/kyc-application', {
        state: {
          tokenId: purchaseTokenId,
          tokenName: `${data.token_name} (${data.token_symbol})`
        }
      });
    } else if (!data.kyc_status) {
      confirm({
        title: eventMsg.user.tokenPurchase.kycSubmitModal.title,
        icon: <ExclamationCircleOutlined />,
        content: eventMsg.user.tokenPurchase.kycSubmitModal.message,
        okType: 'danger',
        cancelText: 'No',
        okText: 'Submit',

        onOk() {
          return new Promise((resolve, reject) => {
            const myHeaders = new Headers();
            myHeaders.append('Authorization', `Bearer ${token}`);
            myHeaders.append('Content-Type', 'application/json');

            const raw = JSON.stringify({
              id: user.kyc_id,
              token_id: purchaseTokenId
            });

            const requestOptions = {
              method: 'POST',
              headers: myHeaders,
              body: raw,
              redirect: 'follow'
            };

            fetch(`${process.env.REACT_APP_API_BASE_URL}/api/v1/request-kyc-approval`, requestOptions)
              .then((res) => res.json())
              .then((result) => {
                if (result.result_code === 0) {
                  message.success(eventMsg.user.tokenPurchase.kycSubmitSuccessMessage);
                  setFetchAgain(!fetchAgain);
                  resolve();
                } else {
                  reject(result.error.message);
                }
              })
              .catch((err) => {
                reject(err.message);
              });
          }).catch((err) => {
            message.error(err);
          });
        }
      });
    } else if (data.kyc_status === 'pending') {
      message.warning(eventMsg.user.tokenPurchase.kycPendingMessage);
    } else if (data.kyc_status === 'rejected') {
      message.error(eventMsg.user.tokenPurchase.kycRejectedMessage);
    } else if (data.kyc_status === 'approved' || data.kyc_status === 'approve') {
      // user redirect toke token purchase page
      navigate('/user/token-purchase', {
        state: { stageInfo: data }
      });
    } else {
      message.error('Ops! Something went wrong.');
    }
  };

  // reset paginate page number
  useEffect(() => {
    setPage(1);
  }, [searchKeyword, limit]);

  return (
    <>
      <div className='bg-backgroundColorWhite min-h-[68vh] shadow-md rounded-sm p-4'>
        <Skeleton loading={loading} active paragraph={{ rows: 10 }}>
          {error ? (
            <Result
              status='error'
              title='Fetching Error'
              subTitle={error}
            />
          ) : (
            <div>
              <Title className='text-start' level={4}>Active ICO List</Title>

              {/* SEARCH BOX & FILTERS */}
              <div className='search-and-filter-box'>
                <Input
                  className='space-x-4'
                  placeholder='Type here to Search active ico'
                  onChange={(e) => setSearchKeyword(e.target.value)}
                  prefix={<SearchOutlined />}
                  size='large'
                />

                <Select
                  className='w-full sm:w-[240px]'
                  placeholder='-- show rows --'
                  onChange={(value) => setLimit(value)}
                  defaultValue='10'
                  size='large'
                >
                  <Option value='10'>10 Rows</Option>
                  <Option value='20'>20 Rows</Option>
                  <Option value='30'>30 Rows</Option>
                  <Option value='40'>40 Rows</Option>
                  <Option value='50'>50 Rows</Option>
                </Select>

                <Select
                  className='w-full sm:w-[240px]'
                  placeholder='-- select type to sort --'
                  onChange={(value) => setSortType(value)}
                  defaultValue='aesc'
                  size='large'
                >
                  <Option value='aesc'>Sort By Ascending</Option>
                  <Option value='desc'>Sort By Descending</Option>
                </Select>
              </div>

              {/* TRANSACTIONS LIST */}
              {response && response.result.rows.length === 0 ? (
                <Empty
                  className='mt-10'
                  description={(
                    <span>Sorry! Available declared ICO data was not found.</span>
              )}
                />
              ) : (
                <div className='w-full shadow bg-white rounded my-3'>
                  <div className='border-gray-200 w-full rounded bg-white overflow-x-auto'>
                    <table className='w-full leading-normal '>
                      {/* TABLE HEADER */}
                      <thead className='table-thead'>
                        <tr className='border-b border-gray-200'>
                          <th scope='col' className='table-thead-th'>
                            TOKEN NAME (SYMBOL)
                          </th>
                          <th scope='col' className='table-thead-th'>
                            TOKEN PRICE
                          </th>
                          <th scope='col' className='table-thead-th'>
                            BONUS
                          </th>
                          <th scope='col' className='table-thead-th'>
                            PURCHASE LIMIT (MIN - MAX)
                          </th>
                          <th scope='col' className='table-thead-th'>
                            DURATION
                          </th>
                          <th scope='col' className='table-thead-th'>
                            STATUS
                          </th>
                          <th scope='col' className='table-thead-th'>
                            KYC STATUS
                          </th>
                          <th scope='col' className='table-thead-th'>
                            ACTIONS
                          </th>
                        </tr>
                      </thead>

                      {/* DATA MAPPING ON TABLE BODY */}
                      {response && response.result.rows.map((data) => (
                        <tbody key={data.id}>
                          <tr className='table-body-tr'>
                            <td className='table-body-td'>
                              <Tooltip title='Click to view Token details'>
                                <Button
                                  className='p-0'
                                  type='link'
                                  onClick={() => {
                                    setTokenDetailsModal(true);
                                    setTokenId(data.token_id);
                                  }}
                                >
                                  {`${data.token_name} (${data.token_symbol})`}
                                </Button>
                              </Tooltip>
                            </td>
                            <td className='table-body-td'>
                              {`$ ${data.token_price} / 1 ${data.token_symbol}`}
                            </td>
                            <td className='table-body-td'>
                              {data.bonus ? `${data.bonus} %` : 'N/A'}
                            </td>
                            <td className='table-body-td'>
                              {data.min_purchase && data.max_purchase ? `${data.min_purchase} - ${data.max_purchase}` : '-'}
                            </td>
                            <td className='table-body-td !lowercase'>
                              {`${data.start_date.split(' ')[0]} to ${data.end_date.split(' ')[0]}`}
                            </td>
                            <td className='table-body-td'>
                              <Tag
                                className='px-2 py-1 text-sm capitalize'
                                color={responseAsColorAndStatus(data.status).color}
                              >
                                {responseAsColorAndStatus(data.status).status}
                              </Tag>
                            </td>
                            <td className='table-body-td'>
                              <Tag
                                className='px-2 py-1 text-sm capitalize'
                                color={data.kyc_status ? responseAsColor(data.kyc_status) : 'error'}
                              >
                                {data.kyc_status ? data.kyc_status : 'N/A'}
                              </Tag>
                            </td>
                            <td className='table-body-td space-x-2'>
                              <Button
                                className='w-[160px]'
                                onClick={() => handlePurchaseToken(data.token_id, data)}
                                disabled={data.purchase_status === 'applied'}
                                type='primary'
                              >
                                {data.purchase_status === 'applied' ? 'Already Purchased' : 'Purchase Token'}
                              </Button>
                            </td>
                          </tr>
                        </tbody>
                      ))}
                    </table>
                  </div>
                </div>
              )}

              {/* RUNNING ICO STAGE LIST PAGINATION */}
              {response && response.result.total_page > 1 && (
              <Pagination
                className='flex items-center justify-center m-4'
                defaultCurrent={1}
                total={response.result.total_page * 10}
                onChange={(e) => setPage(e)}
              />
              )}
            </div>
          )}
        </Skeleton>
      </div>

      {/* UPDATE ROLE AGAINST USER MODAL */}
      <Modal
        title='Token Details'
        open={tokenDetailsModal}
        visible={tokenDetailsModal}
        onCancel={() => {
          setTokenDetailsModal(false);
        }}
        footer={[
          <Button
            key='back'
            onClick={() => { setTokenDetailsModal(false); }}
          >
            Closed
          </Button>
        ]}
        width={1000}
      >
        {tokenId && (<TokenDetails id={tokenId} />)}
      </Modal>
    </>
  );
}

export default IcoList;
