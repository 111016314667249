import {
  Card, Statistic, Typography
} from 'antd';
import React from 'react';

const { Title } = Typography;

function InvestorCard({ investor }) {
  return (
    <Card className='dashboard-card h-56 px-2 sm:px-4' hoverable>
      <Title className='inline-flex items-center' level={4}>
        Investor Information
      </Title>

      <div className='flex flex-row justify-start items-center space-x-8 mt-4'>
        <Statistic
          title='Total Investors'
          value={investor.investors}
          valueStyle={{ color: '#0f172a' }}
          prefix='⇆'
        />

        <Statistic
          title='Fund Status'
          value={investor.fundStatus ? investor.fundStatus : 0}
          valueStyle={{ color: '#9333ea' }}
          precision={2}
          prefix='↝'
        />
      </div>
    </Card>
  );
}

export default InvestorCard;
