import { combineReducers } from 'redux';
import appReducer from './appReducer';
import chainReducer from './chainReducer';
import tokenReducer from './tokenReducer';
import walletReducer from './walletReducer';

const rootReducer = combineReducers({
  token: tokenReducer,
  wallet: walletReducer,
  chain: chainReducer,
  app: appReducer
});

export default rootReducer;
