import { Result, Skeleton } from 'antd';
import React from 'react';
import useFetchData from '../../../hooks/useFetchData';
import AccountStatusCard from '../card/AccountStatusCard';
import IcoCard from '../card/IcoCard';
import ParticipantIcoCard from '../card/ParticipantIcoCard';

function MyDashboard() {
  window.document.title = 'ERC-20 Token • User Dashboard';

  // fetching participant dashboard API data
  const [loading, error, response] = useFetchData(`${process.env.REACT_APP_API_BASE_URL}/api/v1/participant-dashboard`);

  return (
    <Skeleton loading={loading} active paragraph={{ rows: 12 }}>
      {error ? (
        <Result
          status='error'
          title='Fetching Error'
          subTitle={error}
        />
      ) : (
        <div className='min-h-[68vh] rounded-sm'>
          <div className='flex flex-wrap -mx-2 mb-8'>
            <div className='w-full sm:w-1/2 px-2 mb-4'>
              {/* ACCOUNT STATUS CARD */}
              <AccountStatusCard />
            </div>

            <div className='w-full sm:w-1/2 px-2 mb-4'>
              {/* ICO INFO CARD */}
              <IcoCard appliedIco={response?.result?.applied} />
            </div>

            <div className='w-full px-2'>
              {/* AVAILABLE ICO STAGE LIST CARD */}
              <ParticipantIcoCard />
            </div>
          </div>
        </div>
      )}
    </Skeleton>
  );
}

export default MyDashboard;
