import {
  BlockOutlined, DashboardOutlined, ReconciliationOutlined, SelectOutlined, SettingOutlined, SketchOutlined, TransactionOutlined, UnorderedListOutlined, UsergroupAddOutlined, UserOutlined
} from '@ant-design/icons';
import { Button, Layout, Tabs } from 'antd';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import AdminDashboard from '../../components/admin/tabs/AdminDashboard';
import DeclaredIco from '../../components/admin/tabs/DeclaredIco';
import KycList from '../../components/admin/tabs/KycList';
import MyProfile from '../../components/admin/tabs/MyProfile';
import Participant from '../../components/admin/tabs/Participant';
import Settings from '../../components/admin/tabs/Settings';
import SupportTicket from '../../components/admin/tabs/SupportTicket';
import TokenDistribution from '../../components/admin/tabs/TokenDistribution';
import Tokens from '../../components/admin/tabs/Tokens';
import Transactions from '../../components/admin/tabs/Transactions';
import Footers from '../../components/common/Footers';
import Headers from '../../components/common/Headers';

const { Content } = Layout;
const { TabPane } = Tabs;

function MyAdmin() {
  window.document.title = 'ERC-20 Token • Admin Panel';
  const [activeTabKey, setActiveTabKey] = useState('1');
  const navigate = useNavigate();
  const { tab } = useParams();

  const handleTabChange = (key) => {
    switch (key) {
      case '1': {
        navigate('/admin/dashboard');
        break;
      }
      case '2': {
        navigate('/admin/tokens');
        break;
      }
      case '3': {
        navigate('/admin/transactions');
        break;
      }
      case '4': {
        navigate('/admin/declared-ico');
        break;
      }
      case '5': {
        navigate('/admin/token-distribution');
        break;
      }
      case '6': {
        navigate('/admin/kyc-list');
        break;
      }
      case '7': {
        navigate('/admin/participant');
        break;
      }
      case '8': {
        navigate('/admin/support-ticket');
        break;
      }
      case '9': {
        navigate('/admin/my-profile');
        break;
      }
      case '10': {
        navigate('/admin/settings');
        break;
      }
      default: {
        navigate('/admin/dashboard');
      }
    }
  };

  useEffect(() => {
    switch (activeTabKey) {
      case '1': {
        window.document.title = 'ERC-20 Token • Admin Dashboard';
        break;
      }
      case '2': {
        window.document.title = 'ERC-20 Token • Tokens';
        break;
      }
      case '3': {
        window.document.title = 'ERC-20 Token • Transitions';
        break;
      }
      case '4': {
        window.document.title = 'ERC-20 Token • Declared ICO';
        break;
      }
      case '5': {
        window.document.title = 'ERC-20 Token • Token Distribution';
        break;
      }
      case '6': {
        window.document.title = 'ERC-20 Token • KYC List';
        break;
      }
      case '7': {
        window.document.title = 'ERC-20 Token • Participant';
        break;
      }
      case '8': {
        window.document.title = 'ERC-20 Token • Support Ticket';
        break;
      }
      case '9': {
        window.document.title = 'ERC-20 Token • My Profile';
        break;
      }
      case '10': {
        window.document.title = 'ERC-20 Token • Settings';
        break;
      }
      default: {
        window.document.title = 'ERC-20 Token • Admin Dashboard';
      }
    }
  }, [activeTabKey]);

  useEffect(() => {
    if (tab) {
      switch (tab) {
        case 'dashboard': {
          setActiveTabKey('1');
          break;
        }
        case 'tokens': {
          setActiveTabKey('2');
          break;
        }
        case 'transactions': {
          setActiveTabKey('3');
          break;
        }
        case 'declared-ico': {
          setActiveTabKey('4');
          break;
        }
        case 'token-distribution': {
          setActiveTabKey('5');
          break;
        }
        case 'kyc-list': {
          setActiveTabKey('6');
          break;
        }
        case 'participant': {
          setActiveTabKey('7');
          break;
        }
        case 'support-ticket': {
          setActiveTabKey('8');
          break;
        }
        case 'my-profile': {
          setActiveTabKey('9');
          break;
        }
        case 'settings': {
          setActiveTabKey('10');
          break;
        }
        default: {
          navigate('/not-found');
        }
      }
    }
  }, [tab, navigate]);

  return (
    <Layout>
      {/* ADMIN PANEL HEADER'S */}
      <Headers />

      {/* ADMIN PANEL CONTENT'S */}
      <Content
        className='relative overflow-y-auto'
        style={{ minHeight: 'calc(100vh - 170px)' }}
      >
        <Tabs
          className='px-2 md:px-12 lg:px-24'
          onChange={(key) => handleTabChange(key)}
          activeKey={activeTabKey === '10' ? '10' : activeTabKey}
          tabBarExtraContent={(
            <Button
              className='inline-flex items-center'
              icon={<SettingOutlined />}
              type='default'
              size='middle'
              onClick={() => {
                handleTabChange('10');
              }}
            >
              Settings
            </Button>
          )}
          size='middle'
        >
          <TabPane
            tab={(
              <span className='inline-flex items-center space-x-1'>
                <DashboardOutlined />
                <span>Dashboard</span>
              </span>
            )}
            key='1'
          >
            <AdminDashboard />
          </TabPane>

          <TabPane
            tab={(
              <span className='inline-flex items-center space-x-1'>
                <SketchOutlined />
                <span>Tokens</span>
              </span>
            )}
            key='2'
          >
            <Tokens />
          </TabPane>

          <TabPane
            tab={(
              <span className='inline-flex items-center space-x-1'>
                <TransactionOutlined />
                <span>Transactions</span>
              </span>
            )}
            key='3'
          >
            <Transactions />
          </TabPane>

          <TabPane
            tab={(
              <span className='inline-flex items-center space-x-1'>
                <SelectOutlined />
                <span>Declared ICO</span>
              </span>
            )}
            key='4'
          >
            <DeclaredIco />
          </TabPane>
          <TabPane
            tab={(
              <span className='inline-flex items-center space-x-1'>
                <BlockOutlined />
                <span>Token Distribution</span>
              </span>
            )}
            key='5'
          >
            <TokenDistribution />
          </TabPane>

          <TabPane
            tab={(
              <span className='inline-flex items-center space-x-1'>
                <UnorderedListOutlined />
                <span>KYC List</span>
              </span>
            )}
            key='6'
          >
            <KycList />
          </TabPane>

          <TabPane
            tab={(
              <span className='inline-flex items-center space-x-1'>
                <UsergroupAddOutlined />
                <span>Participant</span>
              </span>
            )}
            key='7'
          >
            <Participant />
          </TabPane>

          <TabPane
            tab={(
              <span className='inline-flex items-center space-x-1'>
                <ReconciliationOutlined />
                <span>Support Ticket</span>
              </span>
            )}
            key='8'
          >
            <SupportTicket />
          </TabPane>

          <TabPane
            tab={(
              <span className='inline-flex items-center space-x-1'>
                <UserOutlined />
                <span>My Profile</span>
              </span>
            )}
            key='9'
          >
            <MyProfile />
          </TabPane>

          <TabPane key='10'>
            <Settings />
          </TabPane>
        </Tabs>
      </Content>

      {/* ADMIN PANEL FOOTER'S */}
      <Footers />
    </Layout>
  );
}

export default MyAdmin;
