import { HomeOutlined, PlusSquareOutlined } from '@ant-design/icons';
import { Layout, Tabs } from 'antd';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import CreateToken from '../../components/app/tabs/CreateToken';
import MyHome from '../../components/app/tabs/MyHome';
import Footers from '../../components/common/Footers';
import Headers from '../../components/common/Headers';

const { Content } = Layout;
const { TabPane } = Tabs;

function MyApp() {
  window.document.title = 'ERC-20 Token • My App';
  const [activeTabKey, setActiveTabKey] = useState('1');
  const navigate = useNavigate();
  const { tab } = useParams();

  const handleTabChange = (key) => {
    switch (key) {
      case '1': {
        navigate('/app/home');
        break;
      }
      case '2': {
        navigate('/app/create-token');
        break;
      }
      default: {
        navigate('/app/home');
      }
    }
  };

  useEffect(() => {
    switch (activeTabKey) {
      case '1': {
        window.document.title = 'ERC-20 Token • Home';
        break;
      }
      case '2': {
        window.document.title = 'ERC-20 Token • Create Token';
        break;
      }
      default: {
        window.document.title = 'ERC-20 Token • Home';
      }
    }
  }, [activeTabKey]);

  useEffect(() => {
    if (tab) {
      switch (tab) {
        case 'home': {
          setActiveTabKey('1');
          break;
        }
        case 'create-token': {
          setActiveTabKey('2');
          break;
        }
        default: {
          navigate('/not-found');
        }
      }
    }
  }, [tab, navigate]);

  return (
    <Layout>
      {/* MY-APP HEADER */}
      <Headers />

      {/* MY-APP CONTENT */}
      <Content
        className='relative overflow-y-auto'
        style={{ minHeight: 'calc(100vh - 170px)' }}
      >
        <Tabs
          onChange={(key) => handleTabChange(key)}
          className='px-2 md:px-12 lg:px-24'
          activeKey={activeTabKey}
          size='middle'
        >
          {/* TAB PANE HOME */}
          <TabPane
            tab={(
              <span className='inline-flex items-center space-x-2'>
                <HomeOutlined />
                <span>Home</span>
              </span>
            )}
            key='1'
          >
            <MyHome />
          </TabPane>

          {/* TAB PANE CREATE TOKEN */}
          <TabPane
            tab={(
              <span className='inline-flex items-center space-x-2'>
                <PlusSquareOutlined />
                <span>Create Token</span>
              </span>
            )}
            key='2'
          >
            <CreateToken />
          </TabPane>
        </Tabs>
      </Content>

      {/* MY-APP FOOTER */}
      <Footers />
    </Layout>
  );
}

export default MyApp;
