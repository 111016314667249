import { Select, Tabs, Typography } from 'antd';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setWalletNetwork } from '../../../redux/actions/walletAction';
import CreateTokenForm from '../token/CreateTokenForm';

const { Title } = Typography;
const { Option } = Select;
const { TabPane } = Tabs;

function CreateToken() {
  window.document.title = 'ERC-20 Token • Create Token';
  const { walletNetwork } = useSelector((state) => state.wallet);
  const { chainListLoading, chainListError, chainListData } = useSelector((state) => state.chain);
  const dispatch = useDispatch();

  return (
    <div className='bg-backgroundColorWhite min-h-[68vh] shadow-md rounded-sm'>
      <div className='flex flex-row items-center justify-between mx-2 pt-2 md:mx-4 md:pt-4'>
        <Title className='text-textColorBlack' level={4}>Token Details</Title>

        <Select
          style={{ width: 180 }}
          value={walletNetwork}
          placeholder='-- wallet network --'
          loading={chainListLoading}
          onChange={(value) => {
            dispatch(setWalletNetwork(value));
          }}
          size='middle'
          disabled
        >
          {chainListError === null && chainListData && chainListData.map((data) => (
            <Option key={data.id} value={data.chain_name}>{data.network_name}</Option>
          ))}
        </Select>
      </div>

      <Tabs type='card' size='middle' className='mx-2 md:mx-4'>
        <TabPane tab='Create Token' key='1'>
          <CreateTokenForm />
        </TabPane>
      </Tabs>
    </div>
  );
}

export default CreateToken;
