import { getSessionUser } from '../../utils/helperAuthentication';

function AclRoute({ hasOwnPermission, children }) {
  const assignedAction = getSessionUser().assigned_action;

  if (assignedAction.includes(hasOwnPermission)) {
    return children;
  }
}

export default AclRoute;

/**
 **                    --> Assigned Actions <--
 *! #ID  hasOwnPermission                     --> Implementation Status
 *? #0:  "User.Create"                        --> 👉 IMPLEMENTED
 *? #1:  "User.View"                          --> 👉 IMPLEMENTED
 *? #2:  "User.Edit"                          --> NOT IMPLEMENTATION
 *? #3:  "User.Banned"                        --> NOT IMPLEMENTATION
 *? #4:  "Ico.Declaration.View"               --> 👉 IMPLEMENTED
 *? #5:  "Ico.Declaration.Create"             --> 👉 IMPLEMENTED
 *? #6:  "Ico.Declaration.Detail"             --> 👉 IMPLEMENTED
 *? #7:  "Ico.Stage.Status"                   --> 👉 IMPLEMENTED
 *? #8:  "Ico.Stage.View"                     --> 👉 IMPLEMENTED
 *? #9:  "Ico.Kyc.View"                       --> 👉 IMPLEMENTED
 *? #10: "Ico.Kyc.Create"                     --> NOT IMPLEMENTATION
 *? #11: "Ico.Kyc.Approve"                    --> 👉 IMPLEMENTED
 *? #12: "Ico.Kyc.Detail"                     --> 👉 IMPLEMENTED
 *? #13: "Ico.Ticket.View"                    --> 👉 IMPLEMENTED
 *? #14: "Ico.Ticket.Status"                  --> 👉 IMPLEMENTED
 *? #15: "Ico.Participant.View"               --> 👉 IMPLEMENTED
 *? #16: "Ico.Participant.Detail"             --> 👉 IMPLEMENTED
 *? #17: "Ico.TokenDistribution.Create"       --> 👉 IMPLEMENTED
 *? #18: "Ico.TokenDistribution.View"         --> 👉 IMPLEMENTED
 *? #19: "Ico.TokenDistribution.Detail"       --> NOT IMPLEMENTATION
 *? #20: "Ico.Report.View"                    --> NOT IMPLEMENTATION
 *? #21: "Ico.Dashboard.View"                 --> 👉 IMPLEMENTED
 *? #22: "Ico.Role.View"                      --> 👉 IMPLEMENTED
 *? #23: "Ico.Role.Create"                    --> 👉 IMPLEMENTED
 *? #24: "Ico.Role.Update"                    --> NOT IMPLEMENTATION
 *? #25: "Ico.User.Role.Assign"               --> 👉 IMPLEMENTED
 *? #26: "Ico.User.Role.Assign.Update"        --> 👉 IMPLEMENTED
 *? #27: "Ico.Permission.View"                --> 👉 IMPLEMENTED
 *? #28: "Ico.Permission.Role.Assign"         --> 👉 IMPLEMENTED
 *? #29: "Ico.Permission.Role.Assign.Update"  --> 👉 IMPLEMENTED
 *? #30: "Ico.Contract.Create"                --> NOT IMPLEMENTATION
 *? #31: "Ico.Contract.View"                  --> 👉 IMPLEMENTED
 *? #32: "Ico.TokenDistribution.Initiate"     --> 👉 IMPLEMENTED
 *? #33: "Ico.TokenDistribution.Confirm"      --> 👉 IMPLEMENTED
 *? #34: "Ico.Declaration.Contract.Info.View" --> 👉 IMPLEMENTED
 */
