import {
  CheckCircleOutlined, DeliveredProcedureOutlined, ExclamationCircleOutlined, LoadingOutlined, SendOutlined
} from '@ant-design/icons';
import {
  Button, message, Modal, Steps
} from 'antd';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import eventMsg from '../../../data/event-message.json';
import { getSessionToken } from '../../../utils/helperAuthentication';
import ga4EventAction from '../../../utils/helperGAEventAction';
import notificationWithIcon from '../../../utils/popupNotification';
import AclRoute from '../../routes/AclRoute';

const { confirm } = Modal;
const { Step } = Steps;

function AirDropConfirm({ stageInfo }) {
  const { walletAddress, walletChainId } = useSelector((state) => state.wallet);
  const [current, setCurrent] = useState(stageInfo.transaction_status === 1 && stageInfo.hex === 0 ? 3 : 1);
  const [tokenTransferLoading, setTokenTransferLoading] = useState(false);
  const [tokenTransferConfirmLoading, setTokenTransferConfirmLoading] = useState(false);
  const [transactionStatus, setTransactionStatus] = useState(null);
  const [airDropLoading, setAirDropLoading] = useState(false);
  const [tranxId, setTranxId] = useState(null);
  const token = getSessionToken();
  const navigate = useNavigate();

  // air-drop steps
  const steps = [
    {
      id: 1,
      title: 'Initiate',
      description: 'Initialize Air-Drop',
      content: 'Initialize Air-Drop for Distribution Token',
      data: {
        token_amount: stageInfo.token_amount,
        contract_balance: stageInfo.contract_balance,
        total_participants: stageInfo.total_participants
      }
    },
    {
      id: 2,
      title: 'Transactions',
      description: 'Transfer Tokens',
      content: 'Transfer Token from host contract',
      data: {
        token_amount: stageInfo.token_amount,
        contract_balance: stageInfo.contract_balance,
        transfer_balance: stageInfo.transfer_balance,
        total_participants: stageInfo.total_participants
      }
    },
    {
      id: 3,
      title: 'Validating',
      description: 'Validate Transaction',
      content: 'Validation on your Token Transaction',
      data: {
        token_amount: stageInfo.token_amount,
        contract_balance: stageInfo.contract_balance + stageInfo.transfer_balance,
        transfer_balance: stageInfo.transfer_balance,
        total_participants: stageInfo.total_participants
      }
    },
    {
      id: 4,
      title: 'Confirm',
      description: 'Air-Drop',
      content: 'Confirmation Air-Drop and Complete Air-drop steps',
      data: {
        token_amount: stageInfo.token_amount,
        contract_balance: stageInfo.contract_balance + stageInfo.transfer_balance,
        transfer_balance: stageInfo.transfer_balance,
        total_participants: stageInfo.total_participants
      }
    }
  ];

  // function to handle token token transfer
  const handleTokenTransfer = () => {
    // google analytics event handler
    ga4EventAction('link', 'Token Transfer', 'Admin Token Transfer (Air-drop)');

    if (walletAddress && walletChainId) {
      confirm({
        title: eventMsg.admin.airDrop.transferTokenModal.title,
        icon: <ExclamationCircleOutlined />,
        content: eventMsg.admin.airDrop.transferTokenModal.message,
        okType: 'danger',
        cancelText: 'No',
        okText: 'Yes',

        onOk() {
          setTokenTransferLoading(true);

          // distribute token transaction
          window.ethereum
            .request({
              method: 'eth_sendTransaction',
              params: [{
                from: walletAddress,
                to: stageInfo.send_to,
                // value: stageInfo.token_amount.toString(),
                // gasPrice: "0x9184e72a000",
                // gas: "0x76c0",
                data: stageInfo.hex,
                chainId: walletChainId
              }]
            })
            .then((trxId) => {
              // confirm token transfer API call
              const myHeaders = new Headers();
              myHeaders.append('Authorization', `Bearer ${token}`);
              myHeaders.append('Content-Type', 'application/json');

              const raw = JSON.stringify({
                transfer_id: stageInfo.id,
                stage_id: stageInfo.stage_id,
                trx_id: trxId,
                token_owner_address: walletAddress,
                token_amount: stageInfo.transfer_balance
              });

              const requestOptions2 = {
                method: 'POST',
                headers: myHeaders,
                body: raw,
                redirect: 'follow'
              };

              fetch(`${process.env.REACT_APP_API_BASE_URL}/api/v1/confirm-token-transfer`, requestOptions2)
                .then((res) => res.json())
                .then((result) => {
                  setTokenTransferLoading(false);

                  if (result.result_code === 0) {
                    setTranxId(trxId); // set tranxId
                    setCurrent(current + 1); // move to next steps
                    setTransactionStatus(result.result.status); // set transaction status
                    message.success(eventMsg.admin.airDrop.tokenTransferSuccessMessage);
                  } else {
                    message.error(result?.error?.message);
                  }
                })
                .catch((err) => {
                  setTokenTransferLoading(false);
                  message.error(err.message);
                });
            })
            .catch((error) => {
              setTokenTransferLoading(false);
              message.error(error.message);
            });
        }
      });
    } else {
      notificationWithIcon('error', eventMsg.app.walletConnectionError.title, eventMsg.app.walletConnectionError.message);
    }
  };

  // function to handle token transfer confirm
  const handleTokenTransferConfirm = () => {
    // google analytics event handler
    ga4EventAction('link', 'Validate Transfer Token', 'Admin Validate Transfer Token (Air-drop)');

    setTokenTransferConfirmLoading(true);
    const myHeaders = new Headers();
    myHeaders.append('Authorization', `Bearer ${token}`);
    myHeaders.append('Content-Type', 'application/json');

    const raw = JSON.stringify({
      transfer_id: stageInfo.id,
      stage_id: stageInfo.stage_id,
      trx_id: tranxId,
      token_owner_address: walletAddress,
      token_amount: stageInfo.transfer_balance
    });

    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    fetch(`${process.env.REACT_APP_API_BASE_URL}/api/v1/confirm-token-transfer`, requestOptions)
      .then((res) => res.json())
      .then((result) => {
        setTokenTransferConfirmLoading(false);

        if (result.result_code === 0) {
          if (result.result.status === 1) {
            setCurrent(current + 1); // move to next steps
            setTransactionStatus(result.result.status); // set transaction status
            message.success(eventMsg.admin.airDrop.tokenTransferValidationSuccess);
          } else {
            message.error(eventMsg.admin.airDrop.tokenTransferValidationFailed);
          }
        } else {
          message.error(result?.error?.message);
        }
      })
      .catch((err) => {
        setTokenTransferConfirmLoading(false);
        message.error(err.message);
      });
  };

  // function to handle air-drop
  const handleAirDrop = () => {
    // google analytics event handler
    ga4EventAction('link', 'Air-drop', 'Admin (Air-drop) completion');

    confirm({
      title: 'Token Distribution (Air drop)',
      icon: <ExclamationCircleOutlined />,
      content: 'Are you sure distributed token (Air dorp)?',
      okType: 'danger',
      cancelText: 'No',
      okText: 'Yes',

      onOk() {
        setAirDropLoading(true);
        const myHeaders = new Headers();
        myHeaders.append('Authorization', `Bearer ${token}`);
        myHeaders.append('Content-Type', 'application/json');

        const raw = JSON.stringify({
          stage_id: stageInfo.stage_id,
          transaction_status: stageInfo.transaction_status ? stageInfo.transaction_status : transactionStatus,
          is_air_drop: stageInfo.is_air_drop
        });

        const requestOptions = {
          method: 'POST',
          headers: myHeaders,
          body: raw,
          redirect: 'follow'
        };

        fetch(`${process.env.REACT_APP_API_BASE_URL}/api/v1/distribute-token`, requestOptions)
          .then((response) => response.json())
          .then((result) => {
            setAirDropLoading(false);

            if (result.result_code === 0) {
              message.success(eventMsg.admin.airDrop.successAirDrop, 6);
              navigate('/admin/token-distribution');
            } else {
              message.error(result?.error?.message);
            }
          })
          .catch((error) => {
            message.error(error.message);
            setAirDropLoading(false);
          });
      }
    });
  };

  return (
    <>
      {/* STEPS */}
      <Steps className='px-5 mt-10' current={current}>
        {steps.map((item) => (
          <Step key={item.id} title={item.title} description={item.description} />
        ))}
      </Steps>

      {/* STEPS CONTENTS */}
      <div className='mt-5 p-2 md:p-5'>
        <h1 className='text-2xl text-center font-bold'>{steps[current].content}</h1>

        <div className='container flex justify-center mx-auto'>
          <div className='flex flex-col'>
            <div className='w-full'>
              <div className='border-b border-gray-200 shadow mt-5'>
                <table className='divide-y divide-gray-300'>
                  <thead className='bg-gray-50'>
                    <tr>
                      <th className='input-field-table-th'>Fields</th>
                      <th className='values-table-th'>Values</th>
                    </tr>
                  </thead>

                  <tbody className='bg-white divide-y divide-gray-300'>
                    {/* TOKEN AMOUNT */}
                    <tr className='whitespace-nowrap'>
                      <td className='px-6 py-4'>
                        <div className='text-md text-gray-800'>Token Amount</div>
                      </td>
                      <td className='px-6 py-4'>
                        <div className='text-md text-gray-600'>
                          {steps[current].data.token_amount}
                        </div>
                      </td>
                    </tr>

                    {/* CONTRACT BALANCE */}
                    <tr className='whitespace-nowrap'>
                      <td className='px-6 py-4'>
                        <div className='text-md text-gray-800'>Contract Balance</div>
                      </td>
                      <td className='px-6 py-4'>
                        <div className='text-md text-gray-600'>
                          {steps[current].data.contract_balance}
                        </div>
                      </td>
                    </tr>

                    {/* TRANSFER BALANCE */}
                    <tr className='whitespace-nowrap'>
                      <td className='px-6 py-4'>
                        <div className='text-md text-gray-800'>Transfer Balance</div>
                      </td>
                      <td className='px-6 py-4'>
                        <div className='text-md text-gray-600'>
                          {steps[current].data.transfer_balance}
                        </div>
                      </td>
                    </tr>

                    {/* TOTAL PARTICIPANTS */}
                    <tr className='whitespace-nowrap'>
                      <td className='px-6 py-4'>
                        <div className='text-md text-gray-800'>Total Participants</div>
                      </td>
                      <td className='px-6 py-4'>
                        <div className='text-md text-gray-600'>
                          {steps[current].data.total_participants}
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* STEPS ACTION */}
      <div className='mt-2 flex items-center justify-center'>
        {/* STEP-2: TRANSFER TOKENS */}
        {current === 1 && (
          <AclRoute hasOwnPermission='Ico.TokenDistribution.Confirm'>
            <Button
              className='inline-flex items-center justify-center font-bold min-w-[350px]'
              icon={tokenTransferLoading || <SendOutlined />}
              disabled={tokenTransferLoading}
              onClick={handleTokenTransfer}
              type='primary'
              size='large'
            >
              {tokenTransferLoading ? <LoadingOutlined /> : `${steps[current].title} (${steps[current].description})`}
            </Button>
          </AclRoute>
        )}

        {/* STEP-3: VALIDATE TRANSITIONS */}
        {current === 2 && (
          <AclRoute hasOwnPermission='Ico.TokenDistribution.Confirm'>
            <Button
              className='inline-flex items-center justify-center font-bold min-w-[350px]'
              icon={tokenTransferConfirmLoading || <CheckCircleOutlined />}
              disabled={tokenTransferConfirmLoading}
              onClick={handleTokenTransferConfirm}
              type='primary'
              size='large'
            >
              {tokenTransferConfirmLoading ? <LoadingOutlined /> : `${steps[current].title} (${steps[current].description})`}
            </Button>
          </AclRoute>
        )}

        {/* STEP-4: AIR-DROP CONFIRM */}
        {current === 3 && (
          <AclRoute hasOwnPermission='Ico.TokenDistribution.Create'>
            <Button
              className='inline-flex items-center justify-center font-bold min-w-[350px]'
              icon={airDropLoading || <DeliveredProcedureOutlined />}
              disabled={airDropLoading}
              onClick={handleAirDrop}
              type='primary'
              size='large'
            >
              {airDropLoading ? <LoadingOutlined /> : `${steps[current].title} (${steps[current].description})`}
            </Button>
          </AclRoute>
        )}
      </div>
    </>
  );
}

export default AirDropConfirm;
