import { LoadingOutlined, LockOutlined } from '@ant-design/icons';
import {
  Button, Form, Input, message
} from 'antd';
import React, { useState } from 'react';
import eventMsg from '../../data/event-message.json';
import { getSessionToken, getSessionUser } from '../../utils/helperAuthentication';

function ChangePassword() {
  const [loading, setLoading] = useState(false);
  const token = getSessionToken();
  const user = getSessionUser();
  const { useForm } = Form;
  const [form] = useForm();

  const resetPassword = (values) => {
    if (values.newPassword !== values.confirmPassword) {
      message.error(eventMsg.auth.changePassword.passwordNotMatchError);
    } else {
      setLoading(true);
      const myHeaders = new Headers();
      myHeaders.append('Authorization', `Bearer ${token}`);
      myHeaders.append('Content-Type', 'application/json');

      const raw = JSON.stringify({
        email: user.email,
        old_password: values.oldPassword,
        new_password: values.newPassword,
        confirm_password: values.confirmPassword
      });

      const requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
      };

      fetch(`${process.env.REACT_APP_API_BASE_URL}/api/v1/auth/reset-password`, requestOptions)
        .then((response) => response.json())
        .then((result) => {
          if (result.result_code === 0) {
            message.success(eventMsg.auth.changePassword.successMessage);
            form.resetFields();
            setLoading(false);
          } else {
            message.error(result.error.message);
            setLoading(false);
          }
          setLoading(false);
        })
        .catch((error) => {
          message.error(error.message);
          setLoading(false);
        });
    }
  };

  return (
    <Form
      form={form}
      name='reset-password-form'
      labelCol={{ span: 6 }}
      wrapperCol={{ span: 12 }}
      initialValues={{ remember: true }}
      onFinish={resetPassword}
      autoComplete='off'
    >
      {/* INPUT FIELD OLD PASSWORD */}
      <Form.Item
        label='Old Password'
        name='oldPassword'
        rules={[{
          required: true,
          message: 'Please input here your old password!'
        }]}
      >
        <Input.Password
          prefix={<LockOutlined className='site-form-item-icon mr-2' />}
          placeholder='Type here your old password'
          type='password'
          size='large'
        />
      </Form.Item>

      {/* INPUT FIELD NEW PASSWORD */}
      <Form.Item
        label='New Password'
        name='newPassword'
        rules={[{
          required: true,
          message: 'Please input here your new password!'
        }]}
      >
        <Input.Password
          prefix={<LockOutlined className='site-form-item-icon mr-2' />}
          placeholder='Type here your new password'
          type='password'
          size='large'
        />
      </Form.Item>

      {/* INPUT FIELD CONFIRM PASSWORD */}
      <Form.Item
        label='Confirm Password'
        name='confirmPassword'
        rules={[{
          required: true,
          message: 'Please input here your confirm password!'
        }]}
      >
        <Input.Password
          prefix={<LockOutlined className='site-form-item-icon mr-2' />}
          placeholder='Type here your confirm password'
          type='password'
          size='large'
        />
      </Form.Item>

      {/* FORM SUBMIT BUTTON */}
      <Form.Item className='flex items-center justify-center mt-5'>
        <Button
          className='w-[300px] font-bold'
          disabled={loading}
          htmlType='submit'
          type='primary'
          size='large'
        >
          {loading ? <LoadingOutlined /> : 'Change Password'}
        </Button>
      </Form.Item>
    </Form>
  );
}

export default ChangePassword;
