import { ExclamationCircleOutlined, LoadingOutlined } from '@ant-design/icons';
import { Button, Modal } from 'antd';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import eventMsg from '../../../data/event-message.json';
import arrayToCommaSeparatedText from '../../../lib/arrayToCommaSeparatedText';
import convertToInternationalCurrencySystem from '../../../lib/internationalCurrencyConvert';
import truncateString from '../../../lib/truncateString';
import { setTokenDeployData } from '../../../redux/actions/tokenAction';
import { getSessionToken, getSessionUser } from '../../../utils/helperAuthentication';
import ga4EventAction from '../../../utils/helperGAEventAction';
import notificationWithIcon from '../../../utils/popupNotification';

const { confirm } = Modal;

function CreatedTokenPreview() {
  const { tokenFromData } = useSelector((tokenState) => tokenState.token);
  const { walletNetwork, walletAddress } = useSelector((walletState) => walletState.wallet);
  const [loading, setLoading] = useState(false);
  const token = getSessionToken();
  const user = getSessionUser();
  const dispatch = useDispatch();

  // owner address is the address of the wallet
  const ownerAddress = tokenFromData.advancedSetting ? tokenFromData.createOwner : walletAddress;

  // react-router-dom hooks
  const navigate = useNavigate();
  const location = useLocation();

  // token initiate hex code
  const { hex } = location.state;

  // make handler function to token deployment
  const handleTokenDeployment = () => {
    // google analytics event handler
    ga4EventAction('link', 'Token Deploy', 'User Token Deployment');

    confirm({
      title: eventMsg.app.tokenDeployConfirmModal.title,
      icon: <ExclamationCircleOutlined />,
      content: eventMsg.app.tokenDeployConfirmModal.message,

      onOk() {
        if (ownerAddress) {
          setLoading(true);
          window.ethereum
            .request({
              method: 'eth_sendTransaction',
              params: [{
                from: ownerAddress,
                // to: "0x36Af018353b47EEAEf69a61194E01eB9C73c3968",
                // value: "10000",
                // gasPrice: "0x9184e72a000",
                // gas: "0x76c0",
                data: hex
              }]
            })
            .then((trxId) => {
              // save token info API call
              const myHeaders = new Headers();
              myHeaders.append('Content-Type', 'application/json');
              // if user is logged in, send the token
              token && myHeaders.append('Authorization', `Bearer ${token}`);

              const raw = JSON.stringify({
                token_name: tokenFromData.tokenName,
                token_symbol: tokenFromData.symbol,
                total_supply: tokenFromData.initialSupply,
                token_decimals: tokenFromData.decimals,
                supply_type: tokenFromData?.othersConfig?.includes('CanMint') ? 'multiple' : 'fixed',
                freeze: tokenFromData?.othersConfig?.includes('CanFreeze') ? 1 : 0,
                burning_token: tokenFromData?.othersConfig?.includes('CanBurn') ? 1 : 0,
                change_owner: tokenFromData.advancedSetting ? 1 : 0,
                owner_address: tokenFromData.advancedSetting ? tokenFromData?.createOwner : walletAddress,
                email: user ? user?.email : '',
                company_name: '',
                network: walletNetwork,
                trxId
              });

              const requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: raw,
                redirect: 'follow'
              };

              fetch(`${process.env.REACT_APP_API_BASE_URL}/api/v1/save-token-info`, requestOptions)
                .then((response) => response.json())
                .then((result) => {
                  if (result.result_code === 0) {
                    setLoading(false);
                    notificationWithIcon('success', eventMsg.app.tokenDeploySuccess.title, eventMsg.app.tokenDeploySuccess.message);

                    // save token deploy data to redux
                    dispatch(
                      setTokenDeployData({
                        abi: result.result.abi,
                        bytecode: result.result.bytecode,
                        source_code: result.result.source_code,
                        tokenData: result.result.tokenData,
                        trxId
                      })
                    );

                    // navigate to token deploy page
                    navigate('/app/token-success');
                  } else {
                    notificationWithIcon('error', 'Token Deployment Error', result?.error?.message);
                    setLoading(false);
                  }
                })
                .catch((error) => {
                  notificationWithIcon('error', 'Token Deployment Error', error.message);
                  setLoading(false);
                });
            })
            .catch((error) => {
              notificationWithIcon('error', 'Token Deployment Error', error.message);
              setLoading(false);
            });
        } else {
          notificationWithIcon('error', 'Wallet Connection Error', 'Please first connect to your wallet');
        }
      }
    });
  };

  return (
    <div className='bg-backgroundColorWhite min-h-[68vh]'>
      <h1 className='text-2xl text-center font-bold pt-5'>Created Token Preview</h1>

      <div className='container flex justify-center mx-auto'>
        <div className='flex flex-col'>
          <div className='w-full'>
            <div className='border-b border-gray-200 shadow mt-5'>
              <table className='divide-y divide-gray-300'>
                <thead className='bg-gray-50'>
                  <tr>
                    <th className='input-field-table-th'>Input Fields</th>
                    <th className='values-table-th'>Values</th>
                  </tr>
                </thead>

                <tbody className='bg-white divide-y divide-gray-300'>
                  {/* TOKEN NAME */}
                  {tokenFromData.tokenName && (
                    <tr className='whitespace-nowrap'>
                      <td className='px-6 py-4'>
                        <div className='text-md text-gray-800'>Token Name</div>
                      </td>
                      <td className='px-6 py-4'>
                        <div className='text-md text-gray-600'>{tokenFromData.tokenName}</div>
                      </td>
                    </tr>
                  )}

                  {/* SYMBOLS */}
                  {tokenFromData.symbol && (
                    <tr className='whitespace-nowrap'>
                      <td className='px-6 py-4'>
                        <div className='text-md text-gray-800'>Symbols</div>
                      </td>
                      <td className='px-6 py-4'>
                        <div className='text-md text-gray-600'>{tokenFromData.symbol}</div>
                      </td>
                    </tr>
                  )}

                  {/* INITIAL SUPPLY */}
                  {tokenFromData.initialSupply && (
                    <tr className='whitespace-nowrap'>
                      <td className='px-6 py-4'>
                        <div className='text-md text-gray-800'>Total Supply</div>
                      </td>
                      <td className='px-6 py-4'>
                        <div className='text-md text-gray-600'>
                          {convertToInternationalCurrencySystem(tokenFromData.initialSupply)}
                        </div>
                      </td>
                    </tr>
                  )}

                  {/* DECIMALS */}
                  {tokenFromData.decimals && (
                    <tr className='whitespace-nowrap'>
                      <td className='px-6 py-4'>
                        <div className='text-md text-gray-800'>Decimals(1-18)</div>
                      </td>
                      <td className='px-6 py-4'>
                        <div className='text-md text-gray-600'>{tokenFromData.decimals}</div>
                      </td>
                    </tr>
                  )}

                  {/* OWNER ADDRESS */}
                  <tr className='whitespace-nowrap'>
                    <td className='px-6 py-4'>
                      <div className='text-md text-gray-800'>Owner Address</div>
                    </td>
                    <td className='px-6 py-4'>
                      <div className='text-md text-gray-600'>
                        {ownerAddress ? truncateString(ownerAddress, 30, '.....') : 'None'}
                      </div>
                    </td>
                  </tr>

                  {/* OTHERS CONFIG */}
                  <tr className='whitespace-nowrap'>
                    <td className='px-6 py-4'>
                      <div className='text-md text-gray-800'>Other Config</div>
                    </td>
                    <td className='px-6 py-4'>
                      <div className='text-md text-gray-600'>
                        {arrayToCommaSeparatedText(tokenFromData.othersConfig)}
                      </div>
                    </td>
                  </tr>

                  {/* NETWORK */}
                  {walletNetwork && (
                    <tr className='whitespace-nowrap'>
                      <td className='px-6 py-4'>
                        <div className='text-md text-gray-800'>Network</div>
                      </td>
                      <td className='px-6 py-4'>
                        <div className='text-md text-gray-600 capitalize'>{walletNetwork}</div>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      {/* TOKEN DEPLOY BUTTON */}
      <div className='flex items-center justify-center mt-5'>
        <Button
          className='w-[350px] font-bold mb-5'
          onClick={handleTokenDeployment}
          disabled={loading}
          htmlType='submit'
          type='primary'
          size='large'
        >
          {loading ? <LoadingOutlined /> : 'Deploy Token'}
        </Button>
      </div>
    </div>
  );
}

export default CreatedTokenPreview;
