import React from 'react';

function MyHome() {
  window.document.title = 'ERC-20 Token • Home';

  return (
    <div>
      <h1 className='text-2xl text-center mt-5'>
        Welcome to ICO Platform !!!
      </h1>
    </div>
  );
}

export default MyHome;
