import { LoginOutlined } from '@ant-design/icons';
import { Button, Layout, Typography } from 'antd';
import React from 'react';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import Logo from '../../assets/images/logo.svg';
import useConnectWallet from '../../hooks/useConnectWallet';
import useScreenSize from '../../hooks/useScreenSize';
import { getSessionToken, getSessionUser } from '../../utils/helperAuthentication';
import UserPopover from './UserPopover';
import WalletDropdown from './WalletDropdown';

const { Title } = Typography;
const { Header } = Layout;

function Headers() {
  const { screenWidth } = useScreenSize();
  const { walletAddress, walletBalance } = useSelector((state) => state.wallet);
  const { connectWalletHandler } = useConnectWallet();
  const navigate = useNavigate();

  // user information get from local storage
  const user = getSessionUser();
  const token = getSessionToken();

  // function to logo button click to redirect
  const logoClickToAction = () => {
    if (token && user && user.user_type === 'host') {
      return '/admin/dashboard';
    }

    if (token && user && user.user_type === 'participant') {
      return '/user/dashboard';
    }

    if (token && user && user.user_type === 'sa') {
      return '/super/dashboard';
    }

    return '/app/home';
  };

  return (
    <Header className='app-header'>
      <Link className='flex flex-row items-center justify-center' to={logoClickToAction()}>
        <img className='w-8 h-8' src={Logo} alt='ERC-20 Token' />
        {screenWidth > 480 && (
          <Title
            className='header-title'
            level={4}
          >
            ERC-20 Token
          </Title>
        )}
      </Link>

      {/* HEADER WELCOME TEXT (USER NAME) */}
      {user && (
        <div className='absolute right-[12.5rem] text-textColorWhite text-xl font-normal capitalize hidden sm:right-62 md:right-[15rem] lg:right-72 md:block'>
          {`Welcome! ${user.last_name}`}
        </div>
      )}

      <div>
        {/* USER LOGIN, LOGOUT BUTTON & USER INFO POPOVER */}
        {user && token ? (
          <UserPopover />
        ) : (
          <Button
            className='inline-flex items-center'
            onClick={() => navigate('/auth/login')}
            icon={<LoginOutlined />}
            type='primary'
            shape='round'
            size='middle'
          >
            LOGIN
          </Button>
        )}

        {/* WALLET CONNECT BUTTON OR WALL INFO BUTTON */}
        {walletAddress && walletBalance ? (
          <WalletDropdown />
        ) : (
          <Button
            className='btn-connect-wallet'
            onClick={connectWalletHandler}
            type='default'
            size='middle'
            shape='round'
          >
            Connect Wallet
          </Button>
        )}
      </div>
    </Header>
  );
}

export default Headers;
