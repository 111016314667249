import {
  ExclamationCircleOutlined, LoadingOutlined, SearchOutlined, UserAddOutlined
} from '@ant-design/icons';
import {
  Alert, Button, Card, Empty, Form, Input, message, Modal, Pagination, Result, Select, Skeleton, Tag
} from 'antd';
import React, { useEffect, useState } from 'react';
import eventMsg from '../../../data/event-message.json';
import useFetchData from '../../../hooks/useFetchData';
import { getSessionToken, getSessionUser } from '../../../utils/helperAuthentication';
import ga4EventAction from '../../../utils/helperGAEventAction';
import LoadingSpinner from '../../common/LoadingSpinner';
import AclRoute from '../../routes/AclRoute';

const { Option } = Select;
const { confirm } = Modal;

function AdminUserCard() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isUserRoleSetModalOpen, setIsUserRoleSetModalModalOpen] = useState(false);
  const [roleId, setRoleId] = useState(null);
  const [userId, setUserId] = useState(null);
  const [assignRoleLoading, setAssignRoleLoading] = useState(false);
  const [userAddError, setUserAddError] = useState('');
  const [userAddLoading, setUserAddLoading] = useState(false);
  const [fetchAgain, setFetchAgain] = useState(false);
  const [searchKeyword, setSearchKeyword] = useState('');
  const [sortType, setSortType] = useState('aesc');
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const token = getSessionToken();
  const user = getSessionUser();
  const { useForm } = Form;
  const [form] = useForm();

  // admin user list data fetch
  const [loading, error, response] = useFetchData(`${process.env.REACT_APP_API_BASE_URL}/api/v1/auth/get-admin-user-list?page=${page}&search=${searchKeyword}&limit=${limit}&sort=${sortType}`, fetchAgain);

  // admin role list data fetch
  const [adminRoleLoading, adminRoleError, adminRoleResponse] = useFetchData(`${process.env.REACT_APP_API_BASE_URL}/api/v1/get-role-list`);

  // function to add new admin user
  const handleAddNewAdminUser = (values) => {
    // google analytics event handler
    ga4EventAction('link', 'Add New User', 'Admin Add New User');

    setUserAddLoading(true);
    const myHeaders = new Headers();
    myHeaders.append('Authorization', `Bearer ${token}`);
    myHeaders.append('Content-Type', 'application/json');

    const raw = JSON.stringify({
      first_name: values.firstName,
      last_name: values.lastName,
      email: values.email,
      company_id: user.company_id
    });

    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    fetch(`${process.env.REACT_APP_API_BASE_URL}/api/v1/auth/register-admin-user`, requestOptions)
      .then((res) => res.json())
      .then((result) => {
        setUserAddLoading(false);

        if (result.result_code === 0) {
          form.resetFields();
          setIsModalOpen(false);
          setFetchAgain(!fetchAgain);
          message.success(eventMsg.admin.acl.newUserCreateSuccessMessage);
        } else {
          setUserAddError(result.error.message);
        }
      })
      .catch((err) => {
        setUserAddError(err.message);
        setUserAddLoading(false);
      });
  };

  // function to assign role against admin user
  const handleAssignRoleAgainstUser = () => {
    // google analytics event handler
    ga4EventAction('link', 'Assigned Role', 'Admin User Against Assigned Role');

    if (roleId) {
      setAssignRoleLoading(true);
      const myHeaders = new Headers();
      myHeaders.append('Authorization', `Bearer ${token}`);
      myHeaders.append('Content-Type', 'application/json');

      const raw = JSON.stringify({
        role_id: roleId,
        user_id: userId
      });

      const requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
      };

      fetch(`${process.env.REACT_APP_API_BASE_URL}/api/v1/assign-role-to-user`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
          setAssignRoleLoading(false);

          if (result.result_code === 0) {
            setRoleId(null);
            setUserId(null);
            setFetchAgain(!fetchAgain);
            setIsUserRoleSetModalModalOpen(false);
            message.success(eventMsg.admin.acl.userAgainstRoleAssignedSuccessMessage);
          } else {
            message.error(result.error.message);
          }
        })
        .catch((err) => {
          message.error(err.message);
          setAssignRoleLoading(false);
        });
    } else {
      message.error('Please, Select an role first');
    }
  };

  // function to handle user assented role removed
  const handleUserAssignedRoleRemoved = (id) => {
    // google analytics event handler
    ga4EventAction('link', 'Removed Role', 'Admin User Against Removed Role');

    confirm({
      title: 'Removed user role',
      icon: <ExclamationCircleOutlined />,
      content: 'Are you sure this user removed assigned role?',
      okType: 'danger',
      cancelText: 'No',
      okText: 'Yes',

      onOk() {
        return new Promise((resolve, reject) => {
          const myHeaders = new Headers();
          myHeaders.append('Authorization', `Bearer ${token}`);
          myHeaders.append('Content-Type', 'application/json');

          const raw = JSON.stringify({
            user_role_map_id: id
          });

          const requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
          };

          fetch(`${process.env.REACT_APP_API_BASE_URL}/api/v1/remove-role-to-user`, requestOptions)
            .then((res) => res.json())
            .then((result) => {
              resolve();

              if (result.result_code === 0) {
                setFetchAgain(!fetchAgain);
                message.success(eventMsg.admin.acl.userAgainstRoleRemovedSuccessMessage);
              } else {
                message.error(result.error.message);
              }
            })
            .catch((err) => {
              message.error(err.message);
              reject();
            });
        }).catch(() => {
          message.error('Oops errors!');
        });
      }
    });
  };

  // reset paginate page number
  useEffect(() => {
    setPage(1);
  }, [searchKeyword, limit]);

  return (
    <>
      <Card
        className='bg-backgroundColorWhite min-h-[40vh] shadow-md rounded-sm'
        title='Admin User List'
        extra={(
          <AclRoute hasOwnPermission='User.Create'>
            <Button
              className='inline-flex items-center'
              onClick={() => setIsModalOpen(true)}
              icon={<UserAddOutlined />}
              type='primary'
              size='large'
            >
              Create Admin User
            </Button>
          </AclRoute>
        )}
      >
        <AclRoute hasOwnPermission='User.View'>
          <Skeleton loading={loading} active paragraph={{ rows: 10 }}>
            {error ? (
              <Result
                title='Fetching Error'
                subTitle={error}
                status='error'
              />
            ) : (
              <div>
                {/* SEARCH BOX & FILTERS */}
                <div className='search-and-filter-box'>
                  <Input
                    className='space-x-4'
                    placeholder='Type in to Search admin users'
                    onChange={(e) => setSearchKeyword(e.target.value)}
                    prefix={<SearchOutlined />}
                    size='large'
                  />

                  <Select
                    className='w-full sm:w-[240px]'
                    placeholder='-- show rows --'
                    onChange={(value) => setLimit(value)}
                    defaultValue='10'
                    size='large'
                  >
                    <Option value='10'>10 Rows</Option>
                    <Option value='20'>20 Rows</Option>
                    <Option value='30'>30 Rows</Option>
                    <Option value='40'>40 Rows</Option>
                    <Option value='50'>50 Rows</Option>
                  </Select>

                  <Select
                    className='w-full sm:w-[240px]'
                    placeholder='-- select type to sort --'
                    onChange={(value) => setSortType(value)}
                    defaultValue='aesc'
                    size='large'
                  >
                    <Option value='aesc'>Sort By Ascending</Option>
                    <Option value='desc'>Sort By Descending</Option>
                  </Select>
                </div>

                {/* ADMIN USER LIST */}
                {response && response.result.rows.length === 0 ? (
                  <Empty
                    className='mt-10'
                    description={(
                      <span>Sorry! Your users data was not found.</span>
                    )}
                  />
                ) : (
                  <div className='w-full shadow bg-white rounded my-3'>
                    <div className='border-gray-200 w-full rounded bg-white overflow-x-auto'>
                      <table className='w-full leading-normal'>
                        {/* TABLE HEADER */}
                        <thead className='table-thead'>
                          <tr className='border-b border-gray-200'>
                            <th scope='col' className='table-thead-th'>NAME</th>
                            <th scope='col' className='table-thead-th'>EMAIL</th>
                            <th scope='col' className='table-thead-th'>ROLE</th>
                            <th scope='col' className='table-thead-th'>ACTIONS</th>
                          </tr>
                        </thead>

                        {/* DATA MAPPING ON TABLE BODY */}
                        {response && response.result.rows.map((data) => (
                          <tbody key={Math.random()}>
                            <tr className='table-body-tr'>
                              <td className='table-body-td'>
                                {`${data.first_name} ${data.last_name}`}
                              </td>
                              <td className='table-body-td !lowercase'>
                                {data.email}
                              </td>
                              <td className='table-body-td'>
                                <Tag
                                  className='px-2 py-1 text-sm capitalize'
                                  color={data.role_name ? 'success' : 'error'}
                                >
                                  {data.role_name ? data.role_name : 'N/A'}
                                </Tag>
                              </td>
                              <td className='table-body-td space-x-2'>
                                {data.role_name !== 'Admin' && (data.role_name ? (
                                  <AclRoute hasOwnPermission='Ico.User.Role.Assign.Update'>
                                    <Button
                                      className='w-[135px]'
                                      onClick={() => {
                                        handleUserAssignedRoleRemoved(data.user_role_map_id);
                                      }}
                                      type='default'
                                      danger
                                    >
                                      REMOVE ROLE
                                    </Button>
                                  </AclRoute>
                                ) : (
                                  <AclRoute hasOwnPermission='Ico.User.Role.Assign'>
                                    <Button
                                      type='primary'
                                      onClick={() => {
                                        setUserId(data.user_id);
                                        setIsUserRoleSetModalModalOpen(true);
                                      }}
                                    >
                                      ASSIGNED ROLE
                                    </Button>
                                  </AclRoute>
                                ))}
                              </td>
                            </tr>
                          </tbody>
                        ))}
                      </table>
                    </div>
                  </div>
                )}

                {/* ADMIN USERS LIST PAGINATION */}
                {response && response.result.total_page > 1 && (
                  <Pagination
                    className='flex items-center justify-center m-4'
                    defaultCurrent={1}
                    total={response.result.total_page * 10}
                    onChange={(e) => setPage(e)}
                  />
                )}
              </div>
            )}
          </Skeleton>
        </AclRoute>
      </Card>

      {/* ADD NEW USER MODAL */}
      <Modal
        title='Create New Admin User'
        open={isModalOpen}
        visible={isModalOpen}
        onCancel={() => setIsModalOpen(false)}
        footer={[
          <Button
            key='cancel'
            onClick={() => setIsModalOpen(false)}
          >
            Cancel
          </Button>
        ]}
      >
        {/* NEW USER CREATE ERROR ALERT */}
        {userAddError && (
          <Alert
            className='ml-8 mr-[38px] mb-2'
            message={userAddError}
            type='error'
          />
        )}

        {/* NEW ADMIN USER CREATE FORM */}
        <Form
          form={form}
          name='admin-user-create'
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 16 }}
          initialValues={{ remember: true }}
          onFinish={handleAddNewAdminUser}
          autoComplete='off'
          size='large'
        >
          <Form.Item
            label='First name'
            name='firstName'
            rules={[{
              required: true,
              message: 'Please input here user first name!'
            }]}
          >
            <Input placeholder='Type here user first name' />
          </Form.Item>

          <Form.Item
            label='Last name'
            name='lastName'
            rules={[{
              required: true,
              message: 'Please input here user last name!'
            }]}
          >
            <Input placeholder='Type here user last name' />
          </Form.Item>

          <Form.Item
            label='User email'
            name='email'
            rules={[{
              type: 'email',
              required: true,
              message: 'Please input here user email address!'
            }]}
          >
            <Input placeholder='Type here user email address' />
          </Form.Item>

          <Form.Item wrapperCol={{ offset: 6, span: 16 }}>
            <Button
              className='w-[200px]'
              disabled={userAddLoading}
              htmlType='submit'
              type='primary'
            >
              {userAddLoading ? <LoadingOutlined /> : 'Create New User'}
            </Button>
          </Form.Item>
        </Form>
      </Modal>

      {/* UPDATE ROLE AGAINST USER MODAL */}
      <Modal
        title='Assigned Role Against User'
        open={isUserRoleSetModalOpen}
        visible={isUserRoleSetModalOpen}
        okText='Assigned Role'
        onOk={handleAssignRoleAgainstUser}
        confirmLoading={assignRoleLoading}
        onCancel={() => {
          setRoleId(null);
          setAssignRoleLoading(false);
          setIsUserRoleSetModalModalOpen(false);
        }}
      >
        {adminRoleLoading ? (
          <LoadingSpinner />
        ) : (
          <Select
            className='w-full mb-16'
            placeholder='-- select role for an user --'
            onChange={(value) => setRoleId(value)}
            status={adminRoleError && 'error'}
            value={roleId}
            size='large'
          >
            {adminRoleResponse && adminRoleResponse.result.rows.map((data) => (
              <Select.Option key={data.id} value={data.id}>
                {data.role_name}
              </Select.Option>
            ))}
          </Select>
        )}
      </Modal>
    </>
  );
}

export default AdminUserCard;
